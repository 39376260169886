@charset "utf-8";
@use "../../../global/_" as g;
.e-form-text {
	display: inline-block;
	width: 100%;
	height: 52px;
	padding: 0 18px;
	background: g.$white;
	border: 1px solid g.$lineColor;
	color: g.$textColor;

	&:focus {
		outline: auto;
		outline-color: g.$baseColorLight;
	}
	&._w-m_ {
		max-width: 350px;
	}
	&._w-s_ {
		max-width: 100px;
	}
	&._w-a_ {
		width: auto;
	}
	&._w-zip_ {
		max-width: 150px;
	}

	&._error_ {
		border-color: g.$secondaryColorDark;
	}
}
