@charset "utf-8";
@use "../../global/_" as g;
.c-contact-block {
	@include g.smMin {
		display: flex;
	}
	@include g.print {
		display: none !important;
	}
	&__item {
		flex: 0 0 50%;
		padding: 60px 35px;
		color: g.$white;

		@include g.smMax {
			padding: 35px 15px;
		}
		&:nth-child(1) { background: g.$baseColor; }
		&:nth-child(2) { background: #083d88; }

		&:only-child { flex: 0 0 100%; }
	}
	&__ttl {
		display: flex;
		flex-direction: column;
		align-items: center;

		&::before {
			content: attr(data-txt);
			margin-top: 15px;
			color: #54d0ff;
			@include g.font-en;
			@include g.l_height(12, 15);

			@include g.smMax {
				margin-top: 5px;
			}
		}
		.__ico {
			order: -1;
			font-size: 3.8rem;
			line-height: 1;
			
			@include g.smMax {
				font-size: g.$fz-xxxl;
			}
		}
		.__txt {
			margin-top: 15px;
			@include g.font-bold;
			@include g.l_height(22, 30);

			@include g.smMax {
				margin-top: 10px;
				@include g.l_height(16, 22);
			}
		}
	}
	&__btn {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: min(380px, 100%);
		height: 80px;
		margin: 25px auto 0;
		border: 1px solid g.$white;
		color: g.$white;
		transition: background .3s ease, color .3s ease;

		@include g.smMax {
			width: min(280px, 100%);
			height: 60px;
			margin-top: 20px;
		}
		@include g.hover {
			background: g.$white;
			color: g.$baseColor;
		}
		.__ico {
			position: relative;
			top: 2px;
			margin-right: 8px;
			font-size: g.$fz-l;
			line-height: 1;

			@include g.smMax {
				font-size: g.$fz-m;
			}
		}
		.__txt {
			@include g.font-bold;
			font-size: g.$fz-xl;

			@include g.smMax {
				font-size: g.$fz-m;
			}
		}
		&::after {
			right: 25px;
			@include g.centering-elements(false, true);
			@include g.font-icon('\f105');
			font-size: g.$fz-l;

			@include g.smMax {
				right: 20px;
			}
		}
	}

}