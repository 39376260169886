@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pa-xxl {
	padding: g.$m-xxl !important;

	@include g.smMax {
		padding:math.div(g.$m-xxl, 2) !important;
	}
}
