@charset "utf-8";
@use "../../global/_" as g;
.m-pager {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: 0 5px;
	grid-template-areas: "prev next";
	margin-top: 60px;
	line-height: 1.4;

	@include g.smMax {
		margin-top: 40px;
	}
	&__item {
		display: flex;
	}
	&__item._prev_ {
		grid-area: prev;
		justify-content: flex-start;
	}
	&__item._next_ {
		grid-area: next;
		justify-content: flex-end;
	}
	&__item._prev_ &__link {
		&::after {
			left: 20px;
			@include g.font-icon('\f104');
		}
	}
	&__item._next_ &__link {
		&::after {
			right: 20px;
			@include g.font-icon('\f105');
		}
	}
	&__link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: min(300px, 90%);
		height: 60px;
		padding: 10px 15px;
		background: g.$baseColor;
		color: g.$white;
		@include g.font-medium;
		transition: background .3s ease;

		@include g.smMax {
			height: 50px;
		}
		&::after {
			position: absolute;
			@include g.centering-elements(false, true);
		}
		@include g.hover {
			background: g.$baseColorLight;
			color: g.$white;
		}
	}
}
