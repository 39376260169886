@charset "utf-8";
@use "../../global/_" as g;
.c-product {

	&-btn {
		display: flex;
		align-items: center;
		margin-top: 38px;
		padding-bottom: 24px;
		border-bottom: 1px solid g.$lineColor;

		@include g.smMax {
			flex-direction: column;
		}
		
		& > * {
			&:not(:first-child) {
				margin: 0 0 0 16px;

				@include g.smMax {
					margin: 30px 0 0;
				}
			}
		}
	}

	&-lead {
		margin-top: 32px;
		
		img {
			max-width: 100%;

			@include g.print {
				display: block;
				margin: 0 auto;
			}
		}
		&__body {
			& > * {
				margin-top: 15px;
			}
		}
		&-slide {
			&-panel {
				&._active_ {
					animation: display_anim .75s forwards;
				}
				.__elem { width: 100%; }
			}
			&-tab {
				display: flex;
				margin-top: 10px;

				&__item {
					position: relative;
					width: 80px;
					cursor: pointer;

					@include g.smMax {
						width: 60px;
					}
					&:not(:first-child) {
						margin-left: 10px;
					}
					&::before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						border: 1px solid transparent;
						transition: border-color .3s ease;
					}
					&._active_ {
						&::before {
							border-color: g.$baseColor;
						}
					}
					@include g.hover {
						&::before {
							border-color: g.$baseColor;
						}
					}
					.__elem { width: 100%; }
				}
			}
		}
		&-slide + &__body {
			margin-top: 30px;
		}
	}

	.e-ttl02 + &-lead {
		padding-top: 32px;
		border-top: 1px solid g.$lineColor;
	}

	//特長
	&-feature {
		counter-reset: rank 0;

		&__content {
			margin-top: 56px;

			@include g.smMax {
				margin-top: 45px;
			}
		}
		&__ttl {
			display: flex;
			margin-bottom: 16px;
			padding-bottom: 16px;
			border-bottom: 1px solid g.$lineColor;
			@include g.font-bold;
			@include g.l_height(20, 35);

			@include g.smMax {
				@include g.l_height(16, 28);
			}
			&::before {
				flex: 0 0 auto;
				counter-increment: rank;
				content: counter(rank);
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 14px;
				background: g.$baseColor;
				color: g.$white;
				@include g.circle(38);
				@include g.font-en;
				@include g.l_height(16, 16);

				@include g.smMax {
					width: 30px;
					height: 30px;
					margin-right: 10px;
					@include g.l_height(14, 14);
				}
			}
		}
	}

	//関連製品
	@include g.smMax {
		&-related {
			padding-bottom: 55px;

			.slick-dots {
				bottom: 14px;
				pointer-events: none;
			}
			.slick-dots li {
				width: 8px;
				height: 8px;
				margin: 0 8px;
			}
			.slick-dots li button {
				width: 8px;
				height: 8px;
				padding: 0;
				pointer-events: all;
			}
			.slick-dots li button:before {
				content: '';
				width: 8px;
				height: 8px;
				background: g.$baseColor;
				border-radius: 50%;
				opacity: .3;
			}
			.slick-dots li.slick-active button:before {
				opacity: 1;
			}
			.slick-prev,
			.slick-next {
				top: auto;
				bottom: 0;
				width: 35px;
				height: 35px;
				transform: translate(0, 0);

				&::before {
					content: none;
				}
				.__ico {
					width: 35px;
					height: 35px;
				}
			}
			.slick-prev { left: 0; }
			.slick-next { right: 0; }
		}
	}

	//製品・サービス一覧
	&-category {
		position: relative;

		@include g.smMin {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-rows: auto;
			gap: 8px 15px;
			grid-auto-flow: row;
			grid-template-areas:
				"head01 head02 head03"
				"body01 body01 body01"
				"head04 head05 head06"
				"body02 body02 body02"
				"head07 head08 head09"
				"body03 body03 body03"
				"head10 head11 head12"
				"body04 body04 body04";
			align-items: flex-start;
		}
		@include g.smMax {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto;
			gap: 6px 12px;
			grid-auto-flow: row;
			grid-template-areas:
				"head01 head02"
				"body01 body01"
				"head03 head04"
				"body02 body02"
				"head05 head06"
				"body03 body03"
				"head07 head08"
				"body04 body04"
				"head09 head10"
				"body05 body05"
				"head11 head12"
				"body06 body06";
			align-items: flex-start;
		}

		&__details {
			display: contents;
		}
		&__details:nth-child(1)  &-head { grid-area: head01; }
		&__details:nth-child(2)  &-head { grid-area: head02; }
		&__details:nth-child(3)  &-head { grid-area: head03; }
		&__details:nth-child(4)  &-head { grid-area: head04; }
		&__details:nth-child(5)  &-head { grid-area: head05; }
		&__details:nth-child(6)  &-head { grid-area: head06; }
		&__details:nth-child(7)  &-head { grid-area: head07; }
		&__details:nth-child(8)  &-head { grid-area: head08; }
		&__details:nth-child(9)  &-head { grid-area: head09; }
		&__details:nth-child(10) &-head { grid-area: head10; }
		&__details:nth-child(11) &-head { grid-area: head11; }
		&__details:nth-child(12) &-head { grid-area: head12; }

		@include g.smMin {
			&__details:nth-child(1)  &__body { grid-area: body01; }
			&__details:nth-child(2)  &__body { grid-area: body01; }
			&__details:nth-child(3)  &__body { grid-area: body01; }
			&__details:nth-child(4)  &__body { grid-area: body02; }
			&__details:nth-child(5)  &__body { grid-area: body02; }
			&__details:nth-child(6)  &__body { grid-area: body02; }
			&__details:nth-child(7)  &__body { grid-area: body03; }
			&__details:nth-child(8)  &__body { grid-area: body03; }
			&__details:nth-child(9)  &__body { grid-area: body03; }
			&__details:nth-child(10) &__body { grid-area: body04; }
			&__details:nth-child(11) &__body { grid-area: body04; }
			&__details:nth-child(12) &__body { grid-area: body04; }
		}
		@include g.smMax {
			&__details:nth-child(1)  &__body { grid-area: body01; }
			&__details:nth-child(2)  &__body { grid-area: body01; }
			&__details:nth-child(3)  &__body { grid-area: body02; }
			&__details:nth-child(4)  &__body { grid-area: body02; }
			&__details:nth-child(5)  &__body { grid-area: body03; }
			&__details:nth-child(6)  &__body { grid-area: body03; }
			&__details:nth-child(7)  &__body { grid-area: body04; }
			&__details:nth-child(8)  &__body { grid-area: body04; }
			&__details:nth-child(9)  &__body { grid-area: body05; }
			&__details:nth-child(10) &__body { grid-area: body05; }
			&__details:nth-child(11) &__body { grid-area: body06; }
			&__details:nth-child(12) &__body { grid-area: body06; }
		}

		&-head {
			position: relative;
			display: block;
			height: 263px;
			padding: 48px 20px 0;
			border: 1px solid g.$lineColor;
			transition: border .3s ease, background .3s ease, height .3s ease, padding .3s ease;
			cursor: pointer;

			@include g.smMax {
				height: auto;
				padding: 24px 10px 52px;
			}
			&::before,
			&::after {
				content: '';
				display: block;
				width: 16px;
				height: 2px;
				background: g.$baseColorLight;
				transition: background .3s ease, rotate .3s ease;

				@include g.smMin {
					position: absolute;
					right: 24px;
					top: 230px;
				}
				@include g.smMax {
					bottom: 24px;
					@include g.centering-elements(true, false);
				}
			}
			&::after {
				rotate: 90deg;
			}
			&__ico {
				position: relative;
				display: block;
				fill: g.$white;

				.__ico1,
				.__ico2 {
					display: block;
					width: 100px;
					height: 80px;
					transition: opacity .3s ease;

					@include g.smMax {
						width: 56px;
						height: 48px;
					}
				}
				.__ico1 {
					margin: 0 auto;
				}
				.__ico2 {
					top: 0;
					@include g.centering-elements(true, false);
					opacity: 0;
				}
			}
			&__txt {
				display: block;
				margin-top: 10px;
				text-align: center;
				@include g.font-bold;
				@include g.l_height(20, 35);
				transition: color .3s ease;

				@include g.smMax {
					@include g.l_height(15, 26);
				}
			}
			@include g.media-hover {
				&:hover {
					background: g.$baseColorLight;
					border-color: transparent;
					&::before,
					&::after {
						background: g.$white;
					}
				}
				&:hover &__ico {
					.__ico1 { opacity: 0; }
					.__ico2 { opacity: 1; }
				}
				&:hover &__txt {
					color: g.$white;
				}
			}

			// open時
			&._open_ {
				background: g.$baseColorLight;
				border-color: transparent;
	
				@include g.smMin {
					height: 278px;
				}
				@include g.smMax {
					padding-bottom: 62px;
				}
				&::before,
				&::after {
					background: g.$white;
	
					@include g.smMax {
						bottom: 34px;
					}
				}
				&::after {
					rotate: 0deg;
				}
			}
			&._open_ &__ico {
				.__ico1 { opacity: 0; }
				.__ico2 { opacity: 1; }
			}
			&._open_ &__txt {
				color: g.$white;
			}
		}
		&__body {
			width: 100%;
			margin: -8px 0 8px;
			padding: 40px;
			background: g.$bgColorGray;
			border-top: 6px solid g.$baseColorLight;

			@include g.smMax {
				margin: -6px 0 6px;
				padding: 30px 10px;
			}
		}
	}
}
