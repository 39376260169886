@charset "utf-8";
@use "../../global/_" as g;
.m-company-flow {
	&__item {
		position: relative;

		&:not(:first-child) {
			margin-top: 56px;

			@include g.smMax {
				margin-top: 32px;
			}
			&::before {
				content: '';
				top: -33px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 10px 0;
				border-color: g.$lineColor transparent transparent;
				@include g.centering-elements(true, false);

				@include g.smMax {
					top: -21px;
				}
			}
		}
	}
	&__box {
		border: 1px solid g.$lineColor;

		@include g.smMin {
			display: flex;
		}
	}
	&__ttl {
		flex: 0 0 220px;
		padding: 24px;
		background: g.$bgColorBlue;
		@include g.font-medium;

		@include g.smMin {
			border-right: 1px solid g.$lineColor;
		}
		@include g.smMax {
			padding: 15px;
			border-bottom: 1px solid g.$lineColor;
		}
	}
	&__txt {
		flex: 1 1 auto;
		padding: 24px;

		@include g.smMax {
			padding: 15px;
		}
	}
}