@charset "utf-8";
@use "../../global/_" as g;
.e-btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 300px;
	height: 60px;
	padding: 5px 48px;
	background: g.$baseColor;
	border: 1px solid transparent;
	color: g.$white;
	text-align: center;
	text-decoration: none;
	@include g.font-medium;
	transition: background .3s ease, color .3s ease;
	cursor: pointer;

	@include g.smMax {
		min-width: 280px;
		min-height: 50px;
		height: auto;
	}
	&::before,
	&[href$=".pdf"]::before {
		right: 20px;
		color: g.$white;
		@include g.font-icon('\f105');
		@include g.centering-elements(false, true);
		transition: color .3s ease;
	}
	&[target*="blank"] {
		&::before { right: 15px; }
	}
	&[href$=".pdf"] {
		padding-left: 70px;

		@include g.smMax {
			padding-left: 56px;
		}
		&::after {
			left: 32px;
			@include g.font-icon('\e914');
			@include g.l_height(24, 24);
			@include g.centering-elements(false, true);

			@include g.smMax {
				left: 20px;
				font-size: g.$fz-xxl;
			}
		}
	}
	[class^="icon"] {
		margin-right: 5px;
	}
	&:not(._disabled_):not(:disabled) {
		@include g.hover {
			background: g.$baseColorLight;
			color: g.$white;
		}
	}

	// disabled
	&:disabled,
	&._disabled_ {
		cursor: auto;
		opacity: .5;
	}

	//サイズオプション S-------------------------------
	&._sm_ {
		min-width: 220px;
		height: 44px;
		padding: 5px 48px;
		font-size: g.$fz-s;

		@include g.smMax {
			min-width: 280px;
			min-height: 40px;
			height: auto;
			font-size: g.$fz-xs;
		}

		&:not(._more_):not(._close_):not(._icon-close02_) {
			&::before {
				font-size: g.$fz-m;
			}
		}
		&[href$=".pdf"] {
			padding-left: 65px;
	
			@include g.smMax {
				padding-left: 50px;
			}
			&::after {
				left: 28px;
				@include g.l_height(20, 20);
	
				@include g.smMax {
					font-size: g.$fz-m;
				}
			}
		}
	}
	&._l_ {
		width: min(388px, 100%);
		height: 66px;

		@include g.smMax {
			height: auto;
		}
	}

	//アイコンオプション 戻る-------------------------------
	&._icon-prev_ {
		&::before {
			content: '\f104';
			right: auto;
			left: 20px;
		}
	}
	//アイコンオプション もっと見る-------------------------------
	&._icon-more_ {
		&::before {
			content: '\e905';
			font-size: g.$fz-xxxs;
		}
	}
	//アイコンオプション 閉じる-------------------------------
	&._icon-close_ {
		&::before {
			content: '\e912';
			font-size: g.$fz-xxxs;
		}
	}
	//アイコンオプション 閉じる（アコーディオン）-------------------------------
	&._icon-close02_ {
		&::before {
			content: '\e904';
			font-size: g.$fz-xxxs;
		}
	}
	//アイコンオプション ダウンロード-------------------------------
	&._icon-download_ {
		&::before {
			content: '\e910';
			color: g.$white;
		}
		&[href$=".pdf"] {
			&::before {
				content: none;
			}
		}
	}
	//アイコンオプション クリア-------------------------------
	&._icon-clear_ {
		&::before {
			content: '\e913';
		}
	}
	//アイコンオプション PDF------------------------------
	&._icon-pdf_ {
		&::before {
			content: '\e910';
			color: g.$white;
		}
	}

	
	//カラーオプション Light-------------------------------
	&._bg-light_ {
		background: g.$baseColorLight;

		&:not(._disabled_) {
			@include g.hover {
				background: g.$white;
				border-color: g.$baseColorLight;
				color: g.$baseColorLight;

				&::before {
					color: g.$baseColorLight;
				}
			}
		}

	}
	
	//カラーオプション アウトライン（青）-------------------------------
	&._outline_ {
		background: none;
		border-color: g.$baseColorLight;
		color: g.$baseColorLight;

		&::before {
			color: g.$baseColorLight;
		}
		&:not(._disabled_) {
			@include g.hover {
				background: g.$baseColorLight;
				color: g.$white;

				&::before {
					color: g.$white;
				}
			}
		}
	}

	//カラーオプション アウトライン（黒）-------------------------------
	&._outline-black_ {
		background: g.$white;
		border-color: g.$textColor;
		color: g.$textColor;

		&::before {
			color: g.$textColor;
		}
		&:not(._disabled_) {
			@include g.hover {
				background: g.$textColor;
				color: g.$white;

				&::before {
					color: g.$white;
				}
			}
		}
	}
	
}
