@charset "utf-8";
@use "../../../global/_" as g;
.e-form-check {
	.__elem {
		display: none;
		&:checked + .__txt {
			color: g.$strongTextColor;
			border-color: g.$strongTextColor;

			&::before {
				background: g.$strongTextColor;
			}
		}
		@include g.hover {
			&:not(:disabled) + .__txt {
				border-color: g.$strongTextColor;
			}
		}
	}
	.__txt {
		position: relative;
		display: block;
		width: fit-content;
		padding: 14px 32px 14px 52px;
		background: g.$white;
		border: 1px solid transparent;
		@include g.font-medium;
		transition: border .3s ease, color .3s ease;
		cursor: pointer;

		@include g.smMax {
			padding: 8px 15px 8px 35px;
			font-size: g.$fz-s;
		}
		&::before {
			left: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: g.$lineColor;
			color: g.$white;
			@include g.circle(18);
			@include g.font-icon('\e915');
			@include g.centering-elements(false, true);
			transition: background .3s ease;

			@include g.smMax {
				left: 10px;
				width: 15px;
				height: 15px;
			}
		}
		.c-grid & {
			width: 100%;
		}
	}

	// disabled
	.__elem:disabled + .__txt {
		color: g.$greenishBlue-600;
		cursor: default;
	}
}
