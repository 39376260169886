@charset "utf-8";
@use "../../global/_" as g;
.e-a-tel {
	display: flex;
	align-items: center;
	width: fit-content;

	.__ico {
		width: 30px;
		height: 18px;
	}
	.__txt {
		margin-left: 3px;
	}
}
