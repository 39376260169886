@charset "utf-8";
@use "../../global/_" as g;
.e-ttl03 {
	position: relative;
	margin-top: 88px;
	margin-bottom: 32px;
	padding-bottom: 20px;
	@include g.font-bold;
	@include g.l_height(32, 48);

	@include g.smMax {
		padding-bottom: 10px;
		@include g.l_height(22, 33);
	}
	&::before,
	&::after {
		content: '';
		position: absolute;
		left: 0;
	}
	&::before {
		bottom: 2px;
		width: 186px;
		height: 1px;
		background: g.$lineColor;

		@include g.smMax {
			width: 146px;
		}
	}
	&::after {
		bottom: 0;
		width: 40px;
		height: 3px;
		background: g.$baseColor;
	}
}
