@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mr-l {
	margin-right: g.$m-l !important;
	
	@include g.smMax {
		margin-right: math.div(g.$m-l, 2) !important;
	}
}
