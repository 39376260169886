@charset "utf-8";
@use "../../../global/_" as g;
.c-index-about {
	position: relative;
	margin-top: 130px;
	padding-bottom: 80px;

	@include g.mdMax {
		margin-top: 65px;
		padding-bottom: 55px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 214px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: g.$greenishBlue-300;

		@include g.smMax {
			top: 360px;
		}
	}
	&__ttl {
		position: relative;
		padding-left: 40px;
		@include g.font-bold;
		@include g.l_height(22, 30);

		@include g.smMax {
			padding-left: 30px;
			@include g.l_height(18, 27);
		}
		&::before {
			content: '';
			position: absolute;
			top: .75em;
			left: 0;
			width: 25px;
			height: 2px;
			background: url('/common/img/bg_ttl05.svg') 0 0 no-repeat;
			background-size: 100% auto;

			@include g.smMax {
				width: 20px;
			}
		}
	}
	.m-menu-list02 {
		margin-top: 40px;

		@include g.smMax {
			margin-top: 20px;
		}
	}
	&__bnr {
		margin-top: 80px;
		display: flex;
		text-align: center;

		@include g.smMax {
			margin-top: 50px;
		}
	}
}