@charset "utf-8";
@use "../../global/_" as g;
.c-pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	z-index: 1;

	@include g.smMax {
		right: 10px;
		bottom: 15px;
	}
	@include g.print {
		display: none;
	}
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		background: g.$black;
		color: g.$white;
		font-size: g.$fz-xxxl;
		@include g.circle(55);
		line-height: 0;
		transition: background .3s ease;

		@include g.smMax {
			width: 40px;
			height: 40px;
			padding-bottom: 3px;
		}
		@include g.hover {
			background: g.$baseColorLight;
			color: g.$white;
		}
	}
}
