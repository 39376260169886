@charset "utf-8";
@use "../../global/_" as g;
.e-ttl07 {
	display: grid;
	grid-template-columns: 25px 1fr;
	grid-template-rows: auto auto;
	gap: 15px 15px;
	grid-auto-flow: row;
	grid-template-areas:
	  "ornament txt1"
	  "txt2 txt2";
	align-items: center;

	@include g.smMax {
		gap: 10px 10px;
	}
	&::before {
		content: '';
		grid-area: ornament;
		display: block;
		width: 25px;
		height: 2px;
		background: url(/common/img/bg_ttl05.svg) 0 0 no-repeat;
		background-size: 100% auto;
		
		@include g.smMax {
			width: 20px;
			height: 2px;
		}
	}
	&::after {
		content: attr(data-txt);
		grid-area: txt1;
		display: block;
		color: g.$baseColorLight;
		@include g.l_height(14, 18);
		@include g.font-en-regular;

		@include g.smMax {
			font-size: g.$fz-xs;
		}
	}
	.__elem {
		grid-area: txt2;
		color: g.$baseColor;
		@include g.l_height(32, 48);
		@include g.font-bold;

		@include g.smMax {
			@include g.l_height(25, 38);
		}
	}
}
