@charset "utf-8";
@use "../../global/_" as g;
.m-contact-box {
	@include g.smMin {
		display: flex;

		&._ai-c_ {
			align-items: center;
		}
	}
	&__body {
		flex: 1 1 auto;
	}
	&__link {
		flex: 0 0 auto;
		margin-left: 48px;

		@include g.smMax {
			margin: 20px 0 0;
			text-align: center;
		}
		.e-a-tel02 {
			justify-content: flex-end;
			margin-top: 10px;

			@include g.smMax {
				justify-content: center;
			}
		}
	}
	&__ttl {
		@include g.font-bold;
		@include g.l_height(20, 30);
	}
	&__category {
		margin-top: 15px;
		@include g.l_height(14, 24);

		@include g.smMin {
			display: flex;
			align-items: flex-start;
		}
		.__head {
			flex: 0 0 auto;
			margin-right: 14px;
			padding: 0 12px;
			border: 1px solid g.$baseColor;
			color: g.$baseColor;
			font-size: g.$fz-s;

			@include g.smMax {
				width: fit-content;
			}
		}
		.__body {
			flex: 1 1 auto;

			@include g.smMax {
				margin-top: 5px;
			}
		}
	}
}