@charset "utf-8";
@use "../../global/_" as g;
.e-ttl04 {
	position: relative;
	margin-top: 88px;
	margin-bottom: 25px; 
	padding-left: 22px;
	@include g.font-bold;
	@include g.l_height(24, 36);

	@include g.smMax {
		padding-left: 16px;
		@include g.l_height(20, 30);
	}
	&::before {
		content: '';
		position: absolute;
		top: .8em;
		left: 0;
		display: block;
		width: 12px;
		height: 1px;
		background: g.$baseColor;

		@include g.smMax {
			width: 10px;
		}
	}

	.e-ttl01 + &,
	.e-ttl02 + &,
	.e-ttl03 + & {
		margin-top: 0;
	}
	&:is(.c-product-category__body *) {
		margin-top: 0;
	}

}
