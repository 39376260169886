@charset "utf-8";
@use "../../global/_" as g;

html {
	@include g.smBtMd {
		font-size: calc(100vw / 102.4);
	}
}
body {
	@include g.font-normal;

	@include g.print {
		-webkit-print-color-adjust: exact;
		width: 1200px;
		zoom: .8;
	}
}
[data-whatintent='mouse'] *:focus {
	outline: none;
}
:root {
	--scrollbar: 0;
}
a {
	color: g.$baseColor;
	transition: color .3s ease;
	
	@include g.hover {
		color: g.$strongTextColor;
	}
	&[href^="tel:"] {
		@include g.smMin {
			pointer-events: none;
			color: g.$textColor;
		}
	}
	&[target*="blank"] {
		&::before {
			@include g.font-icon('\e902');
			margin: 0 4px;
			font-size: 1.1rem;
		}
	}
	&[href$=".pdf"] {
		&::before {
			@include g.font-icon('\f1c1');
			margin: 0 4px;
			color: g.$secondaryColorDark;
			font-size: g.$fz-m;
		}
	}
	&._no-icon_[target*="blank"],
	&._no-icon_[href$=".pdf"],
	&.eirItem_title_link[target*="blank"],
	&.eirItem_title_link[href$=".pdf"] {
		&::before {
			content: none;
		}
	}
}
sup {
	font-size: smaller;
	vertical-align: super;
}
sub {
	font-size: smaller;
	vertical-align: sub;
}
input,
textarea {
	appearance: none;
	max-width: 100%;
}
input,
select,
textarea {
	@include g.smMax {
		font-size: g.$fz-m !important;
	}
}	
input::placeholder,
textarea::placeholder {
	opacity: .5;
}
button,
input[type="button"],
input[type="submit"] {
	appearance: none;
}
strong {
	@include g.font-bold;
}

@page {
	margin: 10mm;
	size: 210mm 297mm;
}
