@charset "utf-8";
@use "../../global/_" as g;
.m-report-latest {
	padding: 32px;
	background: g.$bgColorBlue;

	@include g.smMax {
		padding: 15px 15px 30px;
	}
	&__body {
		display: grid;
		grid-template-columns: min(23.6%, 188px) 1fr auto;
		grid-template-rows: auto 1fr;
		gap: 0 32px;
		grid-auto-flow: row;
		grid-template-areas:
			"thumb ttl date"
			"thumb txt txt";
		
		@include g.smMax {
			display: grid;
			grid-template-columns: min(29.85%, 100px) 1fr;
			grid-template-rows: auto 1fr auto;
			gap: 0 24px;
			grid-auto-flow: row;
			grid-template-areas:
				"thumb date"
				"thumb ttl"
				"txt txt";
		}
	}
	&__thumb {
		grid-area: thumb;

		.__elem { width: 100%; }
	}
	&__ttl {
		grid-area: ttl;

		@include g.smMax {
			margin-top: 15px;
		}
		.e-ttl04 {
			margin-bottom: 0;
		}
	}
	&__date {
		grid-area: date;
		color: g.$subTextColor1;
		@include g.l_height(14, 24);

		@include g.smMin {
			align-self: center;
		}
	}
	&__txt {
		grid-area: txt;
		margin-top: 24px;

		& > *:not(:first-child) {
			margin-top: 24px;
		}
	}
	&__btn {
		margin-top: 32px;
		padding-top: 32px;
		min-height: 94px;
		border-top: 1px solid g.$lineColor;
		text-align: center;

		&._al-c_ {
			display: flex;
			justify-content: center;
		}
		@include g.smMin {
			position: relative;

			.__more {
				position: absolute;
				top: 32px;
				right: 50%;
				translate: -42px 0;
			}
			.m-report-latest-details__head {
				position: absolute;
				top: 32px;
				left: 50%;
				translate: 42px 0;
			}

		}

		&.__rows03 {

			@include g.smMin {
				.__more {
					right: auto;
					left: 5%;
					translate: -42px 0;
				}

				.m-report-latest-details__head {
					translate: 0;
					transform: translateX(-50%);
				}

				.m-report-latest-details__head.__digest {
					left: auto;
					right: 5%;
					translate: 42px 0;
					transform: translateX(0);
				}
			}

			@include g.smMax {
				.e-btn {
					min-width: 300px;
				}
			}

		}
	}

	&-details {
		@include g.smMax {
			margin-top: 15px;
		}
		&__head {
			display: block;

			&::-webkit-details-marker {
				display: none;
			}
		}
		&__body {
			@include g.smMin {
				padding-top: 122px;
			}
			@include g.smMax {
				margin-top: 32px;
			}
		}
		&__index {
			@include g.smMin {
				display: grid;
				grid-template-columns: 250px 1fr;
				gap: 0 0;
				border-bottom: 1px solid g.$lineColor;

				.__head,
				.__body {
					padding: 24px 32px;
					border-top: 1px solid g.$lineColor;
				}
			}
			@include g.smMax {
				border-top: 1px solid g.$lineColor;

				.__head {
					padding-top: 20px;
				}
				.__body {
					padding: 15px 0 20px;
					border-bottom: 1px solid g.$lineColor;
				}
			}
			.__head,
			.__body {
				text-align: left;
			}
			.__head {
				@include g.font-medium;
			}

		}
		&__close {
			display: flex;
			justify-content: center;
			margin-top: 32px;
		}
	}
	&-details[open] {
		.m-report-latest-details__head {
			.e-btn._icon-more_ {
				&::before {
					content: '\e904';
				}
			}
		}
		.m-report-latest-details__body {
			animation: accordion_anim .5s ease;
		}
	}

	
}

