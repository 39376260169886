@charset "utf-8";
@use "../../global/_" as g;
.mf_finder_searchBox {
	position: relative;
}
.mf_finder_searchBox_items_wrapper {
	.l-header & {
		padding: 32px;

		@include g.smMax {
			padding: 10px 10px 15px;
		}
	}
}
.mf_finder_searchBox_items {
	position: relative;
}
.mf_finder_searchBox_query_input {
	width: 100%;
	padding: 24px 32px;
	background: g.$white;
	border: none;
	border-bottom: 2px solid g.$baseColor;
	@include g.radius(0);

	@include g.smMax {
		padding: 16px;
	}
}
.mf_finder_searchBox_submit {
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 75px;
	height: 75px;
	background: none;
	cursor: pointer;
	@include g.centering-elements(false, true);

	@include g.smMax {
		width: 60px;
		height: 60px;
	}
	span {
		@include g.hidden;
	}
	&::before {
		color: g.$baseColor;
		font-size: g.$fz-xxxl;
		@include g.font-icon("\e906");

		@include g.smMax {
			font-size: g.$fz-l;
		}
	}
}

.mf_finder_spellcheck_wrapper {
	display: none;
}

.mf_finder_searchBox_links {
	.l-header & {
		display: none;
	}
}

// 製品カテゴリ
.mf_finder_searchBox_category_links {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-flow: row;
	gap: 20px 20px;
	margin-top: 60px;

	@include g.smMax {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 10px 10px;
		margin-top: 30px;
	}

	.router-link-active {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 10px 16px;
		min-height: 60px;
		background: g.$white;
		border: 1px solid g.$baseColor;
		color: g.$baseColor;
		transition:
			background 0.3s ease,
			color 0.3s ease;

		@include g.smMax {
			min-height: 50px;
		}
		@include g.hover {
			background: g.$baseColor;
			color: g.$white;
		}
		&.mf_finder_searchBox_link_current {
			background: g.$baseColor;
			color: g.$white;
		}
	}
}
.mf_finder_searchBox_category_link_0 {
	.router-link-active {
		&::before {
			content: "すべて";

			html[lang="en"] & {
				content: "All";
			}
		}
	}
}

// ページ数
.mf_finder_searchBox_pagemax {
	display: none;
}

// 表示順
.mf_finder_searchBox_sort {
	position: absolute;
	right: 0;
	bottom: -265px;
	z-index: 10;
	display: flex;
	align-items: center;

	@include g.smMax {
		bottom: -185px;
	}
	.has-mask & {
		bottom: -415px;

		@include g.smMax {
			bottom: -310px;
		}
	}
	&:before {
		content: "表示順：";
		font-size: 1.2rem;

		html[lang="en"] & {
			margin-right: 0.5em;
			content: "Order:";
		}
	}
	&_links {
		display: flex;
	}
	&_links {
		& > *:not(:first-child) {
			margin-left: 10px;
		}
		.router-link-active {
			padding: 5px 10px;
			background: g.$white;
			border: 1px solid g.$baseColor;
			color: g.$baseColor;
			@include g.l_height(14, 16);
			transition:
				background 0.3s ease,
				color 0.3s ease;

			@include g.hover {
				background: g.$baseColor;
				color: g.$white;
			}
		}
		.router-link-exact-active {
			background: g.$baseColor;
			color: g.$white;
		}
		.mf_finder_searchBox_sort_link_0 {
			.router-link-active {
				&::before {
					content: "Match";
				}
			}
		}
		.mf_finder_searchBox_sort_link_1 {
			.router-link-active {
				&::before {
					content: "Recent";
				}
			}
		}
	}
}

// 類似後
.mf_finder_msk {
	padding: 32px;
	background: g.$greenishBlue-100;
}
.mf_finder_msk_item {
	&::before {
		content: "もしかして：";
	}
	html[lang="en"] & {
		&::before {
			content: "Did you mean : ";
		}
	}
}
.mf_finder_msk_wrapper + .mf_finder_organic_header_wrapper {
	margin-top: 60px;

	@include g.smMax {
		margin-top: 40px;
	}
}

// 検索結果 header
.mf_finder_organic_header {
	display: flex;
	flex-direction: column-reverse;

	.mf_finder_organic_range {
		display: none;
	}
	.mf_finder_query {
		position: relative;
		margin-bottom: 32px;
		padding-bottom: 20px;
		@include g.font-bold;
		@include g.l_height(32, 48);

		@include g.smMax {
			padding-bottom: 10px;
			@include g.l_height(22, 33);
		}

		html[lang="en"] & {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			width: 186px;
			height: 3px;
			background: url(/common/img/bg_ttl03.svg) 0 0 no-repeat;
			background-size: 186px 3px;

			@include g.smMax {
				width: 146px;
				height: 3px;
				background: url(/common/img/bg_ttl03_sp.svg) 0 0 no-repeat;
				background-size: 146px 3px;
			}
		}
		&::after {
			content: "の検索結果";

			html[lang="en"] & {
				content: "Search Results for";
				margin-right: 0.5em;
			}
		}
		span {
			color: g.$subTextColor1;

			&::before {
				content: "“";
			}
			&::after {
				content: "”";
			}
		}
	}
	.mf_finder_organic_total {
		@include g.font-medium;
		&::after {
			content: "件";
			margin-left: 5px;
		}
		html[lang="en"] & {
			&::after {
				content: "Results";
			}
		}
	}
}

// 検索結果なし header
.mf_finder_organic_no_results {
	position: relative;
	margin-bottom: 32px;
	padding-bottom: 20px;
	@include g.font-bold;
	@include g.l_height(32, 48);

	@include g.smMax {
		padding-bottom: 10px;
		@include g.l_height(22, 33);
	}

	html[lang="en"] & {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		width: 186px;
		height: 3px;
		background: url(/common/img/bg_ttl03.svg) 0 0 no-repeat;
		background-size: 186px 3px;

		@include g.smMax {
			width: 146px;
			height: 3px;
			background: url(/common/img/bg_ttl03_sp.svg) 0 0 no-repeat;
			background-size: 146px 3px;
		}
	}

	&::after {
		content: "検索結果がありません";

		html[lang="en"] & {
			content: "No results";
			margin-right: 0.5em;
		}
	}
}

// 検索結果 記事
.mf_finder_organic_docs_wrapper {
	margin-top: 30px;
}
.mf_finder_organic_docs {
	border-bottom: 1px solid g.$lineColor;
}
.mf_finder_organic_doc {
	padding: 35px 0;
	border-top: 1px solid g.$lineColor;

	@include g.smMax {
		padding: 20px 0;
	}
	.mf_finder_organic_doc_img_wrapper {
		display: none;
	}
	.mf_finder_organic_doc_title {
		@include g.font-medium;
		@include g.l_height(20, 30);

		@include g.smMax {
			@include g.l_height(16, 28);
		}
	}
	.mf_finder_organic_doc_body {
		margin-top: 15px;

		@include g.smMax {
			margin-top: 10px;
		}
	}
	.mf_finder_organic_doc_url_wrapper {
		display: block;
		width: fit-content;
		margin-top: 15px;

		@include g.smMax {
			margin-top: 10px;
		}
		&.mf_finder_link {
			display: flex;
		}
	}
	.mf_finder_organic_doc_url {
		word-break: break-all;
	}
}

// pager
.mf_finder_organic_pager {
	margin: 45px 0 0;
	line-height: 1.4;

	.mf_finder_pager_items {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		& > li {
			margin: 3px;

			& > span,
			& > a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 45px;
				height: 45px;
				background: g.$white;
				border: 1px solid g.$baseColor;
				color: g.$baseColor;
				text-decoration: none;
				transition:
					background 0.3s ease,
					color 0.3s ease;
			}
			& > a {
				@include g.hover {
					background: g.$baseColor;
					color: g.$white;
				}
			}
			& > span {
				background: g.$baseColor;
				color: g.$white;
			}
		}
	}
	.mf_finder_pager_item_prev {
		.router-link-active {
			&::before {
				@include g.font-icon("\f104");
			}
		}
	}
	.mf_finder_pager_item_next {
		.router-link-active {
			&::before {
				@include g.font-icon("\f105");
			}
		}
	}
	.mf_finder_pager_item_first {
		.router-link-active {
			&::before {
				@include g.font-icon("\f100");
			}
		}
	}
}

// ロゴ
.mf_finder_logo {
	margin-top: 20px;
	text-align: right;

	a {
		display: inline-block;
		width: 200px;
		height: 18px;
		font-size: 0;
		background: url("//c.marsflag.com/mf/img/mf_finder_logo.png") no-repeat 0 0;
		background-size: 200px 18px;
	}
}
