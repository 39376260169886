@charset "utf-8";
@use "../../global/_" as g;
.e-list-number {
	counter-reset: number 0;

	&__item {
		position: relative;
		padding-left: 23px;

		@include g.smMax {
			padding-left: 20px;
		}
		&:not(:first-child) {
			margin-top: 15px;
		}
		&::before {
			content: counter(number)".";
			counter-increment: number;
			position: absolute;
			left: 0;
			display: block;
			color: g.$baseColor;
			@include g.font-medium;
		}
	}
}
