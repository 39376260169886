@charset "utf-8";
@use "../vars/_" as v;
@use "sass:math";

// ============================================================
//  vw 
// ============================================================
@function sm_vw($size, $viewport: v.$smDesignW){
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}
@function md_vw($size, $viewport: v.$mdDesignW){
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}
@function lg_vw($size, $viewport: v.$lgDesignW){
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}
@function xl_vw($size, $viewport: v.$xlDesignW){
	$rate: math.div(100, $viewport);
	@return $rate * $size * 1vw;
  }

@function sm_vh($size, $viewport: v.$smDesignW){
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vh;
}
@function md_vh($size, $viewport: v.$mdDesignW){
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vh;
}
@function lg_vh($size){
  $rate: math.div(100, 760);
  @return $rate * $size * 1vh;
}

// ============================================================
//  media query 
// ============================================================
@mixin xsMin {
	@media print, screen and (v.$xsMin) {
		@content;
	}
}
@mixin smMin {
	@media print, screen and (v.$smMin) {
		@content;
	}
}
@mixin mdMin {
	@media print, screen and (v.$mdMin) {
		@content;
	}
}
@mixin lgMin {
	@media print, screen and (v.$lgMin) {
		@content;
	}
}
@mixin xlMin {
	@media print, screen and (v.$xlMin) {
		@content;
	}
}
@mixin mdBtLg {
	@media screen and (v.$mdMin) and (v.$lgMax){
		@content;
	}
}
@mixin smBtMd {
	@media screen and (v.$smMin) and (v.$mdMax){
		@content;
	}
}
@mixin smBtLg {
	@media screen and (v.$smMin) and (v.$lgMax){
		@content;
	}
}
@mixin xsMax {
	@media screen and (v.$xsMax) {
		@content;
	}
}
@mixin smMax {
  @media screen and (v.$smMax) {
    @content;
  }
}
@mixin mdMax {
  @media screen and (v.$mdMax) {
    @content;
  }
}
@mixin lgMax {
  @media screen and (v.$lgMax) {
    @content;
  }
}
@mixin max-screen($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}
@mixin min-screen($breakpoint) {
  @media print, screen and (min-width: $breakpoint) {
    @content;
  }
}
@mixin screen($breakpoint-min, $breakpoint-max) {
  @media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
    @content;
  }
}
@mixin print {
	@media print {
		@content;
	}
}

// ============================================================
//  font 
// ============================================================
@mixin font-normal {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 400;
}
@mixin font-medium {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 500;
}
@mixin font-bold {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 700;
}
@mixin font-en {
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}
@mixin font-en-regular {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}
@mixin font-en-medium {
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
}
@mixin font-en-semibold {
	font-family: 'Montserrat', sans-serif;
	font-weight: 600;
}
@mixin font-icon($content) {
	content: $content;
	font-family: 'icomoon';
	font-weight: normal;
}

// ============================================================
//  grid 
// ============================================================
@mixin column($col){
	width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$grid-margin});
	margin-left: calc(#{v.$grid-margin} / 2);
	margin-right: calc(#{v.$grid-margin} / 2);
	margin-bottom: v.$grid-margin;

	@include smMax {
		width: calc(#{v.$max-width} / #{v.$maxcol} * #{$col} - #{v.$grid-margin-sm});
		margin-left: calc(#{v.$grid-margin-sm} / 2);
		margin-right: calc(#{v.$grid-margin-sm} / 2);
		margin-bottom: v.$grid-margin-sm;
	}
}

// ============================================================
//  icon
// ============================================================
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace +
		str-replace(
			str-slice($string, $index + str-length($search)),
			$search,
			$replace
		);
	}
	@return $string;
}
@function url-encode($string) {
	$map: (
		'%': '%25',
		'<': '%3C',
		'>': '%3E',
		' ': '%20',
		'!': '%21',
		'*': '%2A',
		"'": '%27',
		'"': '%22',
		'(': '%28',
		')': '%29',
		';': '%3B',
		':': '%3A',
		'@': '%40',
		'&': '%26',
		'=': '%3D',
		'+': '%2B',
		'$': '%24',
		',': '%2C',
		'/': '%2F',
		'?': '%3F',
		'#': '%23',
		'[': '%5B',
		']': '%5D'
	);
	$new: $string;
	@each $search, $replace in $map {
		$new: str-replace($new, $search, $replace);
	}
	@return $new;
}
@function inline-svg($string) {
	@return url('data:image/svg+xml;charset=utf8,#{url-encode($string)}');
}
@mixin ico-circle-blank($bgColor: v.$white, $borderColor: v.$greenishBlue-700, $iconColor: v.$baseColor, $width: 45px, $height: 45px) {
	content: '';
	display: inline-block;
	width: $width;
	height: $height;
	background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="#{$bgColor}" d="M25,0c13.8,0,25,11.2,25,25 S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z"/><path fill="#{$borderColor}" d="M25,1.5c-6.3,0-12.2,2.4-16.6,6.9C3.9,12.8,1.5,18.7,1.5,25s2.4,12.2,6.9,16.6c4.4,4.4,10.4,6.9,16.6,6.9 s12.2-2.4,16.6-6.9c4.4-4.4,6.9-10.4,6.9-16.6s-2.4-12.2-6.9-16.6C37.2,3.9,31.3,1.5,25,1.5 M25,0c13.8,0,25,11.2,25,25 S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z"/><path fill="#{$iconColor}" d="M21.4,22.9h10.2v5.8H21.4V22.9z M19.9,30.2H33V18.5H19.9V30.2z"/><path fill="#{$iconColor}" d="M17,22.9v10.2h11.7v-1.5H18.4v-8.7H17 z"/></svg>') 0 0 no-repeat;
}
@mixin ico-circle-arrow-left($bgColor: v.$white, $borderColor: v.$greenishBlue-700, $iconColor: v.$baseColor, $width: 45px, $height: 45px) {
	content: '';
	display: inline-block;
	width: $width;
	height: $height;
	background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="#{$bgColor}" d="M25,0c13.8,0,25,11.2,25,25 S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z"/><path fill="#{$borderColor}" d="M25,1.5c-6.3,0-12.2,2.4-16.6,6.9C3.9,12.8,1.5,18.7,1.5,25s2.4,12.2,6.9,16.6c4.4,4.4,10.4,6.9,16.6,6.9 s12.2-2.4,16.6-6.9c4.4-4.4,6.9-10.4,6.9-16.6s-2.4-12.2-6.9-16.6C37.2,3.9,31.3,1.5,25,1.5 M25,0c13.8,0,25,11.2,25,25 S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z"/><path fill="#{$iconColor}" d="M21.6,25.5c0-0.1,0.1-0.2,0.1-0.3 l5.4-5.4c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1l0.6,0.6c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1-0.1,0.2-0.1,0.3l-4.5,4.5l4.5,4.5 c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3l-0.6,0.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1l-5.4-5.4 C21.7,25.6,21.6,25.6,21.6,25.5L21.6,25.5z"/></svg>') 0 0 no-repeat;
}
@mixin ico-circle-arrow-right($bgColor: v.$white, $borderColor: v.$greenishBlue-700, $iconColor: v.$baseColor, $width: 45px, $height: 45px) {
	content: '';
	display: inline-block;
	width: $width;
	height: $height;
	background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="#{$bgColor}" d="M25,0c13.8,0,25,11.2,25,25 S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z"/><path fill="#{$borderColor}" d="M25,1.5c-6.3,0-12.2,2.4-16.6,6.9C3.9,12.8,1.5,18.7,1.5,25s2.4,12.2,6.9,16.6c4.4,4.4,10.4,6.9,16.6,6.9 s12.2-2.4,16.6-6.9c4.4-4.4,6.9-10.4,6.9-16.6s-2.4-12.2-6.9-16.6C37.2,3.9,31.3,1.5,25,1.5 M25,0c13.8,0,25,11.2,25,25 S38.8,50,25,50S0,38.8,0,25S11.2,0,25,0z"/><path fill="#{$iconColor}" d="M28.4,25.5c0,0.1-0.1,0.2-0.1,0.3 l-5.4,5.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2-0.1-0.3-0.1l-0.6-0.6c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.3l4.5-4.5 l-4.5-4.5c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.2,0.1-0.3l0.6-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1l5.4,5.4 C28.3,25.3,28.4,25.4,28.4,25.5L28.4,25.5z"/></svg>') 0 0 no-repeat;
}
@mixin toko-ornament($color: v.$baseColor, $width: 14.3vw) {
	content: '';
	display: block;
	width: min(172px, $width);
	aspect-ratio: 142 / 41;
	background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0  172 41"><polygon fill="#{$color}" points="172,16 105,16 81,16 27,16 27,41 105,41 105,22 172,22 "/><rect fill="#{$color}" width="91" height="6"/></svg>') 0 0 no-repeat;
}
@mixin icon-office($shape) {
	content: '';
	display: block;
	width: 12px;
	height: 12px;

	@if $shape == icon-zip {
		background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path fill="#{v.$linkTextColor}" d="M1,4.8c0,0.4,0.4,0.8,0.8,0.8h3.4v4.6C5.2,10.6,5.6,11,6,11s0.8-0.4,0.8-0.8c0,0,0,0,0,0V5.6 h3.4c0.4,0,0.8-0.4,0.8-0.8S10.6,4,10.2,4c0,0,0,0,0,0H1.8C1.4,4,1,4.4,1,4.8z M1,1.8c0,0.4,0.4,0.8,0.8,0.8h8.4 c0.4,0,0.8-0.4,0.8-0.8S10.6,1,10.2,1c0,0,0,0,0,0H1.8C1.4,1,1,1.4,1,1.8z"/></svg>') 0 0 no-repeat;
	} @else if $shape == icon-address {
		background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path fill="#{v.$linkTextColor}" d="M4.5,4c0-0.8,0.7-1.4,1.5-1.4c0.8,0,1.5,0.6,1.5,1.4c0,0.8-0.7,1.4-1.5,1.4 C5.2,5.4,4.5,4.8,4.5,4z M1.5,4.1C1.5,6.8,6,12,6,12s4.5-5.2,4.5-7.9C10.4,1.7,8.4-0.1,6,0C3.6-0.1,1.6,1.7,1.5,4.1L1.5,4.1z"/></svg>') 0 0 no-repeat;
	} @else if $shape == icon-tel {
		background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path fill="#{v.$linkTextColor}" d="M5.8,8.3c-1-1-1.5-2.5-1.3-3.9C5.4,4,6.2,3.6,7,3.2L6.2,0.3L3.8,0l0,0C1,4.2,1.9,9.3,7.4,12 l0,0l2.4-1.5l-1.3-3C8.5,7.5,6.4,8.2,5.8,8.3L5.8,8.3z"/></svg>') 0 0 no-repeat;
	} @else if $shape == icon-fax {
		background: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><path fill="#{v.$linkTextColor}" d="M1.3,4.9c0-0.4,0.2-0.6,0.6-0.6h8.2c0.4,0,0.6,0.2,0.6,0.6v2.3c0,0.4-0.2,0.6-0.6,0.6H9V7 c0-0.7-0.3-1-1-1H4C3.3,6,3,6.3,3,7v0.7H1.9c-0.4,0-0.6-0.2-0.6-0.6L1.3,4.9L1.3,4.9z M0,4v4c0,0.7,0.3,1,1,1h2v2c0,0.7,0.3,1,1,1 h4c0.7,0,1-0.3,1-1V9h2c0.7,0,1-0.3,1-1V4c0-0.7-0.3-1-1-1L9,3V1c0-0.7-0.3-1-1-1H4C3.3,0,3,0.3,3,1v2L1,3C0.3,3,0,3.3,0,4z"/></svg>') 0 0 no-repeat;
	}
}


// ============================================================
//  other 
// ============================================================
@mixin hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	font-size: 1px;
	clip: rect(1px, 1px, 1px, 1px);
	opacity: 0;
	overflow: hidden;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition($duration: .4s) {
	transition: $duration all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		translate: -50% -50%;
	} @else if $horizontal {
		left: 50%;
		translate: -50% 0;
	} @else if $vertical {
		top: 50%;
		translate: 0 -50%;
	}
}

@mixin hover {
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			@content;
		}
	 }
}
@mixin media-hover {
	@media (hover: hover) and (pointer: fine) {
		@content;
	 }
}

@mixin object-fit($width: 8, $height: 5) {
	position: relative;
	aspect-ratio: calc($width / $height);
	overflow: hidden;

	.__elem {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: scale .65s ease;
	}
}

// line animation
@mixin line_animation($duration: .3s) {
	animation: line_anim $duration ease-in-out forwards;
}

// line-height
@mixin l_height($fsize, $height) {
	font-size: $fsize * .1rem;
	line-height: calc($height / $fsize);
}

// letter-spacing
@mixin l_spacing($spacing) {
	letter-spacing: ($spacing / 1000 ) + em;
}

// ============================================================
// keyframes
// ============================================================
@keyframes line_anim {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}
@keyframes display_anim {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes accordion_anim {
	0% {
		opacity: 0;
		translate: 0 -5px;
	}
	100% {
		opacity: 1;
		translate: 0 0;
	}
}
@keyframes scrolldown {
	0% {
		scale: 1 0;
		transform-origin: top;
	}
	50% {
		scale: 1 1;
		transform-origin: top;
	}
	51% {
		scale: 1 1;
		transform-origin: bottom;
	}
	100% {
		scale: 1 0;
		transform-origin: bottom;
	}
}
