@charset "utf-8";
@use "../../global/_" as g;
.m-btn-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 45px;

	& > *:not(:first-child) {
		margin: 40px 0 0;
	}

	&._mt-m_ {
		margin-top: g.$m-m;
	}
}
