@charset "utf-8";
@use "../../global/_" as g;
.e-contact {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-auto-flow: row;
	gap: 0 0;

	dt {
		padding-right: .25em;
		@include g.font-medium;
	}
}
