@charset "utf-8";
@use "../../global/_" as g;
@for $i from 0 through 100 {
  .u-mw#{$i}p {
    max-width: #{percentage($i * 0.01)} !important;
  }
	@include g.smMax {
		.u-sm-mw#{$i}p {
			width: #{percentage($i * 0.01)} !important;
		}
	}
}
@for $i from 0 through 90 {
	.u-mw#{$i * 10} {
		max-width: #{$i * 10}px !important;
	}
	@include g.smMax {
		.u-sm-mw#{$i * 10} {
			max-width: #{$i * 10}px !important;
		}
	}
}
