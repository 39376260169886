@charset "utf-8";
@use "../../global/_" as g;
.c-tab02 {
	&-nav {
		display: flex;
		margin-bottom: 40px;

		@include g.smMax {
			flex-wrap: wrap;
		}

		&__item {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			background: g.$white;
			border: 1px solid g.$baseColor;
			color: g.$baseColor;
			@include g.font-bold;
			transition: background .3s ease, color .3s ease;
			cursor: pointer;

			@include g.smMin {
				&:not(:first-child) {
					margin-left: 18px;
				}
			}
			@include g.smMax {
				flex: 0 0 calc((100% - 10px) / 2);
				padding: 10px;
				@include g.l_height(13, 19);

				&:nth-child(2n) {
					margin-left: 10px;
				}
				&:nth-child(n+3) {
					margin-top: 10px;
				}
			}
			@include g.hover {
				background: g.$baseColor;
				color: g.$white;
			}
			&._active_ {
				background: g.$baseColor;
				color: g.$white;
			}

			// カラムオプション 3カラム
			&:nth-child(1):nth-last-child(3),
			&:nth-child(1):nth-last-child(3) ~ & {
				width: calc(100% / 3);
			}
			// カラムオプション 4カラム
			&:nth-child(1):nth-last-child(4),
			&:nth-child(1):nth-last-child(4) ~ & {
				width: calc(100% / 4);
			}
			// カラムオプション 5カラム
			&:nth-child(1):nth-last-child(5),
			&:nth-child(1):nth-last-child(5) ~ & {
				width: calc(100% / 5);
			}
		}
	}
	&__pane{
		&._active_ {
			animation: display_anim .75s forwards;
		}
	}
}
