@charset "utf-8";
@use "../../../global/_" as g;
.c-tktkstories-lead {
	margin: 30px 0 100px;

	@include g.smMax {
		margin-bottom: 50px;
	}
	&__lead {
		margin-top: 50px;
		color: g.$linkTextColor;
		text-align: center;
		@include g.font-bold;
		@include g.l_height(28, 42);

		@include g.smMax {
			margin-top: 30px;
			@include g.l_height(23, 35);
		}
	}
	&__lead + &__txt {
		margin-top: 40px;
	}
	&__txt {
		&._w-s_ {
			max-width: 896px;
			margin-left: auto;
			margin-right: auto;
		}
		&._al-c_ {
			text-align: center;
		}
	}
	&__img {
		position: relative;
		margin-top: 56px;
		padding: 0 40px 40px;
		text-align: center;

		@include g.smMax {
			padding: 0 15px 15px;
		}
		.__elem {
			max-width: 100%;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: -1;
			width: 100%;
			background: g.$bgColorBlue;
			aspect-ratio: 1200 / 313;
		}
	}
}