@charset "utf-8";
@use "../../global/_" as g;
.c-page-prev {
	display: flex;
	justify-content: center;
	padding: 60px 0;
	background: g.$greenishBlue-300;

	@include g.print {
		display: none;
	}
}