@charset "utf-8";
@use "../../global/_" as g;
.e-a-txt-arrow {
	position: relative;
	display: block;
	width: fit-content;
	padding-left: 16px;
	transition: color .3s ease;

	& + & {
		margin-top: 15px;
	}
	@include g.hover {
		color: g.$baseColorLight;
	}
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		@include g.font-icon('\f105');
	}
	&[target*="blank"],
	&[href$=".pdf"],
	&[href$=".zip"],
	&._download_ {
		padding-left: calc(24px + 1em);

		&::before {
			position: absolute;
			left: 18px;
			width: calc(1em + 4px);
			margin: 0;
		}
	}
	&[target*="blank"] {
		&::before {
			top: 7px;

			@include g.smMax { top: 4px; }
		}
	}
	&[href$=".pdf"],
	&[href$=".zip"],
	&._download_ {
		&::before {
			top: 1px;
		}
	}
	&[href$=".zip"],
	&._download_ {
		&::before {
			color: g.$baseColor;
			@include g.font-icon('\e910');
		}
		@include g.hover {
			&::before {
				color: g.$baseColorLight;
			}
		}
	}
}
