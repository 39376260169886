@charset "utf-8";
@use "../../global/_" as g;
.c-anchor-link04 {
	display: flex;

	@include g.smMax {
		border-top: 1px solid g.$lineColor;
		flex-wrap: wrap;
		margin-bottom: -1px;
	}
	&__item {
		flex: 1 1 auto;

		@include g.smMax {
			flex: 0 0 50%;
			border-bottom: 1px solid g.$lineColor;

			&:nth-child(2n + 1) {
				border-right: 1px solid g.$lineColor;
			}
		}

		.__link {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: g.$textColor;
			@include g.font-bold;
			@include g.l_height(18, 31);

			@include g.smMax {
				padding: 16px 0;
				@include g.l_height(14, 30);
			}

			&::after {
				@include g.font-icon("\f107");
				@include g.l_height(18, 18);

				@include g.smMin {
					margin-top: 5px;
				}
			}
			@include g.hover {
			}

			&._no-anchor_ {
				@include g.smMax {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
				}

				&::after {
					content: none;
				}
			}
		}
	}
}
