@charset "utf-8";
@use "../../global/_" as g;
.l-snav {
	margin-top: 80px;
	
	@include g.mdMax {
		margin: 40px -10px 0;
	}
	@include g.print {
		display: none;
	}
	&-ttl {
		padding-bottom: 20px;
		border-bottom: 2px solid g.$baseColor;
		@include g.font-medium;
		@include g.l_height(24, 36);

		@include g.mdMax {
			@include g.l_height(20, 30);
		}
		.__link {
			position: relative;
			display: block; 
			padding-left: 30px;

			@include g.mdMax {
				padding: 0 46px 0 20px;
			}
			&::before {
				position: absolute;
				top: 8px;
				left: 0;
				@include g.font-icon('\f105');
				@include g.l_height(18, 18);
				transition: color .3s ease;

				@include g.mdMax {
					left: auto;
					right: 20px;
				}
			}
		}
	}
	&-level1 {
		&__item {
			border-bottom: 1px solid g.$lineColor;

			& > .__link {
				position: relative;
				display: block;
				padding: 24px 0 24px 46px;
				color: g.$textColor;
				@include g.font-medium;
				@include g.l_height(16, 28);
				transition: color .3s ease;

				@include g.mdMax {
					padding: 20px 46px 20px 15px;
				}

				&::before {
					position: absolute;
					top: 28px;
					left: 16px;
					color: g.$baseColor;
					@include g.font-icon('\f105');
					@include g.l_height(18, 18);
					transition: color .3s ease;

					@include g.mdMax {
						top: 24px;
						left: auto;
						right: 20px;
						font-size: g.$fz-m;
					}
				}
				@include g.hover {
					color: g.$strongTextColor;

					&::before {
						color: g.$strongTextColor;
					}
				}
			}

			& > .__elem {
				position: relative;
				display: block;
				padding: 24px 46px 24px 16px;
				@include g.font-medium;
				@include g.l_height(16, 26);
				cursor: pointer;

				@include g.mdMax {
					padding: 20px 46px 20px 15px;
				}
				&::before,
				&::after {
					content: '';
					position: absolute;
					display: block;
					background: g.$greenishBlue-900;
					@include g.centering-elements(false, true);
				}
				&::before {
					right: 16px;
					width: 10px;
					height: 2px;

					@include g.mdMax {
						right: 20px;
					}
				}
				&::after {
					right: 20px;
					width: 2px;
					height: 10px;
					opacity: 1;
					transition: rotate .3s ease, opacity .3s ease;

					@include g.mdMax {
						right: 24px;
					}
				}
				&._active_ {
					&::after {
						rotate: -90deg;
						opacity: 0;
					}
				}
			}

			//カレント表示
			&._current_ {
				& > .__elem {
					color: g.$baseColor;
				}
				& > .__link:not(._current_) {
					padding-left: 16px;
					color: g.$baseColor;
	
					&::before {
						content: none;
					}
				}
				& > .__link._current_ {
					padding-left: 32px;
					@include g.font-bold;
					pointer-events: none;

					&::before {
						content: '';
						top: 28px;
						right: auto;
						left: 20px;
						display: block;
						width: 2px;
						height: 15px;
						background: g.$baseColor;
					}
				}
			}
		}
	}
	&-level2 {
		display: none;

		@include g.mdMin {
			margin: -8px 0 25px 16px;
			padding: 16px;
			background: g.$bgColorGray;
		}
		@include g.mdMax {
			margin: 0 10px 0 20px;

		}
		&__item {
			@include g.mdMin {
				&:not(:first-child) {
					margin-top: 12px;
				}
			}
			@include g.mdMax {
				border-top: 1px solid g.$lineColor;
			}
			& > .__link {
				position: relative;
				display: block;
				padding-left: 13px;
				color: g.$textColor;
				@include g.font-medium;
				@include g.l_height(14, 24);
				transition: color .3s ease;

				@include g.mdMax {
					display: block;
					padding: 16px 36px 16px 10px;
				}

				&::before {
					position: absolute;
					top: 2px;
					left: 0;
					@include g.font-icon('\f105');
					@include g.l_height(15, 15);
					transition: color .3s ease;

					@include g.mdMax {
						top: 50%;
						left: auto;
						right: 10px;
						translate: 0 -50%;
						color: g.$greenishBlue-900;
						font-size: g.$fz-m;
					}
				}
				@include g.hover {
					color: g.$strongTextColor;

					&::before {
						color: g.$strongTextColor;
					}
				}
			}
			// カレント表示
			&._current_ {
				& > .__link:not(._current_) {
					padding-left: 0;
					color: g.$linkTextColor;

					&::before {
						content: none;
					}
				}
				& > .__link._current_ {
					@include g.mdMin {
						pointer-events: none;
						@include g.font-bold;
					}
					@include g.mdMax {
						background: g.$baseColor;
						color: g.$white;
					}

					&::before {
						@include g.mdMin {
							content: '';
							top: 4px;
							display: block;
							width: 2px;
							height: 15px;
							background: g.$baseColor;
						}
						@include g.mdMax {
							content: none;
						}
					}
				}
			}
		}
	}
	&-level3 {
		display: none;

		@include g.mdMin {
			margin-top: 10px;
			padding: 14px;
			background: g.$white;
		}
		@include g.mdMax {
			margin-left: 10px;
		}
		&__item {
			@include g.mdMin {
				&:not(:first-child) {
					margin-top: 12px;
				}
			}
			@include g.mdMax {
				border-top: 1px solid g.$lineColor;
			}
			& > .__link {
				position: relative;
				display: block;
				padding-left: 13px;
				color: g.$textColor;
				@include g.font-medium;
				@include g.l_height(13, 22);
				transition: color .3s ease;

				@include g.mdMax {
					display: block;
					padding: 14px 36px 14px 10px;
				}

				&::before {
					position: absolute;
					top: 2px;
					left: 0;
					@include g.font-icon('\f105');
					@include g.l_height(15, 15);
					transition: color .3s ease;

					@include g.mdMax {
						top: 50%;
						left: auto;
						right: 10px;
						translate: 0 -50%;
						color: g.$greenishBlue-900;
						font-size: g.$fz-m;
					}
				}
				@include g.hover {
					color: g.$strongTextColor;

					&::before {
						color: g.$strongTextColor;
					}
				}

				// カレント表示
				&._current_ {
					@include g.mdMin {
						pointer-events: none;
						@include g.font-bold;
					}
					@include g.mdMax {
						background: g.$baseColor;
						color: g.$white;
					}

					&::before {
						@include g.mdMin {
							content: '';
							top: 4px;
							display: block;
							width: 2px;
							height: 15px;
							background: g.$baseColor;
						}
						@include g.mdMax {
							content: none;
						}
					}
				}
			}
		}
	}

}

// カレント表示
.l-snav-level1__item._current_ .l-snav-level2,
.l-snav-level2__item._current_ .l-snav-level3 {
	display: block;
}
