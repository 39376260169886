@charset "utf-8";
@use "../../global/_" as g;
.e-ttl01 {
	margin-top: 64px;
	@include g.l_height(36, 50);
	@include g.font-bold;
	text-align: center;

	@include g.smMax {
		margin-top: 32px;
		@include g.l_height(25, 35);
	}
	&::before {
		content: attr(data-txt);
		display: block;
		margin-bottom: 10px;
		color: g.$strongTextColor;
		@include g.font-en-regular;
		@include g.l_height(14, 18);

		@include g.smMax {
			margin-bottom: 5px;
			@include g.l_height(12, 16);
		}
	}

	//下線つきスタイル
	&._line_ {
		padding-bottom: 32px;
		border-bottom: 1px solid g.$lineColor;
	}

	.c-sns-share + & {
		margin-top: 28px;
	}
}
