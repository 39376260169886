@charset "utf-8";
@use "../../global/_" as g;
.e-attention {
	display: flex;
	@include g.font-medium;

	&::before {
		content: '!';
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		@include g.circle(30);
		background: g.$secondaryColorDark;
		color: g.$white;
		font-size: g.$fz-l;
	}
}
