@charset "utf-8";
@use "../../../global/_" as g;
.c-tktkstories-index {
	counter-reset: section 0;

	&__head {
		position: relative;
		z-index: 1;
		background: g.$white;

		@include g.smMin {
			padding: 24px 0 46px;
		}
		@include g.smMax {
			padding-top: 5px;
		}
	}
	&__footer {
		background: g.$white;

		@include g.mdMax {
			padding-top: 20px;
		}
	}
	&__movie {
		width: min(900px, 100%);
		margin: 30px auto 0;
	}
	&__anchor {
		@include g.smMin {
			margin-top: 86px;
		}
	}

	&-section {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		&._visible_ &__bg {
			opacity: 1;
		}
		&__bg {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			display: block;
			opacity: 0;
			transition: opacity 1s ease;

			.__elem {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&__body {
			display: grid;
			grid-template-columns: 455px 1fr;
			grid-template-rows: auto 1fr;
			gap: 64px 0;
			grid-auto-flow: row;
			grid-template-areas:
			  "ttl txt"
			  "ttl btn";

			@include g.mdMin {
				padding: 140px 110px 0 0;
			}
			@media screen and (min-width: 1024px) and (max-width: 1366px) {
				grid-template-columns: auto 1fr;
				gap: 64px 48px;
			}
			@include g.mdMax {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: auto auto auto;
				gap: 0 0;
				grid-auto-flow: row;
				grid-template-areas:
				  "ttl"
				  "txt"
				  "btn";
			}
		}
		&__ttl {
			position: relative;
			grid-area: ttl;
			color: g.$white;
			@include g.font-bold;
			@include g.l_height(36, 54);

			@include g.mdMax {
				font-size: 2.5rem;
			}
			&::before {
				content: '';
				position: absolute;
				top: -10px;
				left: 0;
				display: block;
				width: 25px;
				height: 2px;
				background: url('/common/img/bg_ttl05.svg') 0 0 no-repeat;
				background-size: 100% auto;
			}

			&::after {
				counter-increment: section 1;
				content: '0' counter(section);
				position: absolute;
				top: -10px;
				left: 40px;
				@include g.font-en;
				@include g.l_height(14, 14);
				translate: 0 -50%;
			}
		}
		&__txt { 
			grid-area: txt;
			color: g.$white;
			@include g.font-medium;

			@include g.mdMax {
				margin-top: 24px;
			}
			& > *:not(:first-child) {
				margin-top: 24px;
			}
		}
		&__btn {
			grid-area: btn;
			display: flex;
			justify-content: flex-end;

			@include g.mdMax {
				justify-content: center;
				margin-top: 32px;
			}
		}
		

		// 東光高岳の歴史
		&._history_ &__body {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto 1fr;
			gap: 0 72px;
			grid-auto-flow: row;
			grid-template-areas:
			  "ttl img"
			  "txt img"
			  "btn img";

			@include g.mdMax {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: auto auto auto auto;
				gap: 0 0;
				grid-auto-flow: row;
				grid-template-areas:
				  "ttl"
				  "txt"
				  "img"
				  "btn";
				padding: 80px 0 50px;
				overflow-y: scroll;
			}
		}
		&._history_ &__txt {
			@include g.mdMin {
				margin: 38px 0 0 66px;
			}
		}
		&._history_ &__btn {
			@include g.mdMin {
				margin-top: 64px;
			}
		}
		&._history_ &__img {
			grid-area: img;
			display: flex;
			flex-direction: column;
			translate: 0 -10px;

			@include g.mdMax {
				width: min(450px, 100%);
				margin: 30px auto 0;
			}

			.__elem {
				width: 100%;
			}
		}
		&._history_ &__history-img1 {
			@include g.mdMax {
				width: 209px;
			}
		}
		&._history_ &__history-img2 {
			margin: 32px 0 0 auto;

			@include g.mdMax {
				width: 257px;
				margin-top: 15px;
			}
		}
	}

	// スクロールダウン
	&-scrolldown {
		position: fixed;
		right: 142px;
		bottom: 65px;
		width: 1px;
		height: 186px;
		background: rgba(g.$white, .3);

		@media screen and (min-width: 1024px) and (max-width: 1366px) {
			right: 54px;
		}
		@media screen and (min-width: 1024px) and (max-height: 768px) {
			bottom: 8vh;
			height: 24vh;
		}
		@include g.mdMax {
			display: none;
		}
		&::after{
			content: '';
			position: absolute;
			top: 0;
			display: block;
			width: 1px;
			height: 186px;
			background: g.$white;
			animation: scrolldown 2.6s cubic-bezier(.65, 0, .35, 1) infinite;

			@media screen and (min-width: 1024px) and (max-height: 768px) {
				height: 24vh;
			}
		}
	}

	// ページャー
	&-pager {
		counter-reset: number 0;
		position: fixed;
		right: 163px;
		top: 50%;
		translate: 0 -50%;

		@media screen and (min-width: 1024px) and (max-width: 1366px) {
			right: 75px;
		}
		@include g.mdMax {
			display: none;
		}
		&__item {
			&:not(:first-child) {
				margin-top: 53px;
			}
		}
		&__link {
			position: relative;
			cursor: pointer;

			.__elem {
				display: block;
				@include g.circle(6);
				background: g.$strongTextColor;
				opacity: 0;
				transition: opacity .3s ease;
			}
			&::after {
				counter-increment: number 1;
				content: '0' counter(number);
				position: absolute;
				top: 50%;
				left: 16px;
				color: g.$white;
				@include g.font-en;
				@include g.l_height(14, 18);
				translate: 0 -50%;
				transform-origin: left center;
				transition: scale .3s ease;
			}
			&._active_ {
				.__elem {
					opacity: 1;
				}
				&::after {
					scale: 1.7;
				}
			}
			@include g.hover {
				.__elem {
					opacity: 1;
				}
			}
		}
	}


}
