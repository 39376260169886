@charset "utf-8";
@use "../../global/_" as g;
.c-cookie {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 10;
	width: 100%;
	padding: 16px 28px 16px 40px;
	background: g.$white;
	box-shadow: 0 0 8px rgba(g.$black, .16);
	@include g.l_height(14, 24);
	transition:  z-index .3s ease, opacity .3s ease, visibility .3s ease;

	&._is-hide_ {
		z-index: -1;
		opacity: 0;
		visibility: hidden;
	}
	&._is-show_ {
	}
	@include g.smMin {
		display: flex;
		align-items: center;
	}
	@include g.smMax {
		padding: 40px 10px 15px;
	}
	@include g.print {
		display: none !important;
	}
	&__txt {
		@include g.smMin {
			margin-right: 30px;
		}
		br {
			display: none;

			@media screen and (min-width: 1230px) and (max-width: 1449px) {
				display: block;
			}
		}
	}
	&__agree {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 220px;
		height: 44px;
		background: g.$baseColor;
		color: g.$white;
		@include g.font-medium;
		@include g.l_height(14, 24);
		cursor: pointer;
		transition: background .3s ease;

		@include g.smMin {
			margin-left: auto;
		}
		@include g.smMax {
			width: 100%;
			height: 40px;
			margin-top: 15px;
		}

		@include g.hover {
			background: g.$baseColorLight;
		}
	}
	&__close {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		color: g.$greenishBlue-900;
		line-height: 1;
		cursor: pointer;

		@include g.smMin {
			width: 30px;
			height: 30px;
			margin-left: 55px;
		}
		@include g.smMax {
			position: absolute;
			top: 12px;
			right: 10px;
		}
		&::before {
			@include g.font-icon('\e90b');
		}
		.__hidden {
			@include g.hidden;
		}
	}
}
