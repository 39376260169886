@charset "utf-8";
@use "../../global/_" as g;
.e-caption {
	margin-top: g.$m-xxs;
	@include g.l_height(14, 24);

	@include g.smMax {
		@include g.l_height(13, 22);
	}
	&._al-c_ {
		text-align: center;
	}
}
