@charset "utf-8";
@use "../../global/_" as g;
.e-a-tel02 {
	display: flex;
	align-items: center;
	color: g.$textColor;
	@include g.font-medium;
	@include g.l_height(20, 28);

	&::before {
		width: 18px !important;
		height: 18px !important;
		margin-right: 5px;
		background-size: 18px 18px;
		@include g.icon-office(icon-tel);
	}
}
