@charset "utf-8";
@use "../../global/_" as g;
.c-grid {
	&:not([class*="_col"]) {
		display: flex;
		flex-wrap: wrap;
		margin-left: calc(#{g.$grid-margin} / 2 * -1);
		margin-right: calc(#{g.$grid-margin} / 2 * -1);

		@include g.smMax {
			margin-left: calc(#{g.$grid-margin-sm} / 2 * -1);
			margin-right: calc(#{g.$grid-margin-sm} / 2 * -1);

			&._sm-my24_ {
				.__col {
					margin-bottom: 24px;
				}
			}
		}
	}

	&._center_ {
		justify-content: center;
	}

	.__col {
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;

		@for $i from 1 through g.$maxcol {
			&._col-#{$i}_ {
				@include g.column($i);
			}
			@include g.lgMax {
				&._col-lg-#{$i}_ {
					@include g.column($i);
				}
			}
			@include g.mdMax {
				&._col-md-#{$i}_ {
					@include g.column($i);
				}
			}
			@include g.smMax {
				&._col-sm-#{$i}_ {
					@include g.column($i);
				}
			}
		}
		img {
			max-width: 100%;
		}
		& > *:not(:first-child):not(.m-btn-block) {
			margin-top: g.$m-xxs;
		}
	}
	&._stretch_ {
		.__col {
			display: flex;
		}
	}

	&._col2_ {
		@include g.smMin {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			grid-gap: 34px 34px;
		}

		&._auto-fit_ {
			// ブラウザ幅 1024px 以下で 1カラムを想定
			grid-template-columns: repeat(auto-fit, minmax(482px, 1fr));
		}
	}

	//PC 3カラム -----------------------------------
	&._col3_ {
		@include g.smMin {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: auto;
			grid-gap: 56px 34px;
		}
		&._auto-fit_ {
			grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
		}
	}

	//PC 4カラム -----------------------------------
	&._col4_ {
		@include g.smMin {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: auto;
			grid-gap: 32px 32px;
		}
		&._auto-fit_ {
			@include g.mdMax {
				grid-template-columns: repeat(4, 1fr);
				// ブラウザ幅 769px で 3カラムを想定
				grid-template-columns: repeat(auto-fit, minmax(229px, 1fr));
			}
		}
	}

	//PC 5カラム -----------------------------------
	&._col5_ {
		@include g.smMin {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: auto;
			grid-gap: 24px 24px;
		}
		&._auto-fit_ {
			@include g.mdMax {
				grid-template-columns: repeat(5, 1fr);
				// ブラウザ幅 769px で 3カラムを想定
				grid-template-columns: repeat(auto-fit, minmax(229px, 1fr));
			}
		}
	}

	//余白オプション -----------------------------------
	&._col2_,
	&._col3_,
	&._col4_,
	&._col5_ {
		&._m15_ {
			grid-gap: 15px 15px;
		}
		&._m16_ {
			grid-gap: 16px 16px;
		}
		&._mx60_ {
			grid-column-gap: 60px;
		}
		&._my60_ {
			grid-row-gap: 60px;
		}
	}

	@include g.smMax {
		// SP表示は2カラムを基本とする（PC 2～5カラム） -----------------------------------
		&._col2_,
		&._col3_,
		&._col4_,
		&._col5_ {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			grid-gap: g.$grid-margin g.$grid-margin-sm;
		}

		// SP 強制1カラム -----------------------------------
		&._sm-col1_ {
			display: grid;
			grid-template-columns: repeat(1, 100%);
			grid-template-rows: auto;
			grid-gap: 48px 0;
		}

		&._col2_,
		&._col3_,
		&._col4_,
		&._col5_,
		&._sm-col1_ {
			&._sm-m12_ {
				grid-gap: 12px 12px;
			}
			&._sm-my24_ {
				grid-row-gap: 24px;
			}
			&._sm-my56_ {
				grid-row-gap: 56px;
			}
		}
	}
}
