@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pa-l {
	padding: g.$m-l !important;

	@include g.smMax {
		padding: math.div(g.$m-l, 2) !important;
	}
}
