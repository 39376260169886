@charset "utf-8";
@use "../../global/_" as g;
.c-contact-form {
	padding-top: 80px;

	@include g.smMax {
		padding-top: 50px;
	}
	&__inner {
		margin-top: -80px;
		padding-top: 80px;

		@include g.smMax {
			margin-top: -50px;
			padding-top: 50px;
		}
	}
}
