@charset "utf-8";
@use "../../../global/_" as g;
.e-form-check02 {
	.__elem {
		display: none;

		&:checked + .__txt {
			&::before {
				border-color: g.$strongTextColor;
			}
			&::after {
				opacity: 1;
			}
		}
	}
	
	.__txt {
		position: relative;
		padding: 0 0 0 30px;
		cursor: pointer;
		&::before {
			content: '';
			left: 0;
			display: block;
			width: 20px;
			height: 20px;
			background: g.$white;
			border: 1px solid g.$lineColor;
			@include g.centering-elements(false, true);
			transition: border .3s ease;
		}
		&::after {
			left: 0;
			@include g.font-icon('\e915');
			color: g.$strongTextColor;
			font-size: g.$fz-xxxl;
			line-height: 1;
			opacity: 0;
			@include g.centering-elements(false, true);
			transition: opacity .3s ease;
		}
	}

	// disabled
	.__elem:disabled + .__txt {
		color: g.$greenishBlue-600;
		cursor: default;
	}
}
