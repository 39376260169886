@charset "utf-8";
@use "../../../global/_" as g;
.e-form-file {
	display: flex;
	align-items: center;

	& + &,
	.e-form-error + & {
		margin-top: 24px;
	}
	input[type="file"] {
		opacity: 0;
		visibility: hidden;
		position: absolute;
	}
	.__label {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 162px;
		height: 44px;
		background: g.$baseColorLight;
		border: 1px solid g.$baseColorLight;
		color: g.$white;
		@include g.font-medium;
		cursor: pointer;
		transition: background .3s ease, color .3s ease;

		@include g.smMax {
			width: 90px;
			height: 35px;
			font-size: 1.1rem;
		}
		@include g.hover {
			background: g.$white;
			color: g.$baseColorLight;
		}
	}
	.__filename {
		flex: 1 1 auto;
		margin-left: 24px;

		@include g.smMin {
			word-break: break-all;
			overflow-wrap: break-word;
		}
		@include g.smMax {
			margin-left: 10px;
			font-size: g.$fz-xxs;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	.__fileclear {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 128px;
		height: 44px;
		margin-left: 24px;
		border: 1px solid g.$baseColorLight;
		color: g.$baseColorLight;
		@include g.font-medium;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease, visibility .3s ease;
		cursor: pointer;

		@include g.smMax {
			width: 75px;
			height: 35px;
			margin-left: 10px;
			font-size: g.$fz-xxs;
		}
		&._show_ {
			opacity: 1;
			visibility: visible;
		}
		&::after {
			margin-left: 8px;
			@include g.font-icon('\e912');
			font-size: g.$fz-xxxs;
			line-height: 1;
		}
	}
}
