@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pa-xl {
	padding: g.$m-xl !important;

	@include g.smMax {
		padding:math.div(g.$m-xl, 2) !important;
	}
}
