@charset "utf-8";
@use "../../global/_" as g;
.m-btn-block02 {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;

	@include g.mdMax {
		flex-direction: column;
	}

	& > *:last-child {
		@include g.mdMin {
			position: absolute;
			right: 0;
			bottom: 0;
		}
		@include g.mdMax {
			margin: 24px 0 0;
		}
	}
}
