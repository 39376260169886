@charset "utf-8";
@use "../../../global/_" as g;
.c-ir-policy {

	&-basic-info {
		@include g.smMin {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: repeat(3, auto);
			gap: 5px 1em;
		}
		.__head {
			@include g.font-medium;

			@include g.smMax {
				&:not(:first-child) {
					margin-top: 10px;
				}
			}
		}
		.__body {
		}
	}

}
