@charset "utf-8";
@use "../../global/_" as g;
.m-search-block {
	width: 100%;
	padding: 32px;
	background: g.$gray-100;

	@include g.smMax {
		padding: 10px;

		.l-header & {
			padding: 30px 10px;
		}
	}
	&__ttl {
		margin-bottom: 10px;
		font-size: g.$fz-l;
		@include g.font-bold;
	}
	&__inner {
		position: relative;
	}
	&__input {
		width: 100%;
		height: 75px;
		padding: 0 90px 0 32px;
		background: g.$white;
		border-bottom: 2px solid g.$baseColor;
		font-size: g.$fz-m;

		@include g.mdMax {
			height: 60px;
			padding: 0 55px 0 16px;
		}
	}
	&__submit {
		right: 0;
		width: 90px;
		height: 75px;
		color: g.$baseColor;
		font-size: g.$fz-xxxl;
		background: none;
		@include g.centering-elements(false, true);
		cursor: pointer;

		@include g.smMax {
			width: 50px;
			height: 60px;
			font-size: g.$fz-l;
		}
	}

	&-keyword {
		margin-top: 15px;

		@include g.smMin {
			display: flex;
		}
		&__head {
			font-size: g.$fz-xs;
			@include g.font-bold;

			@include g.smMin {
				flex: 0 0 auto;
				margin-top: 6px;
				font-size: g.$fz-s;
			}
		}
		&__body {
			@include g.smMin {
				flex: 1 1 auto;
				margin-left: 16px;
			}
		}
		&__list {
			display: flex;
			flex-wrap: wrap;
		}
		&__item {
			position: relative;

			@include g.smMax {
				margin-top: 8px;
			}
			&:not(:first-child) {
				margin-left: 16px;
				padding-left: 16px;
				border-left: 1px solid g.$lineColor;

				@include g.smMax {
					margin-left: 8px;
					padding-left: 8px;
				}
			}
			.__elem {
				display: block;
				padding: 6px 12px;
				background: g.$white;
				font-size: g.$fz-s;
				@include g.font-medium;

				@include g.smMax {
					padding: 4px 10px;
					font-size: g.$fz-xxs;
				}
			}
		}
	}
}
