@charset "utf-8";
@use "../../global/_" as g;
.l-inner {
	width: min(100% - 40px, 1200px);
	margin-left: auto;
	margin-right: auto;

	@include g.smMin {
		&._mw1000_ {
			width: min(100% - 40px, 1000px);
		}
		&._mw800_ {
			width: min(100% - 40px, 800px);
		}
	}
	@include g.smMax {
		width: min(100% - 20px, 1040px);

		&._sm-w100p_ {
			width: 100%;
		}
	}
}