@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-ml-l {
	margin-left: g.$m-l !important;

	@include g.smMax {
		margin-left: math.div(g.$m-l, 2) !important;
	}
}
