@charset "utf-8";
@use "../../../global/_" as g;
.c-ir-finance {
	&-graph {
		padding: 24px;
		border: 1px solid g.$lineColor;

		@include g.smMax {
			padding: 8px;
		}
	}
}
