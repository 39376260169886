@charset "utf-8";
@use "../../global/_" as g;
.m-panel-download02 {
	display: flex;
	flex-direction: column;
	padding: 10px 10px 0;
	border: 1px solid g.$lineColor;

	&__body {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
		min-height: 210px;
		padding: 20px 24px;
		background: url('/common/img/bg_texture01.png') 0 0 no-repeat;
		background-size: cover;
	}
	&__logo {
		flex: 0 0 auto;
		width: 82px;
		height: 15px;
		margin-left: auto;
		line-height: 1;

		.__elem {
			width: 82px;
			height: 15px;
		}
	}
	&__product {
		flex: 1 0 auto;
		margin-top: 25px;
		@include g.font-bold;
		@include g.l_height(20, 30);
	}
	&__label {
		flex: 0 0 auto;
		display: flex;
		flex-wrap: wrap;

		.__item {
			margin: 8px 8px 0 0;
			padding: 0 12px;
			background: g.$white;
			border: 1px solid #e0e9f3;
			color: g.$baseColor;
			font-size: g.$fz-s;
			@include g.font-bold;
		}
	}
	&__btn {
		flex: 0 0 auto;
		padding: 24px 0;
		text-align: center;
	}
}