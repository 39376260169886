@charset "utf-8";
@use "../../global/_" as g;
.c-inquiry {

	// PCB使用機器・放射性同位元素使用計器について
	&-pcbra {
		&-check {
			&__ttl {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: g.$fz-l;
				@include g.font-bold;
				text-align: center;

				@include g.smMax {
					font-size: g.$fz-m;
				}
				&::before {
					content: '!';
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					background: g.$secondaryColorDark;
					color: g.$white;
					@include g.circle(30);

					@include g.smMax {
						width: 26px;
						height: 26px;
					}
				}
			}
		}
	}
}