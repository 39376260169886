@charset "utf-8";
@use "../../global/_" as g;
.e-label {
	display: inline-block;
	padding: 2px 12px;
	background: g.$white;
	border: 1px solid g.$linkTextColor;
	color: g.$linkTextColor;
	@include g.font-medium;
	@include g.l_height(14, 22);
	transition: background .3s ease, color .3s ease;

	&._light_ {
		border-color: g.$strongTextColor;
		color: g.$strongTextColor;
	}
}

a.e-label {
	@include g.hover {
		background: g.$linkTextColor;
		color: g.$white;
	}

	&._light_ {
		@include g.hover {
			background: g.$strongTextColor;
		}
	}
}