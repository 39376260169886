@charset "utf-8";
@use "../../../global/_" as g;
.c-company-history {

	@include g.smMin {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto 1fr;
		gap: 64px 64px;
		grid-auto-flow: row;
		grid-template-areas:
		  "tab tab"
		  "pane01 pane02";

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 50%;
			z-index: -1;
			border-left: 1px solid g.$lineColor;
		}
	}
	&-tab {
		grid-area: tab;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 0 64px;
		grid-auto-flow: row;

		@include g.smMax {
			gap: 0 16px;
		}
		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			background: g.$white;
			border: 1px solid g.$lineColor;
			transition: border-color .3s ease;

			@include g.smMin {
				pointer-events: none;
			}
			@include g.smMax {
				&[aria-selected="true"] {
					border-color: g.$baseColor;

					&::before {
						opacity: 1;
					}
				}
			}
			.__elem {
				max-width: 100%;
			}
			&::before {
				content: '';
				position: absolute;
				bottom: -37px;
				left: 50%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 8px 0;
				border-color: g.$baseColor transparent transparent;
				transition: opacity .3s ease;

				@include g.smMax {
					bottom: -20px;
					border-width: 8px 6px 0;
					opacity: 0;
				}
			}
		}
	}
	&__pane {
		@include g.smMax {
			margin-top: 32px;
		}
		.e-ttl03 {
			margin-bottom: 0;

			&:not(:first-child) {
				margin-top: 32px;
			}
		}
		p {
			margin-top: 16px;
		}
		.e-figure {
			margin-top: 32px;
		}
	}
	&__pane#tab-toko { grid-area: pane01; }
	&__pane#tab-takaoka { grid-area: pane02; }
	&__pane#tab-takaoka &__common {
		@include g.smMin {
			margin-left: calc((100% + 64px) * -1);
		}
	}
	&__common {
		margin-top: 32px;
		text-align: center;
		
		@include g.smMin {
			width: calc(200% + 64px);
		}
		.__ttl {
			@include g.font-bold;
			@include g.l_height(20, 30);

			@include g.smMax {
				@include g.l_height(16, 28);
			}
		}
	}
}

