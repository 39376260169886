@charset "utf-8";
@use "../../global/_" as g;
.m-office {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	gap: 0 0;
	grid-template-areas:
		"head"
		"body";
	width: 100%;

	&__head {
		grid-area: head;

		.e-ttl04 {
			margin-bottom: 0;
		}
		.__txt {
			margin-top: 16px;
			color: g.$subTextColor1;
		}
	}
	&__body {
		grid-area: body;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 32px;
		border: 1px solid g.$lineColor;

		@include g.smMax {
			padding: 15px;
		}
	}
	&__head + &__body {
		margin-top: 24px;
	}
	&-data {
		flex: 1 0 auto;
		width: 100%;

		&__zip,
		&__address,
		&__tel,
		&__fax {
			display: flex;
			@include g.font-medium;

			&:not(:first-child) {
				margin-top: 5px;
			}
			&::before {
				flex: 0 0 auto;
				position: relative;
				top: 9px;
				margin-right: 8px;

				@include g.smMax {
					top: 7px;
				}
			}
		}
		&__zip {
			&::before { @include g.icon-office(icon-zip); }
		}
		&__address {
			&::before { @include g.icon-office(icon-address); }
		}
		&__tel {
			&::before { @include g.icon-office(icon-tel); }
		}
		&__fax {
			&::before { @include g.icon-office(icon-fax); }
		}
		&__note {
			margin-top: 16px;
			color: #333;
			@include g.l_height(14, 24);
		}

		& + .e-btn {
			margin-top: 24px;
		}
	}
}