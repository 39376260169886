@charset "utf-8";
@forward "./foundations/_";
@forward "./elements/_";
@forward "./modules/_";
@forward "./components/_";
@forward "./layouts/_";
@forward "./templates/_";
@forward "./application/_";
@forward "./utility/_";

@import url("//fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&family=Noto+Sans+JP:wght@400;500;700&display=swap");

$aos-distance: 15px;
@import "node_modules/aos/src/sass/aos.scss";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";
