@charset "utf-8";
@use "../../../global/_" as g;
.c-index-tktk {
	position: relative;
	margin-top: 130px;
	padding-bottom: 140px;

	@include g.smBtMd {
		margin-top: g.md_vw(65);
		padding-bottom: g.md_vw(70);
	}
	@include g.smMax {
		margin-top: 65px;
		padding-bottom: 65px;
	}
	&::before {
		content: '';
		position: absolute;
		top: 155px;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		background: url('/img/bg_index_tktk01.png') 0 0 no-repeat;
		background-size: cover;

		@include g.smMax {
			top: 83px;
		}
	}
	&__ttl {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: min(1200px, 100% - 40px);
		height: 320px;
		margin: 0 auto;
		background: url('/img/bg_index_tktk02.png') 0 0 no-repeat, linear-gradient(0deg, #3873C8, g.$baseColor);
		background-size: cover;
		color: g.$white;
		text-align: center;

		@include g.smBtMd {
			height: g.md_vw(280);
		}
		@include g.smMax {
			width: calc(100% - 20px);
			height: 200px;
		}
		.__ttl {
			@include g.font-en;
			@include g.l_height(80, 97);

			@include g.smMax {
				@include g.l_height(40, 48);
			}
		}
		.__txt {
			margin-top: 10px;
			@include g.font-medium;
			@include g.l_height(18, 31);

			@include g.smMax {
				@include g.l_height(16, 25);
			}
		}
	}
	&-content1 {
		@include g.mdMin {
			display: flex;
			width: min(1536px, 100%);
			margin: 70px auto 0;
			padding-right: 20px;
		}
		@include g.mdMax {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: auto auto auto auto;
			gap: 0 0;
			grid-auto-flow: row;
			grid-template-areas:
				"lead"
				"img"
				"txt"
				"btn";
			margin-top: 50px;
		}
		&__img {
			flex: 0 0 min(760px ,54.8%);

			.__elem { width: 100%; }
		}
		&__body {
			flex: 0 0 min(540px, 38.9%);
			margin-left: min(60px, 454.8%);
			padding-top: 90px;

			@include g.mdMax {
				display: contents;
			}
		}
		&__lead {
			grid-area: lead;

			@include g.mdMax {
				margin: 0 10px;
			}
			@include g.smBtMd {
				text-align: center;
			}
		}
		&__img {
			grid-area: img;

			@include g.mdMax {
				margin-top: 25px;
			}
		}
		&__txt {
			grid-area: txt;
			margin-top: 25px;

			@include g.mdMax {
				margin-left: 10px;
				margin-right: 10px;
			}
		}
		&__btn {
			grid-area: btn;

			@include g.mdMax {
				margin-top: 25px;
			}
		}
	}

	//東光高岳コラム
	&-column {
		margin-top: 130px;
		overflow: hidden;

		@include g.mdMax {
			margin-top: 65px;
		}
		&__ttl {
			@include g.smMin {
				display: flex;
				align-items: flex-end;
			}
			.__txt {
				margin-left: 60px;

				@include g.smMax {
					margin: 20px 0 0;
				}
			}
		}
		&__slider {
			margin-top: 45px;
			padding-bottom: 60px;

			@include g.smMin {
				overflow: hidden;
			}
			@include g.smMax {
				width: calc(56vw + 20px);
				margin-top: 35px;
				padding-bottom: 45px;
			}
			.slick-list {
				@include g.smMax {
					overflow: visible;
				}
			}
			.slick-dots {
				bottom: 0;

				@include g.smMax {
					width: 100vw;
				}
				li {
					width: 10px;
					height: 10px;
					margin: 0 8px;

					@include g.smMax {
						width: 15px;
						height: 15px;
					}
					button {
						width: 10px;
						height: 10px;
						padding: 0;

						@include g.smMax {
							width: 15px;
							height: 15px;
						}
						&::before {
							content: '';
							background: g.$baseColorLight;
							opacity: .3;
							@include g.circle(10);
							transition: opacity .3s ease;

							@include g.smMax {
								width: 15px;
								height: 15px;
							}
						}
					}
					&.slick-active {
						button {
							&::before {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&__item {
			padding: 0 8px;

			@include g.smMax {
				padding: 0 10px;
			}
		}
		&__link {
			display: block;
		}
		@include g.media-hover {
			&__link:hover &__thumb {
				.__elem {
					scale: 1.1;
				}
			}
			&__link:hover &__txt {
				color: g.$strongTextColor;
			}
		}
		&__thumb {
			@include g.object-fit(3, 2);
		}
		&__date {
			display: block;
			margin: 15px 10px 0;
			color: g.$textColor;
			@include g.font-medium;

			@include g.smMax {
				margin: 10px 0 0;
			}
		}
		&__txt {
			margin: 5px 10px 0;
			@include g.font-bold;
			@include g.l_height(18,31);
			transition: color .3s ease;

			@include g.smMax {
				margin: 5px 0 0;
				@include g.l_height(16,26);
			}
		}
		
	}
}