@charset "utf-8";
@use "../../global/_" as g;
.m-btn-block03 {
	display: flex;
	align-items: center;
	justify-content: center;

	@include g.smMax {
		flex-direction: column;
	}
	& > *:not(:first-child) {
		margin: 0 0 0 32px;

		@include g.smMax {
			margin: 15px 0 0;
		}
	}
}
