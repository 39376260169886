@charset "utf-8";
@use "../../global/_" as g;
.c-form {
	&__block {
		@include g.smMin {
			display: contents;
		}
		@include g.smMax {
			&:not(:first-child) {
				margin-top: 24px;
			}
		}
	}
	&__head {
		display: flex;
		align-items: center;

		&:not(:first-child) {
			margin-top: 24px;
		}
		.__txt {
			@include g.font-medium;
		}
		.__label {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 52px;
			height: 23px;
			margin-left: 16px;
			background: g.$secondaryColorDark;
			color: g.$white;
			@include g.font-medium;
			@include g.l_height(14, 14);

			html[lang="en"] & {
				width: auto;
				padding: 0 5px;
				font-size: g.$fz-xxs;
			}
		}
	}
	&__body {
		margin-top: 10px;
	}

	// 横並び
	@include g.smMin {
		&._horizontal_ {
			display: grid;
			grid-template-columns: 230px 1fr;
			grid-template-rows: repeat(3, 1fr);
			gap: 48px 40px;
			align-items: flex-start;
		}
		&._horizontal_._rows-auto_ {
			grid-template-rows: repeat(3, auto);
		}
		&._horizontal_ &__head {
			padding-top: 12px;

			&:not(:first-child) {
				margin-top: 0;
			}
			.__label {
				margin-left: auto;
			}
		}
		&._horizontal_._confirm_ &__head {
			padding-top: 0;
		}
		&._horizontal_ &__body {
			margin-top: 0;
		}
		&._horizontal_ &__head._as-c_,
		&._horizontal_ &__body._as-c_ {
			padding-top: 0;
			align-self: center;
		}
		&._horizontal_ &__head._as-fs_ {
			align-self: flex-start;
		}
	}

	// エラーメッセージ
	&-error {
		display: flex;
		align-items: center;
		padding: 24px 32px;
		background: g.$secondaryColorLight;
		border: 1px solid g.$secondaryColor;
		color: g.$secondaryColor;

		@include g.smMax {
			padding: 15px;
		}

		&::before {
			content: '!';
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 10px;
			background: g.$secondaryColor;
			color: g.$white;
			@include g.circle(30);
		}

	}

	&-name {
		@include g.smMin {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			grid-auto-flow: row;
			gap: 0 20px;
		}
		&__item {
			display: flex;
			align-items: center;

			@include g.smMax {
				&:not(:first-child) {
					margin-top: 15px;
				}
			}
		}
		.__head {
			flex: 0 0 auto;

			@include g.smMax {
				flex: 0 0 10rem;
			}
		}
		.__body {
			flex: 1 1 auto;
			@include g.smMin {
				margin-left: 5px;
			}
		}
	}

	.container {
	  }
	  

}