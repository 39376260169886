@charset "utf-8";
@use "../../global/_" as g;
.m-menu-list {
	@include g.smMin {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0 0;
	}
	&__item {
		@include g.smMin {
			border-right: 1px solid g.$greenishBlue;
			border-bottom: 1px solid g.$greenishBlue;

			&:nth-child(3n+1) {
				border-left: 1px solid g.$greenishBlue;
			}
			&:nth-child(-n+3) {
				border-top: 1px solid g.$greenishBlue;
			}
		}
		@include g.smMax {
			border: 1px solid g.$greenishBlue;

			&:not(:first-child) {
				border-top: none;
			}
		}
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 15px 25px 15px 15px;

		@include g.smBtMd {
			padding-left: 3%;
			padding-right: 5%;
		}
		@include g.smMax {
			padding: 10px 20px 10px 10px;
		}
		&::after {
			margin-left: auto;
			@include g.ico-circle-arrow-right($width: min(45px, 11.25%));
			background-position: center center;
			transition: background .3s ease;

			@include g.smMax {
				width: 28px;
				height: 28px;
			}
		}
	}
	@include g.media-hover {
		&__link:hover {
			&::after {
				@include g.ico-circle-arrow-right(g.$baseColor, g.$baseColor, g.$white, $width: min(45px, 11.25%));
				background-position: center center;
			}
		}
	}
	&-ico {
		flex: 0 0 min(100px, 25%);
		display: block;

		@include g.smMax {
			width: 65px;
		}
		&__inner {
			position: relative;
			display: block;
			padding-top: 100%;
			background: #f4f6f9;

			.__ico {
				width: min(75px, 100%);
				@include g.centering-elements;

				@include g.smMax {
					width: min(48px, 100%);
				}
			}
		}
	}
	&__txt {
		margin-left: 20px;
		color: g.$baseColor;
		@include g.font-bold;
		@include g.l_height(22, 30);

		@include g.smBtMd {
			margin-left: 5%;
		}
		@include g.smMax {
			margin-left: 15px;
			@include g.l_height(18, 24);
		}
	}
}