@charset "utf-8";
@use "../../global/_" as g;
.e-bnr {
	position: relative;
	display: block;
	width: fit-content;
	margin: 0 auto;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 1;
		box-shadow: inset 0 0 0 1px g.$greenishBlue;
		mix-blend-mode: darken;
	}
	.__elem {
		max-width: 100%;
		transition: scale .3s ease;
	}
	
	&[target*="blank"] {
		&::before {
			left: auto;
			right: 25px;
			margin: 0;
			box-shadow: none;
			@include g.ico-circle-blank;
			@include g.centering-elements(false, true);
			transition: background .3s ease;

			@include g.smMax {
				right: 16px;
				width: 22px;
				height: 22px;
			}
		}
	}
	@include g.hover {
		.__elem {
			scale: 1.05;
		}

		&[target*="blank"] {
			&::before {
				@include g.ico-circle-blank(g.$baseColor, g.$baseColor, g.$white);
			}
		}
	}
}
