@charset "utf-8";
@use "../../global/_" as g;
.e-btn-stock {
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(2, auto);
	gap: 0 10px;
	grid-auto-flow: row;
	grid-template-areas:
	"ico txt1"
	"ico txt2";
	width: min(300px, 100%);
	height: 75px;
	margin: 0 auto;
	padding-left: 20px;
	background: g.$baseColor;
	color: g.$white;
	@include g.font-medium;
	transition: background .3s ease;

	@include g.smMax {
		width: 100%;
		height: 72px;
	}
	.__ico {
		grid-area: ico;
		align-self: center;
		width: 30px;
		height: 30px;
		fill: g.$white;
	}
	.__txt1 {
		grid-area: txt1;
		margin-left: 8px;
		font-size: g.$fz-l;

		@include g.smMin {
			align-self: flex-end;
		}
		@include g.smMax {
			align-self: flex-end;
			font-size: g.$fz-m;
		}
	}
	.__txt2 {
		grid-area: txt2;
		align-self: flex-start;
		font-size: g.$fz-s;

		@include g.smMax {
			font-size: g.$fz-xs;
		}
	}
	&[target*="blank"] {
		&::before {
			content: '';
			right: 8px;
			margin-left: auto;
			@include g.ico-circle-blank(transparent, g.$white, g.$white, 24px, 24px);
			@include g.centering-elements(false, true);

			@include g.smMax {
				right: 16px;
			}
		}
	}
	@include g.hover {
		background: g.$baseColorLight;
		color: g.$white;
	}

	&._l_ {
		@include g.smMin {
			display: flex;
			align-items: center;
			width: min(480px, 100%);
			height: 92px;
			padding-left: 40px;
			gap: 0;

			.__ico {
				width: 34px;
				height: 34px;
			}
			.__txt1 {
				align-self: center;
				margin-left: 32px;
			}
			.__txt2 {
				align-self: center;
			}
			&[target*="blank"] {
				&::before {
					right: 24px;
					@include g.ico-circle-blank(transparent, g.$white, g.$white, 34px, 34px);
				}
			}
		}
	}
}
