@charset "utf-8";
@use "../../../global/_" as g;
.c-tktkstories-business {
	&-head {
		display: grid;
		grid-template-columns: 45% 50%;
		grid-template-rows: auto 1fr;
		gap: 0 5%;
		grid-auto-flow: row;
		grid-template-areas:
		  "ttl img"
		  "txt img";

		@include g.smMin {
			margin-top: 56px;
		}
		@include g.smMax {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, auto);
			gap: 20px 0;
			grid-auto-flow: row;
			grid-template-areas:
			  "ttl"
			  "txt"
			  "img";
		}
		&__ttl {
			grid-area: ttl;
			margin-top: 32px;
			
			.__catedory {
				display: block;
				color: g.$subTextColor1;
				@include g.font-medium;
				@include g.l_height(14, 24);

				@include g.smMax {
					@include g.l_height(12, 20);
				}
			}
			.__ttl {
				display: block;
				@include g.font-bold;
				@include g.l_height(36, 70);

				@include g.smMax {
					@include g.l_height(25, 35);
				}
			}
		}
		&__txt {
			grid-area: txt;

			@include g.smMin {
				margin-top: 35px;
			}
		}
		&__img {
			grid-area: img;
			position: relative;

			@include g.smMax {
				margin: 0 15px;
			}
			.__elem { max-width: 100%; }
		}
	}

	&-lead {
		position: relative;
		margin-top: g.$m-xxl;
		padding: 56px 80px;
		background: url(/tktkstories/business/img/bg_lead.png) 0 0 no-repeat;
		background-size: cover;

		@include g.smMax {
			margin-top: 50px;
			padding: 20px;
		}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 80px;
			width: 40px;
			height: 3px;
			background: g.$baseColorLight;

			@include g.smMax {
				left: 40px;
			}
		}
		.__lead {
			@include g.font-bold;
			@include g.l_height(32, 56);

			@include g.smMax {
				@include g.l_height(20, 35);
			}
		}
		&__body {
			width: min(100%, 730px);
			margin: 55px 0 0 auto;

			@include g.smMax {
				margin-top: 24px;
			}
			& > *:not(:first-child) {
				margin-top: 24px;
			}
		}
	}

	&-content {
		margin: 120px 0 160px;
		counter-reset: number 0;

		@include g.smMax {
			margin: g.$m-l 0 g.$m-xxl;
		}
		&__col {
			&:not(:first-child) {
				margin-top: g.$m-xxl;

				@include g.smMax {
					margin-top: g.$m-l;
				}
			}
			@include g.smMin {
				display: grid;
				grid-template-rows: auto auto 1fr auto;
				gap: 0 5%;
				grid-auto-flow: row;

				&:nth-child(odd) {
					grid-template-columns: 50% 45%;
					grid-template-areas:
					"solition img"
					"ttl img"
					"txt img"
					"case case";
				}
				&:nth-child(even) {
					grid-template-columns: 45% 50%;
					grid-template-areas:
					"img solition"
					"img ttl"
					"img txt"
					"case case";
				}
			}
		}

		&__solution {
			grid-area: solition;
			display: flex;
			align-items: flex-end;
			color: g.$linkTextColor;
			@include g.font-en;
			@include g.l_height(20, 24);

			&::before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 20px;
				margin: 0 10px;
				background: g.$lineColor;
				rotate: 25deg;
				translate: 0, -2px;
			}
			&::after {
				order: -1;
				counter-increment: number 1;
				content: '0' counter(number);
				color: g.$strongTextColor;
				@include g.l_height(32, 39);
			}
		}
		&__ttl {
			grid-area: ttl;
			margin-top: 16px;
			@include g.font-bold;
			@include g.l_height(32, 56);

			@include g.smMin {
				padding-left: 24px;
			}
			@include g.smMax {
				@include g.l_height(20, 35);
			}
		}
		&__txt {
			grid-area: txt;
			margin-top: 24px;

			@include g.smMin {
				padding-left: 24px;
			}
		}
		&__img {
			grid-area: img;

			@include g.smMax {
				margin-top: 24px;
			}
			.__elem { width: 100%; }
		}
		&__case {
			grid-area: case;
			margin-top: g.$m-l;
		}

	}

}