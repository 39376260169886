@charset "utf-8";
@use "../../global/_" as g;
.m-table {
	width: 100%;
	
	th,
	td {
		padding: 24px 32px;
		background: g.$white;
		border: 1px solid g.$lineColor;
		text-align: left;
		vertical-align: middle;

		@include g.smMax {
			padding: 20px;
		}
		&:first-child {
			border-left: none;
		}
		&:last-child {
			border-right: none;
		}
		&._al-l_ { text-align:   left; }
		&._al-r_ { text-align:  right; }
		&._al-c_ { text-align: center; }
		&._nowrap_ { white-space: nowrap; }
	}
	th {
		background: g.$bgColorGray;
	}
	&._px-s_ {
		th,
		td {
			padding: 24px 16px;
		}
	}
	&._p-s_ {
		th,
		td {
			padding: 12px 16px;
		}

		// 事業所案内　本社用（/company/office/headquarters/）
		.__indent {
			position: relative;
			padding-left: 48px;
			background-clip: padding-box;
			
			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 32px;
			}
			&::before {
				top: -2px;
				background: g.$bgColorGray;
			}
			&::after {
				top: -1px;
				border-right: 1px solid g.$lineColor;
			}
		}
	}
	th._slash_,
	td._slash_ {
		padding-top: 8px !important;
		padding-bottom: 8px !important;
		background-image: linear-gradient(to right top, transparent calc(50% - 0.5px), g.$lineColor 50%, g.$lineColor calc(50% + 0.5px), transparent calc(50% + 1px));
		line-height: 1.2;

		& > * {
		}
		& > *:not(:first-child) {
			margin-top: 8px;
		}
	}

	@include g.smMax {
		&-wrap {
			padding-bottom: 18px;
			overflow-x: auto;

			&::-webkit-scrollbar {
				width: 2px;
				height: 2px;
			}
			&::-webkit-scrollbar-track {
				background: g.$greenishBlue-600;
			}
			&::-webkit-scrollbar-thumb {
				background: g.$baseColor;
			}
		}
		&-wrap & {
			table-layout: fixed;
		}
	}
}
