@charset "utf-8";
@use "../../global/_" as g;
.c-snav {
	&-head {
		&__link {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 80px;
			background: g.$baseColor;
			color: g.$white;
			font-size: g.$fz-l;
			text-align: center;
			text-decoration: none;
		}
	}
	&-list {
		&__item {
			border: 1px solid g.$lineColor;
			border-top: none;
		}
		&__link {
			display: flex;
			align-items: center;
			padding: 15px;
			text-decoration: none;
			
			.__txt {
				flex: 1 1 auto;
			}
			.__ico {
				flex: 0 0 auto;
				margin: 0 0 0 10px;
				font-size: g.$fz-xxs;
			}
			@include g.hover {
				opacity: 1;
				background: rgba(g.$baseColor, .05);
			}
		}
	}	
}
