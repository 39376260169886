@charset "utf-8";
@use "../../global/_" as g;
.m-numbering {
	counter-reset: number 0;

	&__content {
		margin-top: 56px;

		@include g.smMax {
			margin-top: 45px;
		}
	}
	&__ttl {
		display: flex;
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid g.$lineColor;
		@include g.font-bold;
		@include g.l_height(20, 35);

		@include g.smMax {
			@include g.l_height(16, 28);
		}
		&::before {
			flex: 0 0 auto;
			counter-increment: number;
			content: counter(number);
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 14px;
			background: g.$baseColor;
			color: g.$white;
			@include g.circle(38);
			@include g.font-en;
			@include g.l_height(16, 16);

			@include g.smMax {
				width: 30px;
				height: 30px;
				margin-right: 10px;
				@include g.l_height(14, 14);
			}
		}
	}
}
