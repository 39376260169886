@charset "utf-8";
@use "../../global/_" as g;
.e-ttl02 {
	margin-top: 56px;
	@include g.font-bold;
	@include g.l_height(36, 50);

	@include g.smMax {
		margin-top: 28px;
		@include g.l_height(25, 35);
	}
	.__sub {
		display: block;
		color: g.$subTextColor1;
		@include g.font-medium;
		@include g.l_height(14, 28);

		@include g.smMax {
			@include g.l_height(12, 24);
		}
	}

	//下線つきスタイル
	&._line_ {
		padding-bottom: 35px;
		border-bottom: 1px solid g.$lineColor;

		@include g.smMax {
			padding-bottom: 18px;
		}
	}
}
