@charset "utf-8";
@use "../../../global/_" as g;
.c-csr-pcb {

	&-list {
		&__item {
			display: flex;

			&:not(:first-child) {
				margin-top: 18px;
			}
			&::before {
				content: '';
				position: relative;
				top: 4px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 18px;
				height: 18px;
				margin-right: 24px;
				border-radius: 2px;
			}
			&._rank1_::before { background: #ff4f55; }
			&._rank2_::before { background: #ff8073; }
			&._rank3_::before { background: #aaaaaa; }
		}
	}


}
