@charset "utf-8";
@use "../../global/_" as g;
.m-menu-list02 {
	@include g.smMin {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0 0;
	}
	&__item {
	}
	&__link {
		position: relative;
		display: block;
	}
	@include g.media-hover {
		&__link:hover &__img {
			&::before {
				opacity: .8;
			}
			.__elem {
				scale: 1.1;
			}
		}
		&__link:hover &__txt {
			&::after {
				right: 30px;
				@include g.ico-circle-arrow-right(g.$baseColor, g.$baseColor, g.$white, 54px, 54px);
				@include g.centering-elements(false, true);
			}
		}
	}
	&__img {
		display: block;
		@include g.object-fit(4, 3);

		@include g.smMax {
			aspect-ratio: 16 / 9;
		}
		&::before {
			content: '';
			position: absolute;
			top: 60px;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: linear-gradient(0deg, rgba(g.$black, 1) 0%, rgba(g.$black, 0) 100%);
			opacity: .55;
			transition: opacity .3s ease;
		}
	}
	&__txt {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 5;
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		padding: 30px;
		color: g.$white;
		transition: color .3s ease;
		@include g.font-bold;
		@include g.l_height(28, 30);

		@include g.smBtMd {
			padding: 5%;
		}
		@include g.smMax {
			padding: 20px 25px;
			@include g.l_height(23, 30);
		}
		&::before {
			content: attr(data-txt);
			margin-top: 5px;
			@include g.font-en;
			@include g.l_height(14, 18);

			@include g.smMax {
				font-size: 1.5rem;
			}
		}
		&::after {
			right: 30px;
			background-position: center center;
			@include g.ico-circle-arrow-right(transparent, g.$white, g.$white, min(54px, 13.5%), 54px);
			@include g.centering-elements(false, true);
			transition: background .3s ease;

			@include g.smBtMd {
				right: 5%;
			}
			@include g.smMax {
				right: 20px;
				width: 40px;
				height: 40px;
			}
		}
	}

	@include g.smMin {
		&__link._s_ &__txt {
			padding: 25px 32px;
			@include g.l_height(18, 31);

			&::before {
				content: none;
			}
			&::after {
				width: 28px;
				height: 28px;
			}
		}
		@include g.media-hover {
			&__link._s_:hover &__txt {
				&:after {
					width: 28px;
					height: 28px;
				}
			}
		}
	}
}
