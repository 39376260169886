@charset "utf-8";
@use "../../global/_" as g;
.e-video {
	@include g.object-fit(16, 9);

	&__btn {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background: rgba(g.$black, .36);
		opacity: 1;
		visibility: visible;
		cursor: pointer;
		transition: background .3s ease, opacity .3s ease, visibility .3s ease;

		.__hidden {
			@include g.hidden;
		}
		.__ico {
			width: 74px;
			height: 64px;
			fill: g.$white;
		}
		@include g.hover {
			&:not(._play_) {
				background: rgba(g.$black, .15);
			}
		}
		&._play_ {
			opacity: 0;
			visibility: hidden;
		}
	}
}
