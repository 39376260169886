@charset "utf-8";
@use "../../global/_" as g;
.c-anchor-link {
	display: flex;
	flex-wrap: wrap;
	margin: 32px 0 16px -32px;
	
	&__item {
		margin-bottom: 16px;
		padding: 0 32px;
		border-right: 1px solid g.$lineColor;
		@include g.l_height(14, 24);

		.__link {
			display: flex;
			align-items: center;
			color: g.$textColor;
			@include g.font-medium;
			transition: color .3s ease;

			&::after {
				flex: 0 0 auto;
				margin-left: 16px;
				color: g.$baseColor;
				@include g.font-icon('\f107');
				@include g.l_height(16, 16);
				transition: color .3s ease;
			}
			.e-company-office-logo {
				width: 25px;
				height: 16px;
				margin: 0 8px 0 0;
				vertical-align: middle;
			}
			.e-company-office-number {
				color: g.$linkTextColor;
				transition: color .3s ease;
			}
			@include g.hover {
				color: g.$strongTextColor;

				&::after {
					color: g.$strongTextColor;
				}
				.e-company-office-number {
					color: g.$strongTextColor;
				}
			}

		}
	}
}