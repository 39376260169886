@charset "utf-8";
@use "../../global/_" as g;
.l-gnav {
	grid-area: gnav;

	@include g.mdMin {
		display: flex;
	}
	&__item {
		flex: 1 1 auto;

		@include g.mdMax {
			border-bottom: 1px solid g.$lineColor;

			&._visible_ {
				background: #f8f8f8;
			}
		}
	}
	&__item._visible_ &__link {
		.__elem {
			color: g.$baseColor;

			@include g.mdMax {
				@include g.font-bold;
			}

			&::after {
				rotate: -90deg;
				opacity: 0;
			}
		}
	}

	&__link {
		width: 100%;
		background: transparent;
		color: g.$black;
		@include g.font-medium;
		transition: color 0.3s ease;
		cursor: pointer;

		@include g.mdMin {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
		}
		.__elem {
			position: relative;
			padding-right: 18px;

			@include g.mdMax {
				display: flex;
				align-items: center;
				height: 70px;
				padding: 0 15px;
			}
			&::before,
			&::after {
				content: "";
				display: block;
				background: g.$greenishBlue-900;
				@include g.centering-elements(false, true);
			}
			&::before {
				right: 0;
				width: 10px;
				height: 2px;
				transition: background 0.3s ease;

				@include g.mdMax {
					right: 15px;
				}
			}
			&::after {
				right: 4px;
				width: 2px;
				height: 10px;
				transition:
					background 0.3s ease,
					rotate 0.4s ease,
					opacity 0.4s ease;

				@include g.mdMax {
					right: 19px;
				}
			}
		}
		@include g.hover {
			color: g.$baseColor;
		}

		//カレント表示
		body.tktkstories &._tktkstories_,
		body.company &._company_,
		body.product &._product_,
		body.research &._research_,
		body.ir &._ir_,
		body.csr &._csr_,
		body.recruit &._recruit_ {
			color: g.$linkTextColor;
		}
	}
}

.l-gnav-special-txt {
	margin: 40px 0 10px;

	&__title {
		font-size: 1.8rem;
		font-weight: 700;
		padding-left: 10px;
		margin-bottom: 15px;
		border-left: 4px solid g.$white;
		@include g.mdMax {
			display: none;
		}
	}

	&__sub-title {
		font-weight: 700;
	}

	@include g.mdMin {
		color: g.$white;
	}

	@include g.mdMax {
		margin: 15px 0 10px;
	}
}

.l-gnav-special-btn {
	display: block;
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.4;
	padding: 10px 30px 10px 20px;
	margin: 10px 20px 0 0;
	border-radius: 100vh;
	position: relative;
	background: #fff;

	> span {
		font-weight: 700;
	}

	&::before {
		margin: 0;
		content: "\e902";
		font-weight: normal;
		font-size: 1.5rem;
		font-family: "icomoon";
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
	}

	@include g.mdMax {
		padding: 10px 30px 10px 20px;
		margin: 10px 0;
		color: #fff;
		background: g.$baseColor;
		transition: 0.3s;

		&:hover {
			color: #fff;
			background: g.$baseColorLight;
		}
	}
}
