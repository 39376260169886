@charset "utf-8";
@use "../../global/_" as g;
.c-breadcrumb {
	background: g.$greenishBlue-100;
	padding: 10px 0;
	font-size: g.$fz-xxs;
	line-height: 1.4;

	@include g.smMax {
		font-size: g.$fz-xxxs;
	}
	@include g.print {
		display: none;
	}
	&__item {
		display: inline-block;

		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				@include g.font-icon('\f105');
			}
		}
	}
	&__link {
		text-decoration: underline 1px solid transparent;
		transition: text-decoration .3s ease;

		@include g.hover {
			text-decoration-color: g.$strongTextColor;
		}
	}
}
