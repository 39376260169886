@charset "utf-8";
@use "../../../global/_" as g;
.e-form-textarea {
	display: inline-block;
	width: 100%;
	padding: 10px 18px;
	background: g.$white;
	border: 1px solid g.$lineColor;
	color: g.$textColor;

	&:focus {
		outline: auto;
		outline-color: g.$baseColorLight;
	}
	&._error_ {
		border-color: g.$secondaryColorDark;
	}
}
