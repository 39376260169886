@charset "utf-8";
@use "../../../global/_" as g;
.u-al-c {
	text-align: center !important;
	margin-right: 0 !important;
	margin-left: 0 !important;

	@include g.smMax {
		&._sm-al-l_ {
			text-align: left !important;
		}
		&._sm-al-c_ {
			text-align: center !important;
		}
		&._sm-al-r_ {
			text-align: right !important;
		}
	}
}
