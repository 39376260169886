@charset "utf-8";
@use "../../global/_" as g;
.t-wrap {
	color: g.$textColor;
	@include g.l_height(16, 28);

	@include g.smMax {
		@include g.l_height(15, 24);
	}
}
