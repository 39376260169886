@charset "utf-8";
@use "../../global/_" as g;
.l-bg-blue {
	position: relative;
	width: calc(100vw - var(--scrollbar));
	margin: 0 calc(50% - ((100vw - var(--scrollbar)) / 2));
	padding: 80px calc(((100vw - var(--scrollbar)) / 2) - 50%);
	background: g.$bgColorBlue;

	@include g.print {
		width: 100%;
		margin: 0 auto;
		padding: 80px 30px;
	}
	@include g.smMax {
		width: 100vw;
		margin: 0 calc(50% - 50vw);
		padding: 56px calc(50vw - 50%);
	}
	&__inner {
		@include g.mdMin {
			width: min(71.6%, 860px);
		}
	}

	&._pt-0_ {
		padding-top: 0;
	}
	&._py-m_ {
		padding-top: g.$m-m;
		padding-bottom: g.$m-m;
	}
}
