@charset "utf-8";
@use "../../global/_" as g;
.l-hnav {
	position: relative;
	grid-area: lnav;
	display: flex;

	@include g.mdMin {
		justify-content: flex-end;
		align-items: center;
	}
	@include g.mdMax {
		order: 1;
		flex-wrap: wrap;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			border-bottom: 1px solid g.$lineColor;
		}
	}
	&__item {
		@include g.mdMin {
			margin-left: 25px;
		}
		@include g.mdMax {
			width: 50%;
			border-bottom: 1px solid g.$lineColor;

			&:has(._report_),
			&:has(._quickcharger_) {
				width: 100%;
			}

			&:nth-child(2n) {
				border-right: 1px solid g.$lineColor;
			}
		}
	}
	@include g.mdMin {
		&__item._jp_,
		&__item._en_ {
			.__ico {
				display: none;
			}
			.__elem {
				@include g.hidden;
			}
		}
		&__item._jp_ &__link,
		&__item._en_ &__link {
			&::before {
				content: attr(data-txt);
			}
		}
	}
	&__link {
		display: flex;
		align-items: center;
		@include g.l_height(14, 20);
		@include g.font-medium;

		@include g.mdMax {
			height: 70px;
			padding: 0 15px;
			color: g.$black;

			&::after {
				margin-left: auto;
				color: g.$greenishBlue-900;
				font-size: g.$fz-m;
				@include g.font-icon("\f105");
			}
		}

		@include g.mdMin {
			&._report_ {
				background: g.$baseColor;
				color: g.$white;
				transition:
					background 0.3s ease,
					color 0.3s ease;
				padding: 4px 24px 4px 16px;
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				min-width: 120px;
				height: 28px;

				&::before {
					position: absolute;
					top: 50%;
					right: 8px;
					translate: 0 -50%;
					content: "\f105";
					color: g.$white;
					font-weight: normal;
					font-family: "icomoon";
					transition: color 0.3s ease;
				}

				@include g.hover {
					background: g.$baseColorLight;
					color: g.$white;
				}
			}

			&._quickcharger_ {
				background: g.$baseColor;
				color: g.$white;
				transition:
					background 0.3s ease,
					color 0.3s ease;
				padding: 4px 32px 4px 16px;
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				min-width: 130px;
				min-height: 28px;

				&::before {
					position: absolute;
					top: 50%;
					right: 8px;
					translate: 0 -50%;
					content: "\e902";
					color: g.$white;
					font-weight: normal;
					font-family: "icomoon";
					transition: color 0.3s ease;
				}

				@include g.hover {
					background: g.$baseColorLight;
					color: g.$white;
				}
			}
		}

		@include g.hover {
			color: g.$baseColor;

			.__elem {
				&::after {
					scale: 1 1;
				}
			}
		}
		&._current_ {
			@include g.smMax {
				background: g.$baseColor;
				color: g.$white;

				&::after {
					content: none;
				}
			}
			.__elem {
				&::after {
					scale: 1 1;
				}
			}
		}
		.__ico {
			margin-right: 5px;
			font-size: 1.5rem;

			@include g.mdMax {
				margin-right: 8px;
				color: g.$baseColor;
			}
		}
		.__elem {
			@include g.smMin {
				position: relative;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					bottom: -2px;
					height: 1px;
					background: g.$baseColor;
					scale: 0 1;
					transition: scale 0.3s ease;
				}
			}
			@include g.smMax {
				@include g.font-medium;
			}
		}
	}
}
