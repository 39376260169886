@charset "utf-8";
@use "../../global/_" as g;
.c-tab {
	&-nav {
		display: flex;
		margin-bottom: 40px;

		&__item {
			flex: 1 1 auto;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			background: g.$white;
			border: 1px solid g.$baseColor;
			color: g.$baseColor;
			@include g.font-bold;
			transition: background .3s ease, color .3s ease;
			cursor: pointer;

			@include g.smMax {
				min-height: 60px;
				padding: 10px;
				@include g.l_height(13, 19);
			}

			&::before {
				content: '';
				bottom: -14px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 12px 0;
				border-color: g.$baseColor transparent transparent;
				line-height: 0;
				opacity: 0;
				visibility: hidden;
				@include g.centering-elements(true, false);
				transition: opacity .15s ease, visibility .15s ease;

				@include g.smMax {
					bottom: -10px;
					border-width: 10px 8px 0;
				}
			}
			&:not(:first-child) {
				border-left: none;
			}
			@include g.hover {
				background: g.$baseColor;
				color: g.$white;
			}
			&._active_ {
				background: g.$baseColor;
				color: g.$white;

				&::before {
					opacity: 1;
					visibility: visible;
				}
			}

			// カラムオプション 3カラム
			&:nth-child(1):nth-last-child(3),
			&:nth-child(1):nth-last-child(3) ~ & {
				width: calc(100% / 3);
			}
			// カラムオプション 4カラム
			&:nth-child(1):nth-last-child(4),
			&:nth-child(1):nth-last-child(4) ~ & {
				width: calc(100% / 4);
			}
			// カラムオプション 5カラム
			&:nth-child(1):nth-last-child(5),
			&:nth-child(1):nth-last-child(5) ~ & {
				width: calc(100% / 5);
			}
		}
	}
	&__pane{
		&._active_ {
			animation: display_anim .75s forwards;
		}
	}
}
