@charset "utf-8";
@use "../../global/_" as g;
.u-order0  { order:  0; }
.u-order1  { order:  1; }
.u-order2  { order:  2; }
.u-order3  { order:  3; }
.u-order4  { order:  4; }
.u-order5  { order:  5; }
.u-order6  { order:  6; }
.u-order7  { order:  7; }
.u-order8  { order:  8; }
.u-order9  { order:  9; }
.u-order10 { order: 10; }
.u-order-m1 { order: -1; }

@include g.smMax {
	.u-sm-order0  { order:  0; }
	.u-sm-order1  { order:  1; }
	.u-sm-order2  { order:  2; }
	.u-sm-order3  { order:  3; }
	.u-sm-order4  { order:  4; }
	.u-sm-order5  { order:  5; }
	.u-sm-order6  { order:  6; }
	.u-sm-order7  { order:  7; }
	.u-sm-order8  { order:  8; }
	.u-sm-order9  { order:  9; }
	.u-sm-order10 { order: 10; }
	.u-sm-order-m1 { order: -1; }
}
