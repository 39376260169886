@charset "utf-8";
@use "../../global/_" as g;
.l-dropdown {
	@include g.mdMin {
		position: fixed;
		top: 110px;
		bottom: 0;
		left: 0;
		width: 100%;
		background: rgba(g.$black, 0.7);
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 0.3s ease,
			visibility 0.3s ease;

		.l-header-search-block._visible_ ~ .l-header-inner & {
			top: 249px;
		}
	}
	@include g.mdMax {
		display: none;
	}
	.l-gnav__item._visible_ & {
		@include g.mdMin {
			opacity: 1;
			visibility: visible;
		}
		@include g.mdMax {
			max-height: 100vh;
		}
	}
	&__body {
		@include g.mdMin {
			position: relative;
			background: g.$baseColor;
			translate: 0 -5px;
			transition: translate 0.3s ease;

			.l-gnav__item._visible_ & {
				translate: 0 0;
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 50%;
				z-index: -1;
				background: #f8f8f8;
			}
		}
	}
	&__inner {
		position: relative;
		display: flex;

		@include g.mdMax {
			width: calc(100% - 30px);
		}
	}
	&__content1 {
		@include g.lgMin {
			display: flex;
		}
		@include g.mdMin {
			flex: 0 0 46.7%;
			padding: 30px 0;
			background: g.$baseColor;
		}
		@include g.mdMax {
			width: 100%;
			border-top: 1px solid #d4d7dc;
		}
		&__ttl {
			@include g.lgMin {
				flex: 0 0 43.7%;
			}
			@include g.mdMax {
				display: none;
			}
			.__link {
				position: relative;
				display: inline-flex;
				align-items: center;
				color: g.$white;
				@include g.font-bold;
				@include g.l_height(24, 42);

				@include g.hover {
					color: g.$baseColorLight;
				}
				&::after {
					margin-left: 15px;
					font-size: 2.7rem;
					@include g.font-icon("\e90d");
				}
				&[target*="blank"] {
					&::before {
						content: none;
					}
					&::after {
						@include g.font-icon("\e911");
					}
				}
			}
		}
		&__list {
			@include g.lgMin {
				flex: 0 0 56.3%;
				padding-left: 50px;
				border-left: 1px solid g.$lineColor;
			}
			@include g.mdBtLg {
				margin-top: 25px;
			}
			@include g.mdMax {
				padding: 10px 0;
			}
		}
		&__item {
			@include g.mdMin {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
			.__link {
				position: relative;
				padding-left: 16px;
				color: g.$white;
				font-size: g.$fz-s;

				@include g.mdMax {
					display: flex;
					align-items: center;
					padding: 10px 0;
					color: g.$textColor;
					@include g.font-medium;
				}
				@include g.hover {
					color: g.$baseColorLight;

					&::before {
						color: g.$baseColorLight !important;
					}
				}
				&::after {
					margin-right: 10px;
					@include g.font-icon("\f105");

					@include g.mdMin {
						position: absolute;
						top: -1px;
						left: 0;
					}
					@include g.mdMax {
						margin: 0 5px 0 auto;
						color: g.$greenishBlue-900;
						order: 1;
					}
				}

				//外部リンク
				&[target*="blank"],
				&[href$=".pdf"] {
					&::before {
						transition: color 0.3s ease;

						@include g.mdMin {
							margin-right: 5px;
							color: g.$white;
						}
						@include g.mdMax {
							color: g.$baseColor;
							font-size: 1.1rem;
						}
					}
				}
				&[href$=".pdf"] {
					&::before {
						@include g.mdMax {
							color: g.$secondaryColor;
							font-size: g.$fz-xxs;
						}
					}
				}
			}
		}
	}
	&__content2 {
		flex: 0 0 53.3%;
		padding: 22px 0 30px 40px;
		background: #f8f8f8;

		@include g.mdMax {
			display: none;
		}
		&__ttl {
			margin-bottom: 12px;
			color: #ef7f0f;
			@include g.font-en-regular;
			@include g.l_height(16, 19);
		}
		.c-grid._col2_ {
			grid-gap: 32px 32px;
		}
	}
	&-panel {
		display: block;
		display: flex;
		flex-wrap: wrap;
		line-height: 1.65;

		@include g.media-hover {
			&:hover &__thumb {
				.__elem {
					scale: 1.1;
				}
				&::after {
					@include g.line_animation;
				}
			}
			&:hover &__category {
				color: g.$baseColor;
			}
			&:hover &__ttl {
				color: g.$baseColorLight;

				&::before {
					color: g.$baseColorLight;
				}
			}
		}
		&__thumb {
			flex: 0 0 100%;
			@include g.object-fit;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				background: g.$baseColorLight;
			}
		}
		&__category {
			flex: 0 0 auto;
			margin-top: 10px;
			padding: 6px 12px;
			border: 1px solid g.$baseColor;
			color: g.$baseColor;
			font-size: g.$fz-s;
			line-height: 1;
		}
		&__new {
			flex: 0 0 auto;
			align-self: center;
			margin: 10px 0 0 10px;
			color: #ef7f0f;
			font-size: 1.5rem;
			@include g.font-en-regular;
		}
		&__ttl {
			flex: 0 0 100%;
			margin-top: 10px;
			color: g.$textColor;
			font-size: 1.5rem;
			@include g.font-medium;
			transition: color 0.3s ease;

			&::before {
				margin-right: 10px;
				color: g.$baseColor;
				@include g.font-icon("\f105");
				transition: color 0.3s ease;
			}
		}
		&__txt {
			flex: 0 0 100%;
			margin-top: 5px;
			color: g.$textColor;
			font-size: g.$fz-s;
		}

		//外部リンク
		&[target*="blank"] {
			&::before {
				content: none;
			}
		}
		&[target*="blank"] &__ttl {
			&::before {
				@include g.font-icon("\e902");
				font-size: 1.1rem;
			}
		}
	}
	&-panel02 {
		display: flex;
		gap: 0 20px;
		flex-wrap: wrap;
		line-height: 1.65;

		@include g.media-hover {
			&:hover &__thumb {
				.__elem {
					scale: 1.1;
				}
				&::after {
					@include g.line_animation;
				}
			}
			&:hover &__category {
				color: g.$baseColor;
			}
			&:hover &__ttl {
				color: g.$baseColorLight;

				&::before {
					color: g.$baseColorLight;
				}
			}
		}

		&__inner {
			flex: 1;
		}

		&__thumb {
			flex: 1;
			width: 100%;
			height: 100%;
			@include g.object-fit;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				background: g.$baseColorLight;
			}
		}
		&__category {
			flex: 0 0 auto;
			margin-top: 10px;
			padding: 6px 12px;
			border: 1px solid g.$baseColor;
			color: g.$baseColor;
			font-size: g.$fz-s;
			line-height: 1;
		}
		&__new {
			flex: 0 0 auto;
			align-self: center;
			margin: 10px 0 0 10px;
			color: #ef7f0f;
			font-size: 1.5rem;
			@include g.font-en-regular;
		}
		&__ttl {
			flex: 0 0 100%;
			color: g.$textColor;
			font-size: 1.5rem;
			@include g.font-medium;
			transition: color 0.3s ease;

			&::before {
				margin-right: 10px;
				color: g.$baseColor;
				@include g.font-icon("\f105");
				transition: color 0.3s ease;
			}
		}
		&__txt {
			flex: 0 0 100%;
			margin-top: 5px;
			color: g.$textColor;
			font-size: g.$fz-s;
		}

		//外部リンク
		&[target*="blank"] {
			&::before {
				content: none;
			}
		}
		&[target*="blank"] &__ttl {
			&::before {
				@include g.font-icon("\e902");
				font-size: 1.1rem;
			}
		}
	}
	&__close {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 44px;
		height: 44px;
		background: g.$baseColor;
		color: g.$white;
		transition: background 0.3s ease;
		cursor: pointer;

		@include g.mdMax {
			display: none;
		}
		@include g.hover {
			background: g.$baseColorLight;
		}
	}

	// 採用情報
	&._recruit_ &__content1 {
		@include g.mdMin {
			flex: 0 0 20%;
			display: block;
		}
	}
	&._recruit_ &__content1__list {
		@include g.mdMin {
			display: none;
		}
	}
	&._recruit_ &__content2 {
		flex: 0 0 80%;
	}

	&-contact {
		border: 1px solid #d1d8e1;
		margin-top: 30px;
		padding: 24px 40px;
		font-size: 1.4rem;

		&._sp_ {
			@include g.mdMin {
				display: none;
			}
		}

		&__ttl {
			font-size: 2rem;
			margin-bottom: 0.5em;
			font-weight: 700;
			color: g.$baseColor;
		}

		&__email-ttl {
			font-size: 1.6rem;
			font-weight: 700;
			margin-bottom: 10px;
			color: g.$baseColor;
		}

		&__inner {
			display: flex;
		}

		&__item {
			flex: 1;

			&:not(:last-child) {
				border-right: 1px solid #d1d8e1;
				margin-right: 40px;
				padding-right: 40px;
			}
		}

		@include g.mdMax {
			margin: 10px 0 20px;
			padding: 20px;

			&__ttl {
				font-size: 1.8rem;
			}

			&__email-ttl {
				font-size: 1.5rem;
				margin-bottom: 10px;
			}

			&__inner {
				display: block;
			}

			&__item {
				&:not(:last-child) {
					border-right: none;
					border-bottom: 1px solid #d1d8e1;
					margin: 0 0 20px;
					padding: 0 0 20px;
				}
			}
		}
	}
}
