@charset "utf-8";
@use "../../global/_" as g;
.l-header {
	position: sticky;
	top: 0;
	left: 0;
	z-index: 20;
	background: g.$white;

	@include g.print {
		display: none;
	}
	&-inner {
		align-items: center;

		@include g.mdMin {
			display: grid;
			grid-template-columns: 150px 1fr auto auto;
			grid-template-rows: auto auto;
			gap: 0 0;
			grid-auto-flow: row;
			grid-template-areas:
			"logo lnav language search"
			"gnav gnav gnav gnav";
			padding-top: 15px;

			&._en_ {
				grid-template-columns: 267px 1fr auto auto;
			}
		}
		@include g.mdMax {
			display: grid;
			grid-template-columns: 115px auto 25px;
			grid-template-rows: auto;
			gap: 0 20px;
			grid-auto-flow: row;
			grid-template-areas: "logo search menu";
			width: 100% !important;
			height: 55px;
			margin: 0;
			padding: 0 10px;
			background: g.$white;
			border-bottom: 1px solid transparent;
			transition: border .3s ease;

			&._en_ {
				grid-template-columns: 218px auto 25px;
			}
			._gnav-show_ & {
				border-bottom-color: g.$lineColor;
			}
		}
	}
	&-logo {
		grid-area: logo;

		&__link {
			display: block;
			line-height: 0;

			.__elem {
				width: 100%;
				height: 27px;

				@include g.mdMax {
					height: 21px;
				}
			}
		}
	}
	&-menu {
		grid-area: menu;
		position: relative;
		width: 25px;
		height: 22px;
		cursor: pointer;

		@include g.mdMin {
			display: none;
		}
		&__item {
			position: absolute;
			left: 0;
			display: block;
			width: 25px;
			height: 2px;
			background: g.$baseColor;
			transition: rotate .3s ease, translate .3s ease, opacity .3s ease;

			&:nth-child(1) { top: 0; }
			&:nth-child(2) { top: 10px; }
			&:nth-child(3) { bottom: 0; }
		}
		&._active_ &__item {
			&:nth-child(1) {
				rotate: -45deg;
				translate: 0 10px;
			}
			&:nth-child(2) { opacity: 0; }
			&:nth-child(3) {
				rotate: 45deg;
				translate: 0 -10px;
			}
		}
	}
	&-nav {
		@include g.mdMin {
			display: contents;
		}
		@include g.mdMax {
			position: fixed;
			top: 55px;
			top: 0;
			left: 0;
			z-index: -1;
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100vh;
			padding: 55px 0 40px;
			background: g.$white;
			overflow-y: auto;
			overscroll-behavior-y: contain;
			opacity: 0;
			visibility: hidden;
			translate: 0 -10px;
			transition: opacity .3s ease, visibility .3s ease, translate .4s ease;

			._gnav-show_ & {
				opacity: 1;
				visibility: visible;
				translate: 0 0;
			}
		}
		&-language {
			grid-area: language;
			position: relative;
			display: flex;
			align-items: center;
			border-left: 1px solid #d1d4d8;
			margin-left: 24px;
			padding-left: 24px;
			font-size: g.$fz-xs;
			line-height: 20px;

			@include g.mdMax {
				display: none;
			}
			&__link {
				padding-bottom: 2px;
				color: #a4b5cc;
				@include g.font-medium;

				&._current_ {
					pointer-events: none;
					color: g.$baseColor;
				}
				&:not(:first-child) {
					&::before {
						content: '/';
						position: relative;
						top: -1px;
						display: inline-block;
						margin: 0 3px;
					}
				}
				&::after {
					content: attr(data-txt);
					transition: color .3s ease;
				}
				.__elem {
					@include g.hidden;
				}
			}
			&__underline {
				position: absolute;
				left: 25px;
				bottom: 0;
				width: 15px;
				height: 1px;
				background: g.$baseColor;
				transition: left .3s ease;
				z-index: -1;
			}

			&._en_ &__underline {
				left: 50px;
			}
			@include g.media-hover {
				&__link:hover {
					color: #a4b5cc;
					
					&::after {
						color: g.$baseColor;
					}
				}
				&__link:nth-child(n+2):hover ~ &__underline {
					left: 50px;
				}
				&._en_ &__link:nth-child(n+1):hover ~ &__underline {
					left: 25px;
				}
			 }
		}

	}

	&-search {
		grid-area: search;
		display: flex;
		align-items: center;
		justify-content: center;
		@include g.circle(35);
		margin-left: 24px;
		background: g.$baseColor;
		color: g.$white;
		font-size: g.$fz-xxs;
		cursor: pointer;
		transition: background .3s ease, color .3s ease;

		@include g.mdMax {
			width: 30px;
			height: 30px;
			margin-left: auto;

			&._active_ {
				background: transparent;
				color: g.$baseColor;

				.__ico {
					font-size: g.$fz-xl;
					&::before {
						content: '\e90b';
					}
				}
			}
		}
		@include g.hover {
			background: g.$baseColorLight;
		}
	}

	&-search-block {
		width: 100%;
		background: g.$gray-100;

		@include g.mdMin {
			display: none;
		}
		@include g.mdMax {
			position: fixed;
			top: 55px;
			left: 0;
			opacity: 0;
			visibility: hidden;
			translate: 0 -5px;
			transition: opacity .3s ease, visibility .3s ease, translate .3s ease;

			&._visible_ {
				opacity: 1;
				visibility: visible;
				translate: 0 0;
			}
		}
	}

}
