@charset "utf-8";
@use "../../global/_" as g;
.m-panel-research {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(4, auto);
	gap: 16px 0;
	grid-auto-flow: row;
	grid-template-areas:
		"thumb thumb"
		"label date"
		"ttl ttl"
		"txt txt";

	@include g.media-hover {
		&:hover &__thumb {
			.__elem {
				scale: 1.075;
			}
			&::after {
				@include g.line_animation;
			}
		}
		&:hover &__ttl {
			color: g.$strongTextColor;

			&::before {
				color: g.$strongTextColor;
			}
		}
	}

	&__thumb {
		grid-area: thumb;
		@include g.object-fit(1, 1);

		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			height: 3px;
			background: g.$baseColorLight;
		}
	}
	&__label {
		grid-area: label;
		white-space: nowrap;
	}
	&__date {
		grid-area: date;
		color: g.$subTextColor1;
		font-size:  g.$fz-s;
		text-align: right;
	}
	&__ttl {
		grid-area: ttl;
		position: relative;
		padding: 15px 0 0 14px;
		border-top: 1px solid g.$lineColor;
		color: g.$textColor;
		@include g.font-bold;
		@include g.l_height(15, 24);
		overflow: hidden; 
		transition: color .3s ease;

		&::before {
			position: absolute;
			top: 20px;
			left: 0;
			color: g.$baseColor;
			font-size: g.$fz-m;
			line-height: 1;
			@include g.font-icon('\f105');
			transition: color .3s ease;
		}
		@include g.smMax {
			.c-grid > .__col & {
				margin-top: 5px;
				@include g.l_height(15, 26);
			}
		}
	}
	&__txt {
		grid-area: txt;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		color: g.$textColor;
		@include g.font-normal;
		@include g.l_height(14, 24);
		overflow: hidden;
	}

}