@charset "utf-8";
@use "../../../global/_" as g;
.e-form-radio {
	.__elem {
		display: none;
		&:checked + .__txt {
			&::before {
				border-color: g.$strongTextColor;
			}
			&::after {
				opacity: 1;
			}
		}
	}
	
	.__txt {
		position: relative;
		padding: 0 0 0 30px;
		cursor: pointer;
		&::before {
			left: 0;
			content: '';
			border: 1px solid g.$lineColor;
			@include g.centering-elements(false, true);
			@include g.circle(20);
		}
		&::after {
			left: 5px;
			content: '';
			background: g.$strongTextColor;
			opacity: 0;
			@include g.centering-elements(false, true);
			@include g.circle(12);
		}
	}

	// disabled
	.__elem:disabled + .__txt {
		color: g.$greenishBlue-600;
		cursor: default;
	}
}
