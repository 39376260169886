@charset "utf-8";
@use "../../global/_" as g;
.l-footer {
	background: g.$white;

	@include g.mdMin {
		padding-top: 80px;
	}
	@include g.print {
		display: none;
	}
	&-nav {
		@include g.mdMin {
			display: flex;
		}
	}
	&-nav1 {
		@include g.mdMin {
			flex: 1 1 auto;
		}
		@include g.lgMin {
			display: grid;
			grid-template-columns: repeat(4, auto);
			grid-template-rows: repeat(3, auto);
			grid-column-gap: 70px;
			grid-row-gap: 20px;
			grid-auto-flow: column;
		}
		@include g.mdBtLg {
			display: grid;
			grid-template-columns: repeat(3, auto);
			grid-template-rows: repeat(4, auto);
			grid-column-gap: 60px;
			grid-row-gap: 20px;
			grid-auto-flow: column;
		}
		@include g.mdMax {
			margin: 0 -10px;
			border-top: 1px solid g.$lineColor;
		}
		&__item {
			@include g.mdMax {
				border-bottom: 1px solid g.$lineColor;
			}
		}
		&__link {
			display: flex;
			align-items: center;
			@include g.font-medium;

			@include g.mdMax {
				position: relative;
				padding: 15px 10px;
				font-size: g.$fz-s;
			}
			&::before {
				@include g.font-icon("\f105");

				@include g.mdMin {
					margin-right: 10px;
				}
				@include g.mdMax {
					right: 10px;
					font-size: g.$fz-m;
					@include g.centering-elements(false, true);
				}
			}
		}
	}
	&-nav2 {
		@include g.mdMin {
			margin-left: 80px;
			padding-left: 55px;
			border-left: 1px solid g.$lineColor;
		}
		@include g.mdMax {
			margin-top: 30px;
		}
		&__item {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
		&__link {
			display: flex;
			align-items: center;
			font-size: g.$fz-s;

			@include g.mdMax {
				font-size: g.$fz-xs;
			}
			&::before {
				margin-right: 10px;
				font-size: g.$fz-m;
				@include g.font-icon("\f105");

				@include g.mdMax {
					order: 1;
					margin: 0 0 0 10px;
				}
			}
		}
	}
	&-content {
		margin-top: 50px;
		background: #eef1f6;

		@include g.mdMin {
			margin-top: 80px;
			padding: 32px 0;
		}
		&__inner {
			@include g.mdMin {
				display: flex;
				align-items: center;
			}
		}
	}
	&-logo {
		flex: 0 0 auto;

		@include g.mdMax {
			padding: 25px 0;
			text-align: center;
		}
		&__link {
			display: block;

			@include g.mdMax {
				width: fit-content;
				margin: 0 auto;
			}
			.__elem {
				width: 158px;
				height: 28px;
			}
		}
		&._en_ &__link {
			.__elem {
				width: 278px;
				height: 28px;
			}
		}
	}
	&-copyright {
		margin-left: auto;
		color: g.$greenishBlue-900;
		font-size: g.$fz-s;

		@include g.mdMax {
			margin: 0 -10px;
			padding: 20px 0;
			border-top: 1px solid g.$lineColor;
			font-size: g.$fz-xs;
			text-align: center;
		}
	}
}
