@charset "utf-8";
@use "../../../global/_" as g;

.c-index-visual {
	width: min(100%, 1536px);
	margin: 0 auto;

	&-slider {
		padding-bottom: 35px;
		overflow: hidden;
		opacity: 0;
		transition: opacity 1s ease;

		&.slick-initialized {
			opacity: 1;
		}
		&__item {
			position: relative;
		}
		&__pic {
			display: block;

			@include g.smMin {
				max-height: 550px;
			}
			.__elem {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&__body {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
		&__inner {
			position: relative;
			height: 100%;

			@include g.smMin {
				width: min(100% - 40px, 1446px) !important;
			}
			@include g.smMax {
				width: 100% !important;
			}
		}
		&__txt {
			color: g.$white;
			@include g.font-bold;
			font-size: g.xl_vw(48);
			line-height: 1.5;

			@include g.xlMin {
				font-size: 4.8rem;
			}
			@include g.smMax {
				font-size: g.sm_vw(54);
			}
		}
		&__btn {
			position: absolute;
			right: 0;
			bottom: g.xl_vw(40);

			@include g.xlMin {
				bottom: 40px;
			}
			@include g.smMax {
				bottom: 0;
			}
			.e-btn {
				min-width: 260px;

				@include g.smMax {
					min-width: g.sm_vw(375);
					height: g.sm_vw(80);
					padding-left: g.sm_vw(30);
					justify-content: flex-start;

					&::after {
						right: 15px;
					}
				}
			}
		}
		.slick-dots {
			bottom: 0;

			li {
				width: 10px;
				height: 10px;
				margin: 0 8px;
				padding: 0;

				@include g.smMax {
					width: 15px;
					height: 15px;
				}
				button {
					width: 10px;
					height: 10px;
					padding: 0;

					@include g.smMax {
						width: 15px;
						height: 15px;
					}
					&::before {
						content: '';
						@include g.circle(10);
						background: g.$baseColorLight;
						opacity: .3;

						@include g.smMax {
							width: 15px;
							height: 15px;
						}
					}
				}
			}
		}

		// スライド 1枚目 独自スタイル
		&__item._slide1_ &__txt {
			padding-top: g.xl_vw(105);
			text-shadow: 0 0 25px rgba(#2359B4, 1);
			text-align: center;

			@include g.xlMin {
				padding-top: 105px;
			}
			@include g.smMax {
				padding-top: g.sm_vw(118);
				text-shadow: 0 0 12px rgba(#2359B4, 1);
			}
		}

		// スライド 2枚目 独自スタイル
		&__item._slide2_ &__inner {
			display: flex;
			align-items: center;
		}
		&__item._slide2_ &__txt {
			margin-left: 70px;
			color: #01347d;
			font-size: g.xl_vw(50);

			@include g.xlMin {
				font-size: 5.0rem;
			}
			@include g.smMax {
				margin: 0 0 g.sm_vw(64) g.sm_vw(22);
				font-size: g.sm_vw(54);
			}
		}

		// スライド 3枚目 独自スタイル
		&__item._slide3_ &__pic {
			.__elem {
				@include g.smMin {
					object-position: left center;
				}
			}
		}
		&__item._slide3_ &__inner {
			@include g.smMin {
				display: flex;
				align-items: center;
			}
		}
		&__item._slide3_ &__txt {
			font-size: g.xl_vw(48);

			@include g.smMin {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 28.9vw;
			}
			@include g.xlMin {
				width: 444px;
				font-size: 4.8rem;
			}
			@include g.smMax {
				position: absolute;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 340px;
				width: 45.3vw;
				height: 480px;
				height: 64vw;
				padding-bottom: 4.53vw;
				font-size: 6.4vw;
			}
		}

		// スライド 4枚目 独自スタイル
		&__item._slide4_ &__txt {
			padding-top: g.xl_vw(195);
			font-size: g.xl_vw(56);
			text-align: center;
			text-shadow: 0 0 10px rgba(#00183A, 1);

			@include g.xlMin {
				padding-top: 195px;
				font-size: 5.6rem;
			}
			@include g.smMax {
				padding-top: g.sm_vw(280);
				font-size: g.sm_vw(54);
			}
		}


		// スライド 英語サイト 1枚目 独自スタイル
		&__item._en-slide1_ &__txt {
			padding-top: g.xl_vw(105);
			text-shadow: 0 0 25px rgba(#2359B4, 1);
			text-align: center;

			@include g.xlMin {
				padding-top: 105px;
			}
			@include g.smMax {
				padding-top: g.sm_vw(118);
				text-shadow: 0 0 12px rgba(#2359B4, 1);
			}
		}

		// スライド 英語サイト 2枚目 独自スタイル
		&__item._en-slide2_ &__inner {
			display: flex;
			align-items: center;
		}
		&__item._en-slide2_ &__txt {
			margin-left: 70px;
			color: #01347d;
			font-size: g.xl_vw(50);

			@include g.xlMin {
				font-size: 5.0rem;
			}
			@include g.smMax {
				margin: 0 0 g.sm_vw(64) g.sm_vw(22);
				font-size: g.sm_vw(54);
			}
		}

		// スライド 英語サイト 3枚目 独自スタイル
		&__item._en-slide3_ &__txt {
			padding-top: g.xl_vw(195);
			font-size: g.xl_vw(56);
			text-align: center;
			text-shadow: 0 0 10px rgba(#00183A, 1);

			@include g.xlMin {
				padding-top: 195px;
				font-size: 5.6rem;
			}
			@include g.smMax {
				padding-top: g.sm_vw(280);
				font-size: g.sm_vw(54);
			}
		}



	}
}
