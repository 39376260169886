@charset "utf-8";
@use "../../global/_" as g;
.c-download {

	&-content {
		margin-top: 24px;

		@include g.smMin {
			display: grid;
			grid-template-columns: 63% 31.6%;
			grid-template-rows: auto;
			gap: 0 5.3%;
			grid-auto-flow: row;
			grid-template-areas: "main form";
			margin-top: 56px;
		}

		&__main {
			grid-area: main;
		}
		&__form {
			grid-area: form;

			@include g.smMax {
				margin-top: 56px;
			}
		}
	}
	&-img {
		padding: 30px;
		background: g.$bgColorBlue;
		text-align: center;

		@include g.smMax {
			padding: 20px;
		}
		.__elem {
			max-width: 100%;
		}
	}
	&-bnr {
		margin-top: 88px;
		padding: 32px;
		background: g.$bgColorBlue;

		&__txt {
			text-align: center;
			@include g.font-bold;
			@include g.l_height(18, 27);

			@include g.smMax {
				@include g.l_height(16, 25);
			}
		}
		&__bnr {
			margin-top: 25px;
		}
	}
}

