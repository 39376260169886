@charset "utf-8";
@use "../../global/_" as g;
.m-company-map {
	margin-top: 24px;

	@include g.smMax {
		margin-top: 15px;
	}
	&__map {
		@include g.smMax {
			display: none;
		}
		&._clone_ {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 1280px;
			height: 720px;
			opacity: 0;
			visibility: hidden;
			transition: opacity .3s ease, visibility .3s ease;
		}
	}
	&__btn {
		@include g.smMin {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 24px;
		}
		@include g.smMax {
			.e-btn {
				display: none;
			}
		}
	}
}

// 印刷プレビュー時用
body.is-map-print {
	position: relative;

	.t-wrap {
		display: none;
	}
	.m-company-map__map._clone_ {
		z-index: 10;
		opacity: 1;
		visibility: visible;
	}
}
