@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mt-l {
	margin-top: g.$m-l !important;

	@include g.smMax {
		margin-top: math.div(g.$m-l, 2) !important;
	}
}
