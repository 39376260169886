@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mr-xxxl {
	margin-right: g.$m-xxxl !important;
	
	@include g.smMax {
		margin-right:math.div(g.$m-xxxl, 2) !important;
	}
}
