@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-pl-xxl {
	padding-left: g.$m-xxl !important;
	
	@include g.smMax {
		padding-left:math.div(g.$m-xxl, 2) !important;
	}
}
