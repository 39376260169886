@charset "utf-8";
@use "../../global/_" as g;
.c-faq-list {
	border-bottom: 1px solid g.$lineColor;

	&__item {
		border-top: 1px solid g.$lineColor;
	}
	&__details[open] &__summary {
		&::after {
			rotate: 0deg;
			opacity: 0;
		}
	}
	&__details[open] &__content {
		animation: accordion_anim .5s ease;
	}	
	&__summary {
		position: relative;
		display: block;
		list-style: none;
		padding: 32px 74px 32px 0;
		cursor: pointer;

		@include g.smMax {
			padding: 16px 42px 16px 0;
		}
		&::-webkit-details-marker {
			display:none;
		}
		&::before,
		&::after {
			content: '';
			right: 32px;
			display: block;
			width: 10px;
			height: 2px;
			background: g.$greenishBlue-900;
			@include g.centering-elements(false, true);
			opacity: 1;
			transition: rotate .3s ease, opacity .3s ease;

			@include g.smMax {
				right: 16px;
			}
		}
		&::after {
			rotate: 90deg;
		}
		.__elem {
			display: flex;
			@include g.font-bold;

			&::before {
				content: 'Q';
				flex: 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				@include g.circle(32);
				margin-right: 12px;
				background: g.$baseColor;
				color: g.$white;
				@include g.font-en;

				@include g.smMax {
					width: 28px;
					height: 28px;
					margin-right: 10px;
				}
			}
		}
		@include g.hover {
			.__elem {
				color: g.$linkTextColor;
			}
		}
	}
	&__content {
		position: relative;
		padding: 0 32px 40px 46px;

		@include g.smMax {
			padding: 0 16px 20px 38px;
		}

		&::before {
			content: 'A';
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			@include g.circle(32);
			margin-right: 12px;
			background: g.$white;
			border: 1px solid g.$baseColor;
			color: g.$linkTextColor;
			@include g.font-en;

			@include g.smMax {
				width: 28px;
				height: 28px;
			}
		}
		& > *:not(:first-child) {
			margin-top: g.$m-m;
			
			@include g.smMax {
				margin-top: 15px;
			}
		}
		img { max-width: 100%; }
	}
}