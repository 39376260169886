@charset "utf-8";
@use "../../global/_" as g;
.m-panel-case {
	display: block;
	@include g.font-medium;

	@include g.media-hover {
		&:hover &__thumb {
			.__elem {
				scale: 1.075;
			}
			&::after {
				@include g.line_animation;
			}
		}
		&:hover &__ttl {
			color: g.$strongTextColor;

			&::before {
				color: g.$strongTextColor;
			}
		}
	}

	&__thumb {
		@include g.object-fit;

		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			height: 3px;
			background: g.$baseColorLight;
		}
	}
	&__ttl {
		position: relative;
		margin-top: 15px;
		padding-left: 15px;
		color: g.$textColor;
		transition: color .3s ease;

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			color: g.$baseColor;
			@include g.font-icon('\f105');
			transition: color .3s ease;
		}
	}
	&__txt {
		margin-top: 10px;
		color: g.$textColor;
		@include g.font-bold;
		@include g.l_height(18, 31);
	}
	&__product {
		margin-top: 15px;
		color: g.$baseColor;
		font-size: g.$fz-s;
	}
	&__tag {
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;
		padding-top: 7px;
		border-top: 1px solid g.$lineColor;

		& > * {
			margin: 3px 6px 3px 0;
		}
	}
}