@charset "utf-8";
@use "../../global/_" as g;
.m-page-head {
	display: grid;
	grid-template-columns: 45% 50%;
	grid-template-rows: auto 1fr;
	gap: 0 5%;
	grid-auto-flow: row;
	grid-template-areas:
	  "ttl img"
	  "txt img";

	@include g.smMin {
		margin-top: 56px;
	}
	@include g.smMax {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, auto);
		gap: 20px 0;
		grid-auto-flow: row;
		grid-template-areas:
		  "ttl"
		  "txt"
		  "img";
	}
	&__ttl {
		grid-area: ttl;
		margin-top: 32px;
		
		.__catedory {
			display: block;
			color: g.$subTextColor1;
			@include g.font-medium;
			@include g.l_height(14, 24);

			@include g.smMax {
				@include g.l_height(12, 20);
			}
		}
		.__ttl {
			display: block;
			@include g.font-bold;
			@include g.l_height(36, 70);

			@include g.smMax {
				@include g.l_height(25, 35);
			}
		}
	}
	&__txt {
		grid-area: txt;

		@include g.smMin {
			margin-top: 35px;
		}
	}
	&__img {
		grid-area: img;

		@include g.smMax {
			margin: 0 15px;
		}
		.__elem { max-width: 100%; }
	}
}
