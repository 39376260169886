@charset "utf-8";
@use "../../global/_" as g;
.m-box02 {
	padding: 0 56px 56px;
	background: g.$white;

	@include g.smMax {
		padding: 0 15px 15px;
	}

	//カラーオプション -------------------------------
	&._line-gray_ {
		border: 1px solid g.$lineColor;
	}
	&._blue_ {
		background: g.$greenishBlue-100;
	}
	&._gray_ {
		background: g.$bgColorGray;
	}
}
