@charset "utf-8";
@use "../../../global/_" as g;
.c-index-services {
	&__inner {
		position: relative;
		width: min(1536px, 100%);
		margin: 0 auto;
		padding: 130px 0;
		overflow: hidden;

		@include g.smMin {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		@include g.smBtMd {
			padding: 65px 0;
		}
		@include g.smMax {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: auto auto auto auto auto;
			gap: 0 0;
			grid-auto-flow: row;
			grid-template-areas:
				"ttl"
				"img"
				"lead"
				"txt"
				"btn";
			padding: 65px 10px;
		}
		&::before {
			content: attr(data-txt);
			position: absolute;
			top: -35px;
			right: 0;
			z-index: -1;
			color: g.$strongTextColor;
			text-transform: uppercase;
			opacity: .05;
			@include g.font-en;
			@include g.l_height(140, 170);

			@include g.smMax {
				top: -15px;
				right: -30px;
				@include g.l_height(60, 73);
			}
		}
	}
	&__body {
		flex: 0 0 min(540px, 50% - 8%);
		margin-right: 4%;

		@include g.smMax {
			display: contents;
		}
	}
	&__ttl {
		grid-area: ttl;
	}
	&__lead {
		grid-area: lead;
		margin-top: 40px;

		@include g.smMax {
			margin-top: 20px;
		}
	}
	&__txt {
		grid-area: txt;
		margin-top: 30px;

		@include g.smMax {
			margin-top: 20px;
		}
	}
	&__btn {
		grid-area: btn;
		margin-top: 40px;
	}
	&__img {
		grid-area: img;
		flex: 0 0 50%;

		@include g.smMax {
			margin-top: 30px;
		}
		.__elem { width: 100%; }
	}
}
