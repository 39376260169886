@charset "utf-8";
@use "../../global/_" as g;
.m-contact-box02 {
	display: flex;
	align-items: center;

	@include g.smMin {
		justify-content: space-between;
	}
	@include g.smMax {
		flex-direction: column-reverse;

		.e-a-tel02 {
			margin-top: 10px;
		}
	}
	.c-grid._col2_ & {
		@include g.smBtMd {
			flex-direction: column-reverse;

			.e-a-tel02 {
				margin-top: 15px;
			}
		}
	}
}