@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mb-xl {
	margin-bottom: g.$m-xl !important;
	
	@include g.smMax {
		margin-bottom: math.div(g.$m-xl, 2) !important;
	}
}
