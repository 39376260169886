@charset "utf-8";
@use "../../global/_" as g;
.m-download {
	text-align: center;

	&__txt {
		@include g.font-bold;
		@include g.l_height(18, 31);

		@include g.smMax {
			@include g.l_height(16, 25);
		}
	}
	&__btn {
		margin-top: g.$m-s;

		@include g.smMax {
			margin-top: g.$m-xxs;
		}
	}
	& + & {
		margin-top: g.$m-m;
	}
}