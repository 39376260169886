@charset "utf-8";
@use "../../../global/_" as g;
.c-ir-index {
	display: grid;
	grid-template-rows: repeat(2, auto);
	grid-template-columns: 71.6% 23%;
	grid-template-areas:
		"news calender"
		"menu individual";
	grid-auto-flow: row;
	gap: g.$m-l 5.3%;

	@include g.smMax {
		grid-template-rows: repeat(4, auto);
		grid-template-columns: 1fr;
		grid-template-areas:
			"news"
			"calender"
			"menu"
			"individual";
		gap: 48px 0;
	}

	&__news { grid-area: news; }
	&__calender { grid-area: calender; }
	&__menu { grid-area: menu; }
	&__individual { grid-area: individual; }


}
