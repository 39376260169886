@charset "utf-8";

// ============================================================
// breakpoint
// ============================================================
$sxs: 375;
$xs: 415; // mobile
$sm: 768; //mobile
$md: 1023; //tablet(大)
$lg: 1279;
$xl: 1536;

$xs-1: $xs + 1; //416
$sm-1: $sm + 1; //769
$md-1: $md + 1; //1024
$lg-1: $lg + 1; //1280
$xl-1: $lg + 1; //1537

$xsMin: "min-width:" + $xs-1 + "px"; //min-width:416px
$smMin: "min-width:" + $sm-1 + "px"; //min-width:769px
$mdMin: "min-width:" + $md-1 + "px"; //min-width:1024px
$lgMin: "min-width:" + $lg-1 + "px"; //min-width:1280px
$xlMin: "min-width:" + $xl-1 + "px"; //min-width:1537px

$xsMax: "max-width:" + $xs + "px"; //max-width:415px
$smMax: "max-width:" + $sm + "px"; //max-width:768px
$mdMax: "max-width:" + $md + "px"; //max-width:1023px
$lgMax: "max-width:" + $lg + "px"; //max-width:1279px

// ============================================================
// design width
// ============================================================
$smDesignW: 750;
$mdDesignW: $md;
$lgDesignW: $lg;
$xlDesignW: 1536;

// ============================================================
// color
// ============================================================
$white: #ffffff;
$black: #000000;

$gray-100: #f8f8f8;
$gray: #888;
$gray-900: #2a2a2a;

$greenishBlue-100: #f2f7fc;
$greenishBlue-200: #eef1f6;
$greenishBlue-300: #dfe6f2;
$greenishBlue: #ceddf1;
$greenishBlue-600: #d1d8e1;
$greenishBlue-700: #a4b5cc;
$greenishBlue-900: #5f748f;

$baseColor: #19509d;
$baseColorLight: #2b95fe;
$baseColorDark: #083d88;

$secondaryColor: #ff4f55;
$secondaryColorLight: #fff9f9;
$secondaryColorDark: #f23434;

$tertiaryColor: #c89b32;

$textColor: $gray-900;
$subTextColor1: $gray;
$subTextColor2: $greenishBlue-700;
$linkTextColor: $baseColor;
$strongTextColor: $baseColorLight;

$lineColor: $greenishBlue-600;

$bgColorGray: $gray-100;
$bgColorBlue: $greenishBlue-100;
//$bgColorGreenishBlue: $greenishBlue-300;

$facebook: #1877f2;
$twitter: #1b9df0;

// ============================================================
// font size
// ============================================================
$fz-xxxs: 1rem;
$fz-xxs: 1.2rem;
$fz-xs: 1.3rem;
$fz-s: 1.4rem;
$fz-m: 1.6rem;
$fz-l: 1.8rem;
$fz-xl: 2rem;
$fz-xxl: 2.2rem;
$fz-xxxl: 2.4rem;

// ============================================================
// マージンサイズ
// ============================================================
$m-xxxxs: 5px;
$m-xxxs: 10px;
$m-xxs: 15px;
$m-xs: 20px;
$m-s: 25px;
$m-m: 30px;
$m-ml: 45px;
$m-l: 60px;
$m-xl: 80px;
$m-xxl: 100px;
$m-xxxl: 150px;

// ============================================================
// grid
// ============================================================
$maxcol: 12;
$max-width: 100%;
$grid-margin: 20px; //PC用 カラム間余白
$grid-margin-sm: 10px; //SP用 カラム間余白
