@charset "utf-8";
@use "../../global/_" as g;
.c-news-entry {
	margin-top: 60px;
	
	&__head {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-template-rows: auto auto auto;
		gap: 0 20px;
		grid-auto-flow: row;
		grid-template-areas:
		  "year year"
		  "ttl ttl"
		  "date category";
		align-items: center;
		padding-bottom: 32px;
		border-bottom: 1px solid g.$lineColor;
	
		.__year {
			grid-area: year;
			color: g.$subTextColor1;
			font-size: g.$fz-s;
			@include g.font-medium;
		}
		.__ttl {
			grid-area: ttl;
			margin: 5px 0 20px;
			line-height: 1.65;
		}
		.__date {
			grid-area: date;
			color: g.$subTextColor1;
			font-size: g.$fz-s;
			@include g.font-medium;
			text-align: right;
		}
		.__category {
			grid-area: category;
		}
	}

	&__body {
		margin-top: 56px;

		& > * {
			margin-top: g.$m-m;
		}
		.m-box + .m-box {
			margin-top: g.$m-m;
		}
		.e-ttl03,
		.e-ttl04,
		.e-ttl05 {
			&:not(:first-child) {
				margin-top: 88px;
			}
		}
		img {
			max-width: 100%;
		}
	}
}
