@charset "utf-8";
@use "../../global/_" as g;
.m-check-list {
	display: flex;
	flex-wrap: wrap;
	margin: -8px;

	@include g.smMax {
		margin: -6px;
	}

	.__col {
		margin: 8px;

		@include g.smMax {
			margin: 6px;
		}
	}
}