@charset "utf-8";
@use "../../global/_" as g;
.m-panel {
	display: block;
	@include g.font-medium;

	@include g.media-hover {
		&:hover &__thumb {
			.__elem {
				scale: 1.075;
			}
			&::after {
				@include g.line_animation;
			}
		}
		&:hover &__ttl {
			color: g.$strongTextColor;

			&::before {
				color: g.$strongTextColor;
			}
		}
	}

	&__thumb {
		@include g.object-fit;
		background: g.$white;

		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			height: 3px;
			background: g.$baseColorLight;
		}
		svg.__elem {
			width: 100px;
			height: 80px;
			object-fit: none;
			@include g.centering-elements;

			@include g.smMax {
				width: 56px;
				height: 48px;
			}
		}
	}
	&__label {
		display: flex;
		flex-wrap: wrap;
		margin: 13px 0 -3px;

		& > * {
			margin: 3px 6px 3px 0;
		}
	}
	&__ttl {
		position: relative;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		margin-top: 15px;
		padding-left: 15px;
		color: g.$textColor;
		@include g.font-bold;
		@include g.l_height(18, 31);
		overflow: hidden; 
		transition: color .3s ease;

		&::before {
			position: absolute;
			top: 1px;
			left: 0;
			color: g.$baseColor;
			font-size: g.$fz-m;
			@include g.font-icon('\f105');
			transition: color .3s ease;
		}
		@include g.smMax {
			.c-grid > .__col & {
				margin-top: 5px;
				@include g.l_height(15, 26);
			}
		}
	}
	&__txt {
		margin-top: 10px;
		color: g.$textColor;
		@include g.font-normal;
		@include g.l_height(15, 26);
	}
	&__date {
		display: block;
		margin-top: 5px;
		color: g.$subTextColor1;
		font-size:  g.$fz-s;
		text-align: right;
	}

}