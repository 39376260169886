@charset "utf-8";
@use "../../global/_" as g;
.e-list-circle {
	&__item {
		position: relative;
		padding-left: 17px;

		@include g.smMax {
			padding-left: 15px;
		}
		&:not(:first-child) {
			margin-top: 15px;
		}
		&::before {
			content: '';
			position: absolute;
			top: .8em;
			left: 0;
			display: block;
			background: g.$baseColor;
			@include g.circle(5);

			@include g.smMax {
				top: .65em;
				width: 4px;
				height: 4px;
			}
		}
	}
}
