@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mt-xxxl {
	margin-top: g.$m-xxxl !important;
	
	@include g.smMax {
			margin-top:math.div(g.$m-xxxl, 2) !important;
	}
}
