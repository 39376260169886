@charset "utf-8";
@use "../../global/_" as g;
.e-figure {
	display: table;
	margin: 0 auto;

	img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
	figcaption {
		display: table-caption;
		caption-side: bottom;
		margin-top: g.$m-xxs;
		@include g.l_height(14, 24);

		@include g.smMax {
			@include g.l_height(13, 22);
		}
		&._al-c_ {
			text-align: center;
		}
	}
}