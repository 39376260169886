@charset "utf-8";
@use "../../global/_" as g;
.e-iframe {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;

	.__elem {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
