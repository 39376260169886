@charset "utf-8";
@use "../../global/_" as g;
.m-news-list {
	border-top: 1px solid g.$lineColor;

	&._bdt-none_ {
		border-top: none;
	}

	&__item {
		display: flex;
		padding: 25px 0;
		border-bottom: 1px solid g.$lineColor;
		@include g.font-medium;

		@include g.smMax {
			flex-wrap: wrap;
			align-items: center;
			padding: 15px 0;
		}
	}
	&__link {
		display: flex;
		width: 100%;
		margin: -25px 0;
		padding: 25px 0;

		@include g.smMax {
			flex-wrap: wrap;
			align-items: center;
			margin: -15px 0;
			padding: 15px 0;
		}

		@include g.hover {
			.__txt {
				color: g.$baseColor;
			}
		}
	}
	&__link[target*="blank"],
	&__link[href$="pdf"] {
		&::before { content: none; }

		.__txt {
			position: relative;
			padding-left: 25px;

			&::before {
				position: absolute;
				left: 0;
				margin-left: 5px;
			}
		}
	}
	&__link[target*="blank"] {
		.__txt {
			&::before {
				top: 6px;
				@include g.font-icon('\e902');
				color: g.$baseColor;
				font-size: 1.1rem;

				@include g.smMax {
					top: 4px;
				}
			}
		}
	}
	&__link[href$="pdf"] {
		.__txt {
			&::before {
				top: 0;
				@include g.font-icon('\f1c1');
				color: g.$secondaryColorDark;
				font-size: g.$fz-m;
			}
		}
	}
	&__link + &__link {
		.__txt {
			position: relative;
			padding-left: 30px;

			&::before {
				content: '';
				position: absolute;
				top: .5rem;
				left: 5px;
				display: inline-block;
				width: 10px;
				height: 8px;
				border: 2px solid g.$baseColor;
				border-right: none;
				border-top: none;
			}
		}
	}
	
	.__date {
		flex: 0 0 auto;
		color: g.$baseColor;

		&._opacity_ {
			opacity: 0;

			@include g.smMax {
				height: 0;

				& + .__txt {
					margin-top: 0;
				}
			}
		}
	}
	.__category {
		flex: 0 0 auto;
		margin-left: 20px;

		@include g.smMax {
			margin-left: 15px;
		}
	}
	.__txt {
		margin-left: 25px;
		color: g.$black;
		transition: color .3s ease;

		@include g.smMax {
			flex: 0 0 100%;
			margin: 10px 0 0;
		}
	}

	//決算短信用 
	&__link._revision_ {
		.__date {
			@include g.hidden;
		}
		.__txt {
			position: relative;
			margin-left: calc(8.3rem + 50px);

			@include g.smMax {
				flex: 0 0 calc(100% - 60px);
				margin-left: 60px;
			}
			&::before {
				content: "";
				position: absolute;
				top: 0.2rem;
				left: -30px;
				display: inline-block;
				width: 10px;
				height: 8px;
				border-left: 2px solid g.$baseColor;
				border-bottom: 2px solid g.$baseColor;
			}
		}
	}
}