@charset "utf-8";
@use "../../global/_" as g;
.c-important {
	margin-top: 60px;
	padding: 30px 35px;
	background: g.$secondaryColorLight;
	border: 1px solid g.$secondaryColor;
	@include g.font-medium;

	@include g.smMin {
		display: flex;
		align-items: center;
	}
	@include g.smBtMd {
		padding: 25px 3%;
	}
	@include g.smMax {
		margin-top: 40px;
		padding: 25px 15px;
	}
	&__ttl {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		color: g.$black;
		font-size: g.$fz-l;

		@include g.smMax {
			justify-content: center;
			font-size: g.$fz-m;
		}
		&::before {
			content: '!';
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 10px;
			background: g.$secondaryColor;
			color: g.$white;
			@include g.circle(30);
			@include g.font-bold;

			@include g.smMax {
				width: 25px;
				height: 25px;
				margin-right: 8px;
				font-size: 1.5rem;
			}
		}
	}
	&__list {
		flex: 1 1 auto;
		margin-left: 45px;

		@include g.smMax {
			margin: 25px 0 0;
		}
	}
	&__item {
		color: g.$black;
		@include g.smMin {
			display: flex;
		}
		&:not(:first-child) {
			margin-top: 25px;

			@include g.smMax {
				margin-top: 20px;
			}
		}
	}
	&__link {
		color: g.$black;
		transition: color .3s ease;

		@include g.smMin {
			display: flex;
		}
		@include g.hover {
			color: g.$secondaryColor;
		}
	}
	&__link[target*="blank"],
	&__link[href$="pdf"] {
		&::before {
			content: none;
		}
	}
	&__link[target*="blank"] &__txt {
		&::before {
			margin-right: 5px;
			color: g.$baseColor;
			font-size: 1.1rem;
			@include g.font-icon('\e902');
			transition: color .3s ease;
		}
	}
	&__link[href$="pdf"] &__txt {
		&::before {
			margin-right: 5px;
			color: g.$secondaryColorDark;
			font-size: g.$fz-m;
			@include g.font-icon('\f1c1');
		}
	}
	&__time {
		flex: 0 0 auto;
	}
	&__txt {
		@include g.smMin {
			flex: 1 1 auto;
			margin-left: 30px;
		}
	}
	@include g.media-hover {
		&__link:hover &__txt {
			&::before {
				color: g.$secondaryColor;
			}
		}
	}
}