@charset "utf-8";
@use "../../global/_" as g;
.e-list-disc {
	&__item {
		position: relative;
		padding-left: 11px;

		@include g.smMax {
			padding-left: 8px;
		}
		&:not(:first-child) {
			margin-top: 6px;
		}
		&::before {
			content: '';
			position: absolute;
			top: .88em;
			left: 0;
			display: block;
			background: g.$textColor;
			@include g.circle(3);

			@include g.smMax {
				top: .75em;
			}
		}
	}
}
