@charset "utf-8";
@use "../../global/_" as g;
.m-card02 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding: 20px 22px;
	border: 1px solid g.$lineColor;
	color: g.$textColor;

	.__ttl {
		position: relative;
		padding-left: 14px;
		@include g.font-bold;
		@include g.l_height(18, 31);

		&::after {
			position: absolute;
			top: 7px;
			left: 0;
			color: g.$linkTextColor;
			font-size: g.$fz-m;
			line-height: 1;
			@include g.font-icon('\f105');
			transition: color .3s ease;
		}
	}
	.__txt {
		margin-top: 10px;
		@include g.l_height(14, 24);
	}
	@include g.hover {
		.__ttl {
			&::after {
				color: g.$strongTextColor;
			}
		}
	}

	&[target*="blank"] {
		&::before {
			content: none;
		}
		.__ttl {
			&::before {
				@include g.font-icon('\e902');
				margin: 0 6px 0 4px;
				color: g.$linkTextColor;
				font-size: 1.1rem;
				transition: color .3s ease;
			}
		}
		@include g.hover {
			.__ttl {
				&::before {
					color: g.$strongTextColor;
				}
			}
		}
	}
	&[href$=".pdf"] {
		&::after {
			content: none;
		}
		.__ttl {
			&::before {
				@include g.font-icon('\f1c1');
				margin: 0 6px 0 4px;
				color: g.$secondaryColorDark;
				font-size: g.$fz-m;
			}
		}
		@include g.hover {
			.__ttl {
				&::before {
					color: g.$secondaryColorDark;
				}
			}
		}
	}

}
