@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mt-xxl {
	margin-top: g.$m-xxl !important;

	@include g.smMax {
		margin-top:math.div(g.$m-xxl, 2) !important;
	}
}
