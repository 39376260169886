@charset "utf-8";
@use "../../global/_" as g;
.m-bnr-case {
	@include g.smMin {
		display: flex;
	}
	&__thumb {
		overflow: hidden;

		@include g.smMin {
			flex: 0 0 37%;
			position: relative;
			height: 173px;
		}
		.__elem {
			width: 100%;
			transition: scale .65s ease;

			@include g.smMin {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&__body {
		background: url('/common/img/bg_bnr_case.png') 0 0 no-repeat;
		background-size: cover;

		@include g.smMin {
			flex: 0 0 63%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 4.6%;
		}
		@include g.smBtMd {
			flex-direction: column;
			align-items: flex-start;
		}
		@include g.smMax {
			padding: 20px;
		}
	}
	&__ttl {
		flex: 0 0 auto;
		display: flex;
		align-items: center;
		color: g.$linkTextColor;
		@include g.font-medium;
		@include g.l_height(24, 36);
		transition: color .3s ease;

		@include g.smMax {
			@include g.l_height(22, 33);
		}
		&::before {
			margin-right: 15px;
			transition: border .3s ease, background .3s ease, color .3s ease;
			@include g.ico-circle-arrow-right(transparent);

			@include g.smMax {
				width: 30px;
				height: 30px;
				margin-right: 10px;
			}
		}
	}
	&__txt {
		margin-top: 15px;
		color: g.$textColor;
		transition: color .3s ease;

		@include g.mdMin {
			flex: 1 1 auto;
			margin-left: 32px;
		}
	}
	@include g.media-hover {
		&:hover &__thumb {
			.__elem {
				scale: 1.1;
			}
		}
		&:hover &__ttl {
			color: g.$strongTextColor;

			&::before {
				@include g.ico-circle-arrow-right(g.$strongTextColor, g.$strongTextColor, g.$white);
			}
		}
		&:hover &__txt {
			color: g.$strongTextColor;
		}
	}
}