@charset "utf-8";
@use 'sass:math';
@use "../../../global/_" as g;
.u-mt-xl {
	margin-top: g.$m-xl !important;

	@include g.smMax {
		margin-top:math.div(g.$m-xl, 2) !important;
	}
}
