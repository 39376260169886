@charset "utf-8";
@use "../../global/_" as g;
@for $i from 0 through 100 {
	.u-w#{$i}p {
		width: #{percentage($i * 0.01)} !important;
	}
	@include g.smMax {
		.u-sm-w#{$i}p {
			width: #{percentage($i * 0.01)} !important;
		}
	}
}
@for $i from 0 through 90 {
	.u-wd#{$i * 10} {
		width: #{$i * 10}px !important;
	}
	@include g.smMax {
		.u-sm-wd#{$i * 10} {
			width: #{$i * 10}px !important;
		}
	}
}
