@charset "utf-8";
@use "../../../global/_" as g;
.c-ir-individual {
	&-stock {
		margin-top: 80px;

		@include g.smMax {
			margin-top: 40px;
		}
		&__ttl {
			@include g.font-bold;
			@include g.l_height(24, 36);
			text-align: center;

			@include g.smMax {
				@include g.l_height(20, 30);
			}
		}
		&__txt {
			margin-top: 10px;
			text-align: center;
		}
		&__btn {
			margin-top: 24px;
		}
	}

	&-block {
		background: url('/ir/individual/img/bg_index_01.png') 0 0 no-repeat #F2F8FF;
		background-size: cover;

		@include g.smMin {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 40px;

			&._reverse_ {
				flex-direction: row-reverse;
			}
			&._reverse_ &__body {
				margin: 0 0 0 60px;
			}
		}
		@include g.smMax {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: repeat(4, auto);
			gap: 0 0;
			grid-auto-flow: row;
			grid-template-areas:
			  "ttl"
			  "txt"
			  "img"
			  "btn";
			padding: 20px 20px 30px;
		}
		&__img {
			flex: 0 1 auto;
			grid-area: img;
			.__elem {
				max-width: 100%;
			}
			@include g.smMax {
				max-width: 80%;
				margin: 15px auto 0;
				text-align: center;
			}
		}
		&__body {
			@include g.smMin {
				flex: 0 0 300px;
				margin: 0 60px 0 0;
			}
			@include g.smMax {
				display: contents;
			}
		}
		&__ttl {
			grid-area: ttl;
			color: g.$linkTextColor;
			@include g.font-bold;
			@include g.l_height(24, 36);

			@include g.smMax {
				@include g.l_height(20, 30);
			}
		}
		&__txt {
			grid-area: txt;
			margin-top: 20px;

			@include g.smMax {
				margin-top: 15px;
			}
		}
		&__btn {
			grid-area: btn;
			margin-top: 24px;
			text-align: center;
		}
	}

	&-bnr-finance {
		position: relative;
		display: flex;
		align-items: center;
		height: 154px;
		padding-left: 56px;
		background: url('/ir/individual/img/bg_index_02.png') 0 0 no-repeat g.$bgColorBlue;
		background-size: cover;

		@include g.smMax {
			height: 100px;
			padding-left: 15px;
		}
		&::before {
			content: '';
			position: absolute;
			right: 63px;
			bottom: 0;
			width: 340px;
			height: 154px;
			background: url('/ir/individual/img/bg_index_03.png') right bottom no-repeat;

			@include g.smMax {
				right: 5px;
				width: 150px;
				height: 68px;
				background-size: 100% auto;
			}
		}
		.__elem {
			display: flex;
			align-items: center;
			@include g.font-bold;
			@include g.l_height(24, 36);

			@include g.smMax {
				@include g.l_height(20, 30);
			}

			&::before {
				margin-right: 15px;
				@include g.ico-circle-arrow-right(transparent, g.$lineColor);
				transition: background .3s ease;

				@include g.smMax {
					width: 30px;
					height: 30px;
					margin-right: 10px;
				}
			}
		}

		@include g.media-hover {
			&:hover {
				.__elem {
					&::before {
						@include g.ico-circle-arrow-right(g.$strongTextColor, g.$strongTextColor, g.$white);
					}
				}
			}
		}
	}
}
