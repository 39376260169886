@charset "utf-8";
@use "../../../global/_" as g;

.c-index-bnr {
    width: min(1200px, 100% - 40px);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    &__item {
        width: calc((100% - 30px) / 3);
        max-width: 380px;
        display: block;
        transition: 0.3s;

        img {
            width: 100%;
        }

        a {
            transition: 0.3s;

            &[target*="blank"]::before {
                content: none !important;
            }

            &:hover {
                opacity: 0.7;
            }
        }
    }
    @include g.smMax {
        flex-direction: column;

        &__item {
            width: 100%;
            max-width: 100%;
        }
    }
}
