@charset "utf-8";
@use "../../../global/_" as g;
.c-tktkstories-infographics {
	&__ttl {
		margin-bottom: 15px;
		@include g.font-bold;
		@include g.l_height(32, 56);
	}
	&__notes {
		color: #333;
		text-align: right;
		@include g.l_height(14, 24);

		@include g.smMax {
			margin-top: 15px;
			@include g.l_height(12, 22);
		}
	}

	&-corporate {
		margin-top: 40px;

		&__body {
			@include g.lgMin {
				display: grid;
				grid-template-columns: 392px 190px 594px;
				grid-template-rows: repeat(4, auto);
				gap: 12px 12px;
				grid-auto-flow: row;
				grid-template-areas:
					"establishment establishment location"
					"product product product"
					"sale composition composition"
					"group . .";
			}
			@include g.smBtLg {
				display: grid;
				grid-template-columns: 32.6% 15.8% 49.5%;
				grid-template-rows: repeat(4, auto);
				gap: 1% 1%;
				grid-auto-flow: row;
				grid-template-areas:
					"establishment establishment location"
					"product product product"
					"sale composition composition"
					"group . .";
			}
			@include g.smMax {
				& > * {
					margin-top: 12px;
				}
			}
		}
	}

	&-works {
		margin-top: 88px;

		@include g.smMax {
			margin-top: 60px;
		}

		&__body01 {
			@include g.smMin {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(3, auto);
				gap: 12px 12px;
				grid-auto-flow: row;
				grid-template-areas:
					"employee gendar"
					"age age"
					"recruit holiday";
			}
			@include g.smMax {
				& > * {
					margin-top: 12px;
				}
			}
		}
		&__body02 {
			@include g.smMin {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: repeat(2, auto);
				gap: 12px 12px;
				margin-top: 12px;
			}
			@include g.smMax {
				& > * {
					margin-top: 12px;
				}
			}
		}
	}

	&-btn {
		position: relative;
		width: calc(100vw - var(--scrollbar));
		margin: 88px calc(50% - ((100vw - var(--scrollbar)) / 2)) 0;
		padding: 32px calc(((100vw - var(--scrollbar)) / 2) - 50%);
		background: g.$bgColorGray;
	
		@include g.smMax {
			width: 100vw;
			margin: 60px calc(50% - 50vw) 0;
			padding: 24px calc(50vw - 50%);
		}
		&__txt {
			text-align: center;
		}
		&__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 24px;

			@include g.smMax {
				flex-direction: column;
			}

			& > * {
				&:not(:first-child) {
					margin-left: 60px;

					@include g.smMax {
						margin: 15px 0 0 0;
					}
				}
			}
		}
	}

	&-box {
		position: relative;
		background: g.$bgColorBlue;

		&__ttl {
			position: absolute;
			@include g.font-bold;
			@include g.l_height(28, 42);
			text-align-last: left;

			@include g.lgMin {
				top: 24px;
				left: 40px;
			}
			@include g.smBtLg {
				top: 1.8vw;
				left: 3.1vw;
				font-size: 2.1vw;
			}
			@include g.smMax {
				top: 15px;
				left: 15px;
				@include g.l_height(20, 30);
			}

			.__hidden {
				@include g.hidden;
			}
		}
		img {
			display: block;
			max-width: 100%;
		}
		&__note {
			position: absolute;
			right: 40px;
			bottom: 24px;
			color: g.$subTextColor1;
			@include g.l_height(14, 24);
			// background: #f90;

			@include g.lgMin {
				right: 40px;
				bottom: 24px;
			}
			@include g.smBtLg {
				right: 3.1vw;
				bottom: 1.8vw;
				font-size: 1vw;
			}
			@include g.smMax {
				right: 15px;
				bottom: 15px;
			}

			&._static_ {
				// position: static;
				// text-align: right;
				background: #0f0;
			}
		}

		&._establishment_ {
			grid-area: establishment;
			padding-bottom: 30px;
		}
		&._location_ {
			grid-area: location;
			padding-bottom: 30px;
		}
		&._product_ {
			grid-area: product;
		}
		&._product_ &__product {
			@include g.smMin {
				.__img1,
				.__img2,
				.__img3 {
					display: none;
				}
			}
			@include g.smMax {
				padding: 60px 15px 15px;
			}
			.__img1 {
				max-width: 60vw;
				margin: 0 auto;
			}
			.__img2 {
				margin: 20px 0 0;
			}
			.__img3 {
				margin: 15px 0 0;
			}
		}
		&._product_ &__note {
			@include g.smMin {
				top: 40%;
				bottom: auto;
			}
			@include g.smMax {
				position: static;
				margin-top: 5px;
				text-align: right;
			}
		}
		&._sale_ {
			grid-area: sale;

			@include g.smMin {
				padding-top: 25px;
			}
			@include g.smMax {
				padding-bottom: 30px;

				img {
					max-width: 85vw;
					margin: 0 auto;
				}
			}
		}
		&._composition_ {
			grid-area: composition;
			padding-bottom: 30px;

			@include g.smMax {
				padding: 10px 0 40px;
				overflow: hidden;

				img {
					scale: 1.1;
				}
			}
		}
		&._group_ {
			grid-area: group;
			padding-bottom: 30px;

			@include g.smMax {
				img {
					max-width: 85vw;
					margin: 0 auto;
				}
			}
		}
		&._employee_ {
			grid-area: employee;
			padding-bottom: 20px;

			@include g.smMax {
				padding-bottom: 30px;
			}
		}
		&._gender_ {
			grid-area: gendar;
			display: flex;
			align-items: center;
			justify-content: center;

			@include g.smMax {
				padding-bottom: 10px;
			}
		}
		&._age_ {
			grid-area: age;
		}
		&._age_ &__age {
			display: grid;
			grid-template-columns: 1.7fr 1fr;
			grid-template-rows: repeat(2, auto);
			gap: 20px 20px;
			grid-auto-flow: row;
			grid-template-areas:
				"img1 img3"
				"img2 img2";
			padding: 50px 20px;

			.__img1 { grid-area: img1; }
			.__img2 { grid-area: img2; }
			.__img3 { grid-area: img3; }

			@include g.smMin {
				display: none;
			}
		}
		&._recruit_ {
			grid-area: recruit;
		}
		&._holiday_ {
			grid-area: holiday;
		}
		&._years_ {
		}
		&._overtime_ {
		}
		&._qualification_ {
		}
		&._paid-holiday_ {
		}
		&._maternity_ {
		}
		&._return_ {
		}
	}

	&-certification {
		@include g.mdMin {
			padding: 42px 56px;
		}
		@include g.smMin {
			display: flex;
			align-items: center;
		}
		&__body {
			flex: 1 1 auto;
			
			.__ttl {
				font-size: g.$fz-l;
				@include g.font-medium;
			}
		}
		&__figure {
			flex: 0 0 auto;
			display: flex;
			align-items: flex-start;

			@include g.smMax {
				justify-content: center;
				margin-top: 20px;
			}
			.__elem {
				@include g.smMin {
					margin-left: 28px;
				}
				@include g.smMax {
					max-width: calc((100% - 15px) / 2);
					&:not(:first-child) {
						margin-left: 15px;
					}
				}
			}
		}
	}

}
