@charset "utf-8";
@use "../../global/_" as g;
.c-research {

	// 研究開発トップページ
	&-index {
		&-head {
			@include g.smMin {
				display: grid;
				grid-template-columns: 30% 66.6%;
				grid-template-rows: auto;
				grid-auto-flow: row;
				grid-template-areas: "body slider";
				gap: 0 3.3%;
				margin-top: 64px;
			}
			@include g.smMax {
				padding-bottom: 30px;
			}
			&__body {
				grid-area: body;

				.e-ttl01 {
					margin-bottom: 24px;

					@include g.smMin {
						margin: 0 0 35px;
						text-align: left;
					}
				}
			}
			&__slider {
				grid-area: slider;

				@include g.smMax {
					margin-top: 25px;
				}
			}
		}

		&-slider {
			opacity: 0;
			transition: opacity .3s ease;
			
			&.slick-initialized {
				opacity: 1;
			}
			&__item {
			}
			&__link {
				position: relative;
				display: block;
			}
			@include g.media-hover {
				&__link:hover &__ttl {
					color: g.$strongTextColor;
				}
				&__link:hover &__thumb {
					.__elem {
						scale: 1.075;
					}
				}
			}
			&__thumb {
				@include g.object-fit(968, 500);

				@include g.smMax {
					aspect-ratio: calc(800 / 500);
				}
			}
			&__body {
				position: absolute;
				left: 24px;
				bottom: 24px;
				width: 407px;
				padding: 20px 32px;
				background: rgba(g.$white, .92);

				@include g.smMin {
					display: grid;
					grid-template-columns: 1fr;
					grid-template-rows: repeat(3, auto);
					gap: 0 0;
					grid-auto-flow: row;
					grid-template-areas:
						"label"
						"ttl"
						"date";
				}
				@include g.smMax {
					left: 0;
					bottom: 0;
					display: grid;
					grid-template-columns: auto 1fr;
					grid-template-rows: repeat(2, auto);
					gap: 5px 16px;
					grid-auto-flow: row;
					grid-template-areas:
						"label date"
						"ttl ttl";
					width: 100%;
					padding: 15px 15px 10px;
				}
			}
			&__label {
				grid-area: label;
				line-height: 1;

				& > * {
					padding: 3px 12px;
					line-height: 1;
				}
			}
			&__ttl {
				grid-area: ttl;
				position: relative;
				padding-left: 15px;
				color: g.$textColor;
				@include g.font-bold;
				@include g.l_height(15, 24);
				transition: color .3s ease;

				@include g.smMin {
					margin-top: 10px;
					@include g.l_height(14, 23);
				}
				&::before {
					position: absolute;
					top: 0;
					left: 2px;
					color: g.$linkTextColor;
					@include g.font-icon('\f105');
				}
			}
			&__date {
				grid-area: date;
				display: block;
				color: g.$subTextColor1;
				@include g.font-medium;
				@include g.l_height(14, 24);

				@include g.smMin {
					margin-top: 6px;
					text-align: right;
					@include g.l_height(12, 22);
				}
			}
			.slick-prev,
			.slick-next {
				top: auto;
				bottom: 24px;
				z-index: 1;
				width: 45px;
				height: 45px;
				transform: translate(0, 0);
				transition: opacity .3s ease;

				@include g.smMax {
					width: 30px;
					height: 30px;
					bottom: -35px;
				}
				&::before {
					content: none;
				}
				.__ico1,
				.__ico2,
				.__ico3 {
					position: absolute;
					top: 0;
					left: 0;
					width: 45px;
					height: 45px;
					transition: opacity .3s ease, visibility .3s ease;

					@include g.smMax {
						width: 30px;
						height: 30px;
					}
				}
				@include g.smMin {
					.__ico2,
					.__ico3 {
						opacity: 0;
						visibility: hidden;
					}
				}
				@include g.smMax {
					.__ico1,
					.__ico2 {
						opacity: 0;
						visibility: hidden;
					}
				}
				@include g.hover {
					.__ico1 {
						opacity: 0;
						visibility: hidden;
					}
					.__ico2 {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			.slick-prev {
				left: auto;

				@include g.smMin {
					right: 85px;
				}
				@include g.smMax {
					right: calc(50% + 5px);
				}
			}
			.slick-next {
				@include g.smMin {
					right: 24px;
				}
				@include g.smMax {
					right: auto;
					left: calc(50% + 5px);
				}
			}
		}

		&-bnr {
			margin-top: g.$m-l;

			&__txt {
				@include g.font-bold;
				font-size: g.$fz-l;
				text-align: center;
			}
			&__bnr {
				margin-top: g.$m-s;
			}
		}
	}

	//研究開発 詳細ページ
	&-detail {
		&-head {
			margin-top: 54px;
			padding-bottom: 15px;
			border-bottom: 1px solid g.$lineColor;

			@include g.smMax {
				margin-top: 32px;
			}
			&__sub-ttl {
				color: g.$subTextColor1;
				@include g.font-medium;
				@include g.l_height(14, 24);
			}
			&__label {
				margin-top: 10px;
			}
			&__ttl {
				margin-top: 10px;
				@include g.font-bold;
				@include g.l_height(36, 50);

				@include g.smMax {
					@include g.l_height(25, 35);
				}
			}
			&__interviewee {
				display: flex;
				flex-wrap: wrap;
				margin-top: 10px;
				@include g.l_height(14, 24);

				.__item {
					margin-right: 1em;
				}
			}
			&__date {
				display: block;
				margin-top: 10px;
				color: g.$subTextColor1;
				@include g.font-medium;
				@include g.l_height(14, 24);
				text-align: right;
			}
		}

		&-lead {
			margin-top: 32px;
			
			&__img {
				.__elem {
					max-width: 100%;
				}
			}
			&__body {
				margin-top: 72px;

				@include g.smMax {
					margin-top: 32px;
				}
				& > * {
					margin-top: 24px;
				}
			}
		}

		&-technology {
			&.l-bg-blue {
				margin-top: 88px;
			}
			&__inner {
				@include g.mdMin {
					display: grid;
					grid-template-columns: 50% 45%;
					grid-template-rows: repeat(2, auto);
					gap: 0 5%;
					grid-auto-flow: row;
					grid-template-areas:
						"ttl ttl"
						"body img";
				}
			}
			&__ttl {
				grid-area: ttl;
			}
			&__body {
				grid-area: body;

				& > *:not(:first-child) {
					margin-top: 24px;
				}
				.__lead {
					@include g.font-bold;
					@include g.l_height(20, 30);

					@include g.mdMax {
						@include g.l_height(18, 27);
					}
				}
			}
			&__img {
				grid-area: img;

				@include g.mdMax {
					margin-top: 32px;
					text-align: center;
				}
				.__elem { max-width: 100%; }
			}
		}

		&-profile {
			padding: 30px;
			border: 1px solid g.$lineColor;

			@include g.smMin {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 20px 20px;
			}
			&__item {
				display: flex;

				@include g.smMax {
					&:not(:first-child) {
						margin-top: 20px;
					}
				}
			}
			&__thumb {
				flex: 0 0 140px;
				align-self: flex-start;
				border-radius: 50%;
				@include g.object-fit(1, 1);

				@include g.smMax {
					flex: 0 0 80px;
					align-self: center;
				}
			}
			&__body {
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-left: 22px;
			}
			&__name {
				@include g.font-medium;
				@include g.l_height(15, 26);

				@include g.smMax {
					@include g.l_height(14, 25);
				}
			}
			&__txt {
				margin-top: 5px;
				@include g.l_height(13, 23);

				@include g.smMax {
					@include g.l_height(11, 20);
				}
			}
		}

		&-contents {
			counter-reset: number 0;

			&__section {
				margin-top: 120px;

				@include g.smMax {
					margin-top: 60px;
				}
			}
			&__ttl {
				position: relative;
				margin-bottom: 55px;
				padding-left: 60px;
				@include g.font-bold;
				@include g.l_height(32, 56);

				@include g.smMax {
					margin-bottom: 32px;
					padding-left: 45px;
					@include g.l_height(20, 35);
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					counter-increment: number 1;
					content: "0" counter(number);
					color: g.$strongTextColor;
					@include g.font-en;
					line-height: 1;
				}
				&::after {
					content: '';
					position: absolute;
					top: 15px;
					left: 47px;
					height: 20px;
					border-left: 1px solid g.$lineColor;
					rotate: 26.5deg;

					@include g.smMax {
						top: 5px;
						left: 32px;
					}
				}
			}
			.__interviewer {
				position: relative;
				padding-left: 54px;
				@include g.font-bold;
				@include g.l_height(18, 31);

				@include g.smMax {
					padding-left: 30px;
					@include g.l_height(16, 25);
				}
				&::before {
					content: '';
					position: absolute;
					top: .9em;
					left: 0;
					width: 36px;
					border-top: 1px solid g.$textColor;

					@include g.smMax {
						width: 20px;
					}
				}
			}
			.__name {
				padding-right: 1em;
				color: g.$linkTextColor;
				@include g.font-bold;
			}
			p {
				margin-top: 32px;
			}
			&__img {
				margin: 56px 0;
				text-align: center;

				@include g.smMax {
					margin: 32px 0;
				}
				.__elem { max-width: 100%; }
			}
		}
	}

}
