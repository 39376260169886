@charset "utf-8";
@use "../../global/_" as g;
.e-ttl05 {
	margin-top: 88px;
	margin-bottom: 20px;
	@include g.font-bold;
	@include g.l_height(20, 30);

	@include g.smMax {
		@include g.l_height(18, 27);
	}
	.e-ttl01 + &,
	.e-ttl02 + &,
	.e-ttl03 + &,
	.e-ttl04 + & {
		margin-top: 0;
	}
}
