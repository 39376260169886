@charset "utf-8";
@use "../../global/_" as g;
.e-lead {
	@include g.font-bold;
	@include g.l_height(36, 54);

	@include g.smMax {
		@include g.l_height(25, 40);
	}
	strong {
		color: g.$strongTextColor;
	}
}