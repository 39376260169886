@charset "utf-8";
@use "../../../global/_" as g;
.c-company-organization {
	@include g.smMin {
		width: fit-content;
		margin: 0 auto;
	}
	&__item {
		@include g.smMin {
			display: flow-root;
			position: relative;
			padding-top: 82px;
		}
	}
	&-top {
		width: 200px;
		text-align: center;

		@include g.smMin {
			float: left;
			width: 250px;
		}
	}
	&-audit {
		@include g.smMin {
			position: absolute;
			top: 0;
			left: 250px;
			min-width: 350px;
			padding-left: 60px;
		}
		@include g.smMax {
			width: 200px;
			margin: 20px 0 0 50px;
		}
		&__item {
			position: relative;

			&::before {
				content: '';
				left: -45px;
				width: 45px;
				border-top: 1px solid g.$lineColor;
				@include g.centering-elements(false, true);

				@include g.smMax {
					left: -20px;
					width: 20px;
				}
			}
			&::after {
				content: '';
				position: absolute;
				left: -45px;
				top: 50%;
				bottom: calc(-50% - 20px);
				border-left: 1px solid g.$lineColor;

				@include g.smMax {
					left: -20px;
					top: -20px;
					bottom: 50%;
				}
			}
		}
	}
	&-second {
		@include g.smMin {
			min-width: 350px;
			margin-left: 250px;
			padding-left: 60px;
		}
		@include g.smMax {
			width: fit-content;
			margin: 20px 0 0 70px;
		}
		&__item {
			position: relative;

			&:not(:first-child) {
				margin-top: 20px;

				@include g.smMax {
					margin-top: 15px;
				}
				@include g.smMin {
					&::before {
						left: -30px;
						width: 30px;
					}
					&::after {
						content: '';
						position: absolute;
						left: -30px;
						top: calc(-50% - 20px);
						bottom: 50%;
						border-left: 1px solid g.$lineColor;
					}
				}
			}
			&::before {
				content: '';
				left: -60px;
				width: 60px;
				border-top: 1px solid g.$lineColor;
				@include g.centering-elements(false, true);

				@include g.smMax {
					left: -40px;
					width: 40px;
				}
			}
			@include g.smMax {
				&::after {
					content: '';
					position: absolute;
					left: -40px;
					top: calc(-50% - 20px);
					bottom: 50%;
					border-left: 1px solid g.$lineColor;
				}
			}
		}
	}
	&__box {
		padding: 16px;
		background: g.$bgColorGray;
		border: 1px solid g.$lineColor;

		@include g.smMax {
			padding: 10px;
		}
		.__elem {
			display: block;
		}
		.__inner {
			display: block;
			padding: 10px 16px;
			border: 1px solid g.$lineColor;
		}
		.__elem + .__inner {
			margin-top: 16px;

			@include g.smMax {
				margin-top: 10px;
			}
		}
		.c-company-organization-top &,
		.c-company-organization-audit & {
			background: g.$bgColorBlue;
			@include g.font-medium;
		}
	}

}