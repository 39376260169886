@charset "utf-8";
@use "../../../global/_" as g;
.c-tktkstories-strength {
	&-power {
		margin-top: 100px;
		counter-reset: number01 0;

		@include g.smMax {
			margin-top: 50px;
		}
		&__ttl {
			text-align: center;
			@include g.font-bold;
			@include g.l_height(32, 56);

			@include g.smMax {
				@include g.l_height(20, 35);
			}
		}
		&-anchor {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: auto;
			grid-auto-flow: row;
			gap: 0 20px;
			margin-top: 55px;
			counter-reset: number02 0;

			@include g.smBtMd {
				grid-template-columns: repeat(3, 1fr);
				gap: 20px 20px;
			}
			@include g.smMax {
				grid-template-columns: repeat(2, 1fr);
				gap: 10px 10px;
				margin-top: 30px;
			}
			&__item {
				display: flex;
			}
			&__link {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				padding: 25px 15px 40px;
				text-align: center;
				border: 1px solid g.$lineColor;
				transition: border .3s ease, background-color .3s ease;

				@include g.smMax {
					padding: 20px 10px 30px;
				}

				&::before {
					position: absolute;
					top: 15px;
					left: 15px;
					counter-increment: number02 1;
					content: '0' counter(number02);
					color: g.$strongTextColor;
					line-height: 1;
					transition: color .3s ease;
					@include g.font-en;
				}
				&::after {
					bottom: 15px;
					@include g.font-icon('\f107');
					@include g.centering-elements(true, false);
					line-height: 1;
					transition: color .3s ease;

					@include g.smMax {
						bottom: 5px;
					}
				}
			}
			@include g.media-hover {
				&__link:hover {
					background: g.$baseColorLight;
					border-color: g.$baseColorLight;

					&::before,
					&::after {
						color: g.$white;
					}
				}
				&__link:hover &__ico {
					.__ico2 {
						opacity: 0;
					}
				}
				&__link:hover &__txt {
					color: g.$white;
				}
			}
			&__ico {
				position: relative;
				flex: 0 0 auto;
				width: 70px;
				height: 70px;

				@include g.smMax {
					width: 45px;
					height: 45px;
				}

				.__ico1,
				.__ico2 {
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 70px;
					height: 70px;
					transition: opacity .3s ease;
					fill: g.$white;

					@include g.smMax {
						width: 45px;
						height: 45px;
					}
				}
			}
			&__txt {
				flex: 1 0 auto;
				display: flex;
				align-items: center;
				margin-top: 10px;
				color: g.$textColor;
				@include g.font-bold;
				transition: color .3s ease;

				@include g.smMax {
					font-size: g.$fz-s;
					line-height: 1.4;
				}
			}
		}

		&-section {
			position: relative;
			margin-top: 60px;

			@include g.smMin {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				margin-top: 150px;

				&:nth-child(2n+1) {
					flex-direction: row-reverse;
				}
			}
			&__body {
				flex: 0 0 45%;

				@include g.smMin {
					padding-bottom: 15px;
				}
			}
			&__ttl {
				position: relative;

				@include g.smMax {
					padding: 30px 0 0 50px;
				}
				&::before {
					counter-increment: number01 1;
					content: '0' counter(number01);
					color: g.$strongTextColor;
					font-size: 3.2rem;
					@include g.font-en;
					line-height: 1;

					@include g.smMax {
						position: absolute;
						top: 15px;
						left: 0;
						font-size: 2.8rem;
					}
				}
				&::after {
					content: '';
					position: absolute;
					top: 15px;
					left: 40px;
					display: inline-block;
					width: 1px;
					height: 20px;
					margin: 0 10px;
					translate: 0, -2px;
					rotate: 25deg;
					background: g.$lineColor;

					@include g.smMax {
						top: 30px;
						left: 30px;
					}
				}
				.__txt1 {
					display: block;
					@include g.l_height(20, 30);

					@include g.smMin {
						margin-top: 15px;
					}
					@include g.smMax {
						@include g.l_height(16, 28);
					}
				}
				.__txt2 {
					display: block;
					color: g.$linkTextColor;
					@include g.font-bold;
					@include g.l_height(32, 56);

					@include g.smMax {
						@include g.l_height(20, 35);
					}
				}
			}
			&__txt {
				margin-top: 20px;
			}
			&__img {
				flex: 0 0 50%;

				@include g.smMax {
					margin-top: 30px;
				}
				.__elem { width: 100%; }
			}
			&__bg {
				position: absolute;
				z-index: -1;

				.__elem {
					width: 100%;
					height: 100%;
				}
			}

			&._power01_ &__bg {
				top: g.xl_vw(-44);
				right: g.xl_vw(-184);
				width: g.xl_vw(454);
				height: g.xl_vw(488);

				@include g.xlMin {
					top: -44px;
					right: -184px;
					width: 454px;
					height: 488px;
				}
				@include g.smMax {
					top: 0;
					right: 0;
				}
			}
			&._power02_ &__bg {
				top: g.xl_vw(-5);
				left: g.xl_vw(-168);
				width: g.xl_vw(314);
				height: g.xl_vw(432);

				@include g.xlMin {
					top: -5px;
					left: -168px;
					width: 314px;
					height: 432px;
				}
				@include g.smMax {
					top: 0;
					right: 0;
					left: auto;
				}
			}
			&._power03_ &__bg {
				top: 0;
				right: g.xl_vw(-168);
				width: g.xl_vw(446);
				height: g.xl_vw(446);

				@include g.xlMin {
					right: -168px;
					width: 446px;
					height: 446px;
				}
				@include g.smMax {
					top: 0;
					right: 0;
				}
			}
			&._power04_ &__bg {
				left: g.xl_vw(-168);
				bottom: g.xl_vw(-86);
				width: g.xl_vw(476);
				height: g.xl_vw(394);

				@include g.xlMin {
					left: -168px;
					bottom: -86px;
					width: 476px;
					height: 394px;
				}
				@include g.smMax {
					top: 0;
					right: 0;
					bottom: auto;
					left: auto;
				}
			}
			&._power05_ &__bg {
				top: 0;
				right: g.xl_vw(-200);
				width: g.xl_vw(406);
				height: g.xl_vw(422);

				@include g.xlMin {
					right: -200px;
					width: 406px;
					height: 422px;
				}
				@include g.smMax {
					top: 0;
					right: 0;
				}
			}
		}
	}
}