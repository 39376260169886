@charset "utf-8";
@use "../../global/_" as g;
.c-anchor-link03 {
	&__item {
		border-bottom: 1px solid g.$lineColor;
		@include g.l_height(14, 24);
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 10px 10px 10px 5px;
		color: g.$textColor;
		@include g.font-medium;
		transition: color .3s ease;

		&::after {
			flex: 0 0 auto;
			margin-left: auto;
			color: g.$baseColor;
			@include g.font-icon('\f107');
			@include g.l_height(16, 16);
			transition: color .3s ease;
		}
		.__number {
			min-width: 2.5em;
			color: g.$linkTextColor;
			@include g.font-medium;
			transition: color .3s ease;

			img {
				width: 25px;
				height: 16px;
				margin: 0 auto;
				vertical-align: middle;
			}
		}
		.__txt {
		}
		@include g.hover {
			color: g.$strongTextColor;

			&::after {
				color: g.$strongTextColor;
			}
			.__number {
				color: g.$strongTextColor;
			}
		}

	}
}