@charset "utf-8";
@use "../../global/_" as g;
.l-lnav {
	@include g.smMin {
		margin-top: 24px;

		.c-tktkstories-index__head & {
			margin-top: 0;
		}
	}
	@include g.smMax {
		margin: 24px 0 46px;
	}
	&__list {
		@include g.smMin {
			display: flex;
			border-left: 1px solid g.$greenishBlue;
		}
	}
	&__item {
		@include g.smMin {
			flex: 1 1 auto;
			border-right: 1px solid g.$greenishBlue;
		}
		@include g.smMax {
			&:not(:first-child) {
				border-top: 1px solid g.$lineColor;
			}
		}
	}
	&__link {
		@include g.smMin {
			display: block;
			padding: 8px;
			color: g.$baseColor;
			@include g.font-bold;
			text-align: center;
		}
		@include g.smMax {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			background: g.$greenishBlue-200;
			color: g.$textColor;

			&::after {
				flex: 0 0 auto;
				margin-left: auto;
				color: g.$baseColor;
				@include g.font-icon('\f105');
			}
		}
		.__elem {
			@include g.smMin {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: -8px;
					height: 1px;
					background: g.$baseColor;
					scale: 0 1;
					transition: scale .3s ease;
				}
			}
			@include g.smMax {
				@include g.font-medium;
			}
		}
		&._current_,
		body.tktkstories_business &._business_ {
			@include g.smMax {
				background: g.$baseColor;
				color: g.$white;

				&::after {
					content: none;
				}
			}
			.__elem {
				&::after {
					scale: 1 1;
				}
			}
		}
		@include g.hover {
			color: g.$baseColor;
			
			.__elem {
				&::after {
					scale: 1 1;
				}
			}
		}
	}

	//カレント表示
	
}