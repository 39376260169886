@charset "utf-8";
@use "../../global/_" as g;
.c-cases-number {
	display: flex;
	align-items: flex-end;
	@include g.font-medium;
	line-height: 1;

	.__label {
	}
	.__number {
		margin-left: 10px;
		font-size: g.$fz-xl;
	}
}