@charset "utf-8";
@use "../../../global/_" as g;
.c-csr-index {

	&-visual {
		margin: 40px 0 104px;

		@include g.smMax {
			margin: 24px -10px 54px;
		}
		&__slider {
			opacity: 0;
			line-height: 0;
			transition: opacity .3s ease;

			@include g.smMax {
				padding-bottom: 35px;
			}
			&.slick-initialized {
				opacity: 1;
			}
			&.slick-dotted.slick-slider {
				margin-bottom: 0;
			}

			.slick-prev,
			.slick-next {
				top: auto;
				bottom: 24px;
				z-index: 1;
				width: 45px;
				height: 45px;
				transform: translate(0, 0);

				&::before {
					content: none;
				}
				.__ico1,
				.__ico2 {
					position: absolute;
					top: 0;
					left: 0;
					width: 45px;
					height: 45px;
					transition: opacity .3s ease, visibility .3s ease;
				}
				.__ico2 {
					opacity: 0;
					visibility: hidden;
				}
				.__hidden {
					@include g.hidden;
				}
				@include g.hover {
					.__ico1 {
						opacity: 0;
						visibility: hidden;
					}
					.__ico2 {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			.slick-prev {
				left: auto;
				right: 85px;
			}
			.slick-next { right: 24px; }

			.slick-dots {
				bottom: 24px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: 45px;
				padding-right: 154px;
				pointer-events: none;

				@include g.smMax {
					bottom: 0;
					height: auto;
				}

				li {
					width: 10px;
					height: 10px;
					margin: 0 0 0 15px;

					@include g.smMax {
						width: 15px;
						height: 15px;
					}
					button {
						width: 10px;
						height: 10px;
						pointer-events: auto;

						@include g.smMax {
							width: 15px;
							height: 15px;
						}
						&::before {
							content: '';
							@include g.circle(10);
							background: g.$white;
							opacity: .5;

							@include g.smMax {
								width: 15px;
								height: 15px;
								background: g.$baseColorLight;
							}
						}
					}
					&.slick-active {
						button {
							&::before {
								opacity: 1;

								@include g.smMin {
									background: g.$baseColor;
								}
							}
						}
					}
				}
			}
			
		}
		&__link {
			display: block;

			.__elem {
				width: 100%;
				transition: scale .5s ease;

				@include g.hover {
					scale: 1.04;
				}
			}
		}
	}

}
