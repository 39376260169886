@charset "utf-8";
@use "../../global/_" as g;
.m-sitemap-list {
	margin: 0 5px;
	
	& > .__item {
		&:not(:first-child)  {
			margin-top: 10px;
		}
	}
}