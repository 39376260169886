@charset "utf-8";
@use "../../global/_" as g;
.m-panel-download {
	width: 100%;
	padding: 32px 0 20px;
	border: 1px solid g.$lineColor;
	text-align: center;

	&__ico {
		.__ico {
			width: 52px;
			height: 40px;
		}
	}
	&__ttl {
		margin-top: 15px;
		font-size: g.$fz-xl;
		@include g.font-bold;
	}
	&__name {
		color: g.$subTextColor1;
		@include g.font-medium;
	}
	&__btn {
		margin-top: 10px;
		
		.e-btn._sm_ {
			@include g.smMin {
				width: min(220px, 100%);
				min-width: 0;
			}
		}
	}
	&__size {
		margin-top: 5px;
		color: g.$subTextColor1;
		font-size: g.$fz-xxs;
		@include g.font-medium;
	}
}