@charset "utf-8";
@use "../../global/_" as g;
.e-ttl06 {
	margin-top: 88px;
	margin-bottom: 15px;
	@include g.font-bold;
	@include g.l_height(18, 35);

	@include g.smMax {
		@include g.l_height(16, 30);
	}
	.e-ttl01 + &,
	.e-ttl02 + &,
	.e-ttl03 + &,
	.e-ttl04 + &,
	.e-ttl05 + & {
		margin-top: 0;
	}
}
