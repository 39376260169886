@charset "utf-8";
@use "../../global/_" as g;
.m-card {
	display: grid;
	grid-template-columns: min(129px, 31.2%) 1fr;
	grid-template-rows: auto auto 1fr;
	gap: 0 20px;
	grid-auto-flow: row;
	grid-template-areas:
	  "ttl ttl"
	  "thumb txt"
	  "thumb btn";
	max-width: 583px;
	margin: 0 auto;
	padding: 20px 22px;
	background: g.$white;
	border: 1px solid g.$lineColor;

	@include g.smMax {
		display: grid;
		grid-template-columns: 134px 1fr;
		grid-template-rows: auto auto auto;
		gap: 0 15px;
		grid-auto-flow: row;
		grid-template-areas:
		  "ttl ttl"
		  "thumb txt"
		  "btn btn";
		padding: 24px;
	}

	&__ttl {
		grid-area: ttl;
		margin-bottom: 20px;
		@include g.font-bold;
		@include g.l_height(20, 35);

		@include g.smMax {
			margin-bottom: 12px;
			@include g.l_height(18, 27);
		}
	}
	&__thumb {
		grid-area: thumb;
		position: relative;
		aspect-ratio: 129 / 146;

		@include g.smMax {
			aspect-ratio: 134 / 152;
		}
		&._m_ {
			aspect-ratio: 136 / 192;
		}
		&._a4_ {
			aspect-ratio: 210 / 297;
		}
		.__elem {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__txt {
		grid-area: txt;
		@include g.l_height(14, 24);
	}
	&__btn {
		grid-area: btn;
		margin-top: 16px;

		@include g.smMax {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}

		& > * {
			min-width: 0 !important;
			width: min(220px, 100%);

			@include g.smMax {
				width: 100%;
			}
		}
	}
}
