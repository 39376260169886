@charset "utf-8";
@use "../../global/_" as g;
.m-form-list {
	& > .__item {
		margin: 15px 0 0;
		
		&:first-child {
			margin-top: 0;
		}
	}
	@include g.smMin {
		&._horizontal_ {
			display: flex;
			flex-wrap: wrap;

			& > .__item {
				margin: 4px 32px 4px 0;
			}
		}
	}
}