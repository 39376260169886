@charset "utf-8";
@use "../../../global/_" as g;
.c-index-ir {
	border-top: 1px solid g.$lineColor;

	&__inner {
		position: relative;
		width: min(1536px, 100%);
		margin: 0 auto 80px;
		padding-top: 130px;

		@include g.smMin {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			margin-bottom: 55px;
			padding-right: 20px;
		}
		@include g.smBtMd {
			padding-top: 65px;
		}
		@include g.smMax {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: auto auto auto auto auto;
			gap: 0 0;
			grid-auto-flow: row;
			grid-template-areas:
				"ttl"
				"lead"
				"img"
				"txt"
				"btn";
			padding: 75px 10px 0;
		}
		&::before {
			content: attr(data-txt);
			position: absolute;
			top: -25px;
			left: -10px;
			color: g.$strongTextColor;
			@include g.font-en;
			@include g.l_height(140, 170);
			opacity: .05;

			@include g.smMax {
				top: -10px;
				left: 0;
				@include g.l_height(45, 55);
			}
		}
	}
	&__body {
		flex: 0 0 min(568px, 42%);
		margin-left: 4%;
		padding-top: 30px;

		@include g.smMax {
			display: contents;
		}
	}
	&__ttl {
		grid-area: ttl;
	}
	&__lead {
		grid-area: lead;
		margin-top: 30px;

		@include g.smMax {
			margin-top: 25px;
		}
	}
	&__txt {
		grid-area: txt;
		margin-top: 30px;
	}
	&__btn {
		grid-area: btn;
		margin-top: 40px;

		@include g.smMax {
			margin-top: 30px;
		}
	}
	&__img {
		grid-area: img;
		flex: 0 0 50%;

		@include g.smMax {
			margin-top: 30px;
		}
		.__elem { width: 100%; }
	}
}
