@charset "utf-8";
@use "../../global/_" as g;
.m-box {
	padding: 32px;
	background: g.$white;

	@include g.smMax {
		padding: 15px;
	}

	.c-tab__pane & {
		padding: 24px 24px 40px;
	}

	//カラーオプション -------------------------------
	&._line-gray_ {
		border: 1px solid g.$lineColor;
	}
	&._blue_ {
		background: g.$greenishBlue-100;
	}
	&._gray_ {
		background: g.$bgColorGray;
	}

	//余白オプション -------------------------------	
	&._p-s_ {
		padding: 16px 24px;

		@include g.smMax {
			padding: 16px;
		}
	}


	.c-grid .m-panel + & {
		margin-top: 15px;
	}
}
