@charset "utf-8";
@use "../../global/_" as g;
.l-contents {
	margin-bottom: 160px;

	@include g.mdMax {
		display: flex;
		flex-direction: column;
	}
	&._mb0_ {
		margin-bottom: 0;
	}
	&._sm-mb0_ {
		@include g.smMax {
			margin-bottom: 0;
		}
	}
	&._ov-h_ {
		overflow: hidden;
	}
	&__inner {
		@include g.mdMin {
			display: grid;
			grid-template-columns: 71.6% 23%;
			grid-template-rows: auto;
			gap: 0 5.3%;
			grid-auto-flow: row;
			grid-template-areas: "main side";
		}
		@include g.print {
			display: block !important;
		}
	}
	&__main {
		grid-area: main;
	}
	&__side {
		grid-area: side;
		position: relative;

		@include g.print {
			display: none;
		}
	}
}
