@charset "utf-8";
@use "../../global/_" as g;
@font-face {
  font-family: 'icomoon';
  src:  url('/common/icon/fonts/icomoon.eot');
  src:  url('/common/icon/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('/common/icon/fonts/icomoon.woff2') format('woff2'),
    url('/common/icon/fonts/icomoon.ttf') format('truetype'),
    url('/common/icon/fonts/icomoon.woff') format('woff'),
    url('/common/icon/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	.__hidden {
		@include g.hidden;
	}
}

.icon-check:before {
	content: "\e915";
  }
  .icon-clear:before {
	content: "\e913";
  }
  .icon-outline-pdf:before {
	content: "\e914";
  }
  .icon-outline-document:before {
	content: "\e900";
  }
  .icon-outline-mail:before {
	content: "\e901";
  }
  .icon-outline-faq:before {
	content: "\e90c";
  }
  .icon-circle-blank:before {
	content: "\e911";
  }
  .icon-circle-arrow-left:before {
	content: "\e90d";
  }
  .icon-twitter:before {
	content: "\e90e";
  }
  .icon-facebook:before {
	content: "\e90f";
  }
  .icon-download:before {
	content: "\e910";
  }
  .icon-blank:before {
	content: "\e902";
  }
  .icon-global:before {
	content: "\e90a";
  }
  .icon-document:before {
	content: "\e903";
  }
  .icon-close:before {
	content: "\e90b";
  }
  .icon-close-bold:before {
	content: "\e912";
  }
  .icon-minus:before {
	content: "\e904";
  }
  .icon-plus:before {
	content: "\e905";
  }
  .icon-search:before {
	content: "\e906";
  }
  .icon-pen:before {
	content: "\e907";
  }
  .icon-mail:before {
	content: "\e908";
  }
  .icon-faq:before {
	content: "\e909";
  }
  .icon-angle-double-left:before {
	content: "\f100";
  }
  .icon-angle-double-right:before {
	content: "\f101";
  }
  .icon-angle-left:before {
	content: "\f104";
  }
  .icon-angle-right:before {
	content: "\f105";
  }
  .icon-angle-up:before {
	content: "\f106";
  }
  .icon-angle-down:before {
	content: "\f107";
  }
  .icon-file-pdf-o:before {
	content: "\f1c1";
  }
  