@charset "utf-8";
@use "../../global/_" as g;
.e-label02 {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 126px;
	height: 30px;
	color: g.$white;
	@include g.font-medium;
	@include g.l_height(13, 19);

	&._notice_ { background: #c89b32; }
	&._press_  { background: #634b9b; }
	&._event_  { background: #2b95fe; }
	&._ir_     { background: #1ab898; }
	&._significant_ { background: g.$secondaryColorDark; }

	.m-news-list & {
		width: 120px;
	}
}
