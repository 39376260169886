@charset "utf-8";
@use "../../../global/_" as g;
.c-index-info {
	margin-top: 90px;

	@include g.smBtMd {
		margin-top: g.md_vw(90);
	}
	@include g.smMax {
		margin-top: 65px;
	}
	@include g.smMin {
		.m-news-list__item,
		.m-news-list__link {
			padding: 15px 0;
		}
		.m-news-list__link {
			margin: -15px 0;
		}
		.s_eirItem {
			flex-wrap: wrap;
			padding: 15px 0;
		}
	}
}