@charset "utf-8";
@use "../../global/_" as g;
.c-sns-share {
	display: none !important;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 16px;

	@include g.mdMax {
		justify-content: flex-end;
		margin-top: 20px;
	}
	@include g.print {
		display: none;
	}
	.l-lnav + & {
		margin-top: 32px;
	}
	.l-contents__side > & {
		@include g.mdMin {
			position: absolute;
			top: 0;
			left: 0;
			justify-content: flex-start;
		}
	}
	&__item {
		&:not(:first-child) {
			margin-left: 24px;

			@include g.screen(g.$md-1 + px, 1100px) {
				margin-left: 12px;
			}
		}
	}
	&__link {
		display: flex;
		align-items: center;
		transition: opacity .3s ease;

		&[target*="blank"] {
			&::before {
				content: none;
			}
		}
		.__ico {
			width: 32px;
			height: 32px;
		}
		.__txt {
			margin-left: 8px;
			font-size: g.$fz-xxs;
			@include g.font-bold;
		}
		&._fb_ {
			color: g.$facebook;
			fill: g.$facebook;
		}
		&._tw_ {
			color: g.$twitter;
			fill: g.$twitter;
		}
		@include g.hover {
			opacity: .65;
		}
	}
}