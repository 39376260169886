@charset "utf-8";
@use "../../global/_" as g;
.e-ttl08 {
	margin-bottom: 24px;
	@include g.font-bold;
	@include g.l_height(24, 36);

	@include g.smMax {
		@include g.l_height(20, 30);
	}
	&::before {
		content: '';
		display: block;
		width: 40px;
		height: 3px;
		margin-bottom: 30px;
		background: g.$baseColorLight;

		@include g.smMax {
			margin-bottom: 20px;
		}
	}
}
